<template>
  <div class="spectrum dashboard">
    <transition name="fade">
      <SideNavigation v-if="!isMobile && availability" class="sidenav"/>
    </transition>
    <div v-if="!availability" style="height: inherit; display: flex; justify-content: center; align-items: center;" class="spectrum-Body">
      <transition name="fade">
        <div style="text-align: center; justify-content: center; padding: 0 16px; display: flex; flex-direction: column; align-items: center; margin-top: 256px;" class="spectrum-Body">
          <h1 style="margin-bottom: 0;" class="spectrum-Heading spectrum-Heading--sizeXL">Unavailable dashboard</h1>
          <p class="spectrum-Body spectrum-Body--sizeM">There are no options you can configure in this server at the moment</p>
          <ServerSwitcher style="margin-top: 24px; margin-left: auto; margin-right: auto;" />
        </div>
      </transition>
      <transition name="fade">
        <Footer style="bottom: 32px; position: absolute; background-color: var(--spectrum-global-color-gray-75); padding: 0 64px 0 64px;"/>
      </transition>
    </div>
    <section v-else :style="{'margin-left': isMobile ? 0 : '228px', 'padding': isMobile ? '24px' : '48px'}" class="management-view">
        <div class="container" style="max-width: 950px; width: 100%; position: relative;">
          <transition name="fade">
            <Configuration v-if="getSideNavigationState === 'CONFIGURATIONS'" />
          </transition>
          <transition name="fade">
            <UserExperiences v-if="getSideNavigationState === 'LEADERBOARDS'" />
          </transition>
          <transition name="fade">
            <Warnings v-if="getSideNavigationState === 'WARNINGS'" />
          </transition>
          <transition name="fade">
            <Commands v-if="getSideNavigationState === 'COMMANDS'" />
          </transition>
          <transition name="fade">
            <ReactionRoles v-if="getSideNavigationState === 'REACTION_ROLES'" />
          </transition>
          <transition name="fade">
            <Logs v-if="getSideNavigationState === `LOGS`" />
          </transition>
          <transition name="fade">
            <Tickets v-if="getSideNavigationState === `TICKETS`" />
          </transition>
          <transition name="fade">
            <Footer style="background-color: var(--spectrum-global-color-gray-75); padding: 32px 0 0 0;"/>
          </transition>
        </div>
    </section>
  </div>
</template>

<script>
import SideNavigation from '../components/dashboard/SideNavigation.vue'
import Configuration from '../components/dashboard/Configurations.vue'
import UserExperiences from '../components/dashboard/UserExperiences.vue'
import Warnings from '../components/dashboard/Warnings.vue'
import Logs from '../components/dashboard/Logs.vue'
import Commands from '../components/dashboard/Commands.vue'
import ReactionRoles from '../components/dashboard/ReactionRoles.vue'
import Tickets from '../components/dashboard/Tickets.vue'
import Footer from '../components/Footer.vue'
import ServerSwitcher from '../components/ServerSwitcher.vue'
export default {
  name: 'Dashboard',
  inject: [`mq`],
  components: {
    SideNavigation,
    Configuration,
    UserExperiences,
    Warnings,
    Logs,
    Commands,
    ReactionRoles,
    Tickets,
    ServerSwitcher,
    Footer
  },
  data() {
    return {
      availability: true,
      sections: {
        'CONFIGURATIONS': [`OVERALL`],
        'LEADERBOARDS': [`GLOBAL`, `LOCAL`],
        'WARNINGS': [`REGULAR`],
        'COMMANDS': [`OVERVIEW`, `REGISTRATION`, `IMPORT`],
        'REACTION_ROLES': [`OVERVIEW`, `REGISTRATION`],
        'TICKETS': [`OVERVIEW`, `ARCHIVE`],
        'LOGS': [
          `WARNINGS`,
          `MESSAGES`,
          `ROLES`,
          `THREADS`,
          `MEMBERS`,
          `BANS`,
          `CHANNELS`,
          `LOCKDOWNS`,
          `EMOJIS`,
          `CONFIGS`
        ]
      }
    }
  },
  computed: {
    isMobile() {
      return this.mq.current === `mobile`
    },
    getSideNavigationState() {
      return this.$store.state.sideNavigation.activeMenu
    }
  },
  methods: {
    enabledSubs(key, list=[]) {
      let totalDisabled = 0
      let subs = []
      for (let i = 0; i < list.length; i++) {
        if (this.$store.getters.getPermissions(`SERVER_${key}${this.sections[key].length <= 1 ? `` : `_${list[i]}`}`).READ === 0) {
          totalDisabled++
        }
        else {
          subs.push(list[i])
        }
      }
      return {
        state: totalDisabled === list.length ? false : true,
        source: subs
      }
    },
  },
  created() {
    let checkpoint = null
    for (let key in this.sections) {
      const subs = this.sections[key]
      if (checkpoint !== null) break
      const subCalculation = this.enabledSubs(key, subs)
      if (this.$store.getters.getPermissions(`SERVER_${key}`).READ && subCalculation.state) {
        this.$store.commit(`updateSideNavigation`, key)
        this.$store.commit(`updateSubSideNavigation`, `${key}_${subCalculation.source[0]}`)
        this.$store.commit(`updateSideNavRemoteReload`, true)
        checkpoint = key
      }
    }
    //  Fallback
    if (checkpoint === null) {
      this.availability = false
    }
  }
}
</script>

<style scoped>
  .dashboard {
    max-width: 100vw;
    box-sizing: border-box;
    background-color: var(--spectrum-global-color-gray-75);
  }
  .sidenav {
    position: fixed;
    left: 0;
    top: 64px;
  }
  .management-view {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    min-height: calc(100vh - 63px);
    box-sizing: border-box;
  }
</style>