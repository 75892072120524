<template>
  <div class="spectrum container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
    <div class="spectrum-ProgressCircle spectrum-ProgressCircle--indeterminate spectrum-ProgressCircle--large">
      <div class="spectrum-ProgressCircle-track"></div>
      <div class="spectrum-ProgressCircle-fills">
        <div class="spectrum-ProgressCircle-fillMask1">
          <div class="spectrum-ProgressCircle-fillSubMask1">
            <div class="spectrum-ProgressCircle-fill"></div>
          </div>
        </div>
        <div class="spectrum-ProgressCircle-fillMask2">
          <div class="spectrum-ProgressCircle-fillSubMask2">
            <div class="spectrum-ProgressCircle-fill"></div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center; padding: 16px 24px;" class="spectrum-Typography">
      <p class="spectrum-Body spectrum-Body--sizeS">{{ text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: `Loading`,
  props: {
    text: String
  }
}
</script>
<style scoped>
  .container {
    background-color: var(--spectrum-global-color-gray-75);
    z-index: 1;
  }
</style>