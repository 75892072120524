<template>
  <div style="background-color: transparent;" class="spectrum container">
    <div v-if="subCategory === 'LEADERBOARDS_GLOBAL'">
      <div style="margin-bottom: 16px" class="spectrum-Typography">
        <h1 class="spectrum-Heading spectrum-Heading--sizeXL">Global Leaderboard</h1>
        <p class="spectrum-Body spectrum-Body--sizeM">The top ten users that have reached highest experience points across Adobe's product servers.</p>
      </div>
      <transition name="fade-reverse">
        <Loading style="margin-left: -1px; padding-right: 24px; position: absolute; height: 300px; width: 100%;" :text="'Loading global exp...'" v-if="loading" />
      </transition>
        <div v-if="globalExp === false" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
          <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-icon-18-Info" />
          </svg>
          <div class="spectrum-InLineAlert-header">No data for global exp yet</div>
        </div>
        <transition-group :style="{'display': 'flex', 'flex-direction': 'column', 'padding': '0', 'flex-wrap': 'wrap'}" class="spectrum-Body" tag="ul" name="fade-reverse">
          <li :key="key" :style="{'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px'}" class="card" v-for="(value, key) in globalExp">
            <p style="width: 50px; text-align: center;" class="spectrum-Body spectrum-Body--sizeS">{{ rankSuffix(key+1) }}</p>
            <img style="width: 32px; height: 32px;" class="spectrum-Avatar" :src="value.avatar" alt="Avatar">
            <h1 style="padding-left: 16px;" class="spectrum-Heading spectrum-Heading--sizeS">{{ value.name }}</h1>
            <p style="margin-left: auto; padding-right: 16px;" class="spectrum-Body spectrum-Body--sizeS">{{ `${commanifier(value.exp.current)} exp (level ${value.exp.level})` }}</p>
          </li>
        </transition-group>
      <p style="margin-top: 16px;" class="spectrum-Body spectrum-Body--sizeXS">The displayed data is reflecting to the current state of user experience points across Adobe's product servers.<br />It's not updated in realtime, so it is advised to frequently refresh the page to get the newest data.</p>
    </div>
    <div v-else-if="subCategory === 'LEADERBOARDS_LOCAL'">
      <div style="margin-bottom: 16px" class="spectrum-Typography">
        <h1 class="spectrum-Heading spectrum-Heading--sizeXL">Weekly Server Leaderboard</h1>
        <p class="spectrum-Body spectrum-Body--sizeM">The top ten members that have sent most messages in the current server. Refreshed each week.</p>
      </div>
      <transition name="fade-reverse">
        <Loading style="margin-left: -1px; padding-right: 24px; position: absolute; height: 300px; width: 100%;" :text="'Loading weekly server exp...'" v-if="loading" />
      </transition>
      <div v-if="localMessages === false" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
        <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-icon-18-Info" />
        </svg>
        <div class="spectrum-InLineAlert-header">No data for weekly messages yet</div>
      </div>
      <transition-group :style="{'display': 'flex', 'flex-direction': 'column', 'padding': '0', 'flex-wrap': 'wrap'}" class="spectrum-Body" tag="ul" name="fade-reverse">
        <li :key="key" :style="{'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px'}" class="card" v-for="(value, key) in localMessages">
          <p style="width: 50px; text-align: center;" class="spectrum-Body spectrum-Body--sizeS">{{ rankSuffix(key+1) }}</p>
          <img style="width: 32px; height: 32px;" class="spectrum-Avatar" :src="value.avatar" alt="Avatar">
          <h1 style="padding-left: 16px;" class="spectrum-Heading spectrum-Heading--sizeS">{{ value.name }}</h1>
          <p style="margin-left: auto; padding-right: 16px;" class="spectrum-Body spectrum-Body--sizeS">{{ commanifier(value.messages) + ' messages sent' }}</p>
        </li>
      </transition-group>
      <p style="margin-top: 16px;" class="spectrum-Body spectrum-Body--sizeXS">The displayed data is reflecting to the current state of weekly member messages sent inside current server.<br />It's not updated in realtime, so it is advised to frequently refresh the page to get the newest data.</p>
    </div>
    <div style="display: none;" v-if="isRemoteReload && ['LEADERBOARDS_GLOBAL', 'LEADERBOARDS_LOCAL'].includes(subCategory)">{{ refreshPool(subCategory) }}</div>
  </div>
</template>
<script>
import Loading from '../Loading.vue'
export default {
  name: `UserExperiences`,
  inject: [`mq`],
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      globalExp: [],
      localMessages: []
    }
  },
  computed: {
    isRemoteReload() {
      return this.$store.state.sideNavigation.remoteReload
    },
    subCategory() {
      return this.$store.state.sideNavigation.activeSubMenu
    },
    isMobile() {
      return this.mq.current === `mobile`
    }
  },
  methods: {
    async findUser() {
      const res = await this.$http.get(`/api/users/${this.targetUserKeyword}`, {
        params: {
          guild: this.$store.state.guild.id
        }
      })
      if (res.data.user === null) return this.target = false
      this.target = res.data
      this.newExp = res.data.exp.total
    },
    commanifier(number) {
      return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`) : 0
    },
    rankSuffix(i) {
      let j = i % 10
      let k = i % 100
      if (j == 1 && k != 11) return i + `st`
      if (j == 2 && k != 12) return i + `nd`
      if (j == 3 && k != 13) return i + `rd`
      return i + `th`
    },
    async refreshPool(key) {
      this.loading = true
      this.$store.commit(`lockSideNavigation`, true)
      this.$store.commit(`updateSideNavRemoteReload`, false)
      this.globalExp = []
      this.localMessages = []
      if (key === `LEADERBOARDS_GLOBAL`) await this.fetchGlobalExp()
      if (key === `LEADERBOARDS_LOCAL`) await this.fetchWeeklyMessages()
      return this.$store.commit(`lockSideNavigation`, false)
    },
    async fetchGlobalExp() {
      const pool = await this.$http.get(`/api/leaderboards/global`)
      this.loading = false
      if (pool.data.leaderboards <= 0) return this.globalExp = false
      const sorted = pool.data.leaderboards.sort((a,b) => (a.exp < b.exp) ? 1 : ((b.exp < a.exp) ? -1 : 0))
      for (let i=0; i<sorted.length; i++) {
        await new Promise(r => setTimeout(r, 150))
        this.globalExp.push(sorted[i])
      }
    },
    async fetchWeeklyMessages() {
      const pool = await this.$http.get(`/api/leaderboards/weekly/${this.$store.state.guild.id}`)
      this.loading = false
      if (pool.data.leaderboards <= 0) return this.localMessages = false
      const sorted = pool.data.leaderboards.sort((a,b) => (parseInt(a.messages) < parseInt(b.messages)) ? 1 : ((parseInt(b.messages) < parseInt(a.messages)) ? -1 : 0))
      for (let i=0; i<sorted.length; i++) {
        await new Promise(r => setTimeout(r, 150))
        this.localMessages.push(sorted[i])
      }
    }
  }
}
</script>
<style scoped>
  .card {
    flex-wrap: wrap;
    height: 50px;
    display: flex;
    align-items: center;
    list-style-type: none;
  }
  .fade-reverse-enter-active {
    transition: all 0.2s ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>