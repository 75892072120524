<template>
  <div class="spectrum-Popover is-open" style="margin-top: 8px; z-index: 2; position: relative;">
    <div v-if="loading" style="margin: 16px auto;" class="spectrum-ProgressCircle spectrum-ProgressCircle--indeterminate">
      <div class="spectrum-ProgressCircle-track"></div>
      <div class="spectrum-ProgressCircle-fills">
        <div class="spectrum-ProgressCircle-fillMask1">
          <div class="spectrum-ProgressCircle-fillSubMask1">
            <div class="spectrum-ProgressCircle-fill"></div>
          </div>
        </div>
        <div class="spectrum-ProgressCircle-fillMask2">
          <div class="spectrum-ProgressCircle-fillSubMask2">
            <div class="spectrum-ProgressCircle-fill"></div>
          </div>
        </div>
      </div>
    </div>
    <slot v-else></slot>
  </div>
</template>
<script>
export default {
  name: `Popover`,
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    header: String,
    caption: String
  }
}
</script>