//  Spectrum icons
import loadIcons from "loadicons"
loadIcons('https://unpkg.com/@spectrum-css/icon@3.0.3/dist/spectrum-css-icons.svg')
loadIcons('https://unpkg.com/@adobe/spectrum-css-workflow-icons@1.2.1/dist/spectrum-icons.svg')

//  Global theme
import "@spectrum-css/vars/dist/spectrum-global.css"
import "@spectrum-css/vars/dist/spectrum-medium.css"
import "@spectrum-css/vars/dist/spectrum-lightest.css"
import "@spectrum-css/vars/dist/spectrum-darkest.css"

//  Spectrum components
import "@spectrum-css/page/dist/index-vars.css"
import "@spectrum-css/textfield/dist/index-vars.css"
import "@spectrum-css/button/dist/index-vars.css"
import "@spectrum-css/buttongroup/dist/index-vars.css"
import "@spectrum-css/actionbutton/dist/index-vars.css"
import "@spectrum-css/asset/dist/index-vars.css"
import "@spectrum-css/icon/dist/index-vars.css"
import "@spectrum-css/inlinealert/dist/index-vars.css"
import "@spectrum-css/checkbox/dist/index-vars.css"
import "@spectrum-css/quickaction/dist/index-vars.css"
import "@spectrum-css/typography/dist/index-vars.css"
import "@spectrum-css/divider/dist/index-vars.css"
import "@spectrum-css/card/dist/index-vars.css"
import "@spectrum-css/underlay/dist/index-vars.css"
import "@spectrum-css/dialog/dist/index-vars.css"
import "@spectrum-css/illustratedmessage/dist/index-vars.css"
import "@spectrum-css/link/dist/index-vars.css"
import "@spectrum-css/dropzone/dist/index-vars.css"
import "@spectrum-css/fieldlabel/dist/index-vars.css"
import "@spectrum-css/menu/dist/index-vars.css"
import "@spectrum-css/avatar/dist/index-vars.css"
import "@spectrum-css/popover/dist/index-vars.css"
import "@spectrum-css/picker/dist/index-vars.css"
import "@spectrum-css/search/dist/index-vars.css"
import "@spectrum-css/stepper/dist/index-vars.css"
import "@spectrum-css/switch/dist/index-vars.css"
import "@spectrum-css/tabs/dist/index-vars.css"
import "@spectrum-css/toast/dist/index-vars.css"
import "@spectrum-css/sidenav/dist/index-vars.css"
import "@spectrum-css/progresscircle/dist/index-vars.css"
import "@spectrum-css/pagination/dist/index-vars.css"
import "@spectrum-css/tag/dist/index-vars.css"
import "@adobe/focus-ring-polyfill"

import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { Vue3Mq } from "vue3-mq"
import { Plugin } from 'vue-responsive-video-background-player'
import store from './store/index.js'
import Router from './router/index.js'
import App from './App.vue'
import axios from 'axios'
//  Load saved theme
store.commit(`toggleDarkTheme`, localStorage.getItem(`darkTheme`) === `true`)
library.add(faDiscord)
const app = createApp(App)
axios.defaults.withCredentials = true
app.config.globalProperties.$http = axios
//  Feature Flag Interceptor (FFI)
app.config.globalProperties.$featureFlagInterceptor = async(targetId=null, guildId=null) => {
  if (targetId === null) throw new TypeError(`Target ID is required`)
  if (guildId === null) throw new TypeError(`Guild ID is required`)
  const data = (await axios.get(`/api/featureFlags/${targetId}/${guildId}`)).data
  return {
    source: data,
    getPermission(flagId) {
      const targetMeta = data.find(flag => flag.ID === flagId.toUpperCase())
      return targetMeta ? targetMeta.PERMISSIONS : {}
    }
  }
}
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(Plugin)
app.use(Router)
app.use(store)
app.use(Vue3Mq, {
  breakpoints: {
    mobile: 0,
    desktop: 1364
  }
})
Router.beforeEach((to, from, next) => {
  store.commit(`updateProfilePopover`, false)
  store.commit(`updateNavigationDrawer`, false)
  if (from.name === 'Server Selection') {
    store.commit(`updateSideNavigation`, `Configurations`)
    store.commit(`updateSubSideNavigation`, `Overall`)
  }
  if ([`/`, `/server-selection`].includes(to.path)) {
    store.commit(`updateTopNavigation`, to.name)
  }
  if ([`Dashboard`, `Server Selection`].includes(to.name)) {
    if (!store.state.user) return next({ name: 'Login' })
  }
  //  Updating title
  document.title = to.name === `Home` 
  ? `Adobe Discord: Connecting Adobe together`
  : `${to.name} - Adobe Discord`
  return next()
})
app.mount('#app')
