import { createStore } from 'vuex'
const store = createStore({
  state() {
    return {
      darkTheme: false,
      modal: null,
      guild: null,
      profilePopover: false,
      user: null,
      toast: null,
      toastTimeout: null,
      isLoggedIn: false,
      sortPopover: false,
      topNavigation: {
        activeMenu: `Home`,
        drawer: false
      },
      featureFlags: [],
      sideNavigation: {
        remoteReload: false,
        lockButton: false,
        activeSubMenu: `CONFIGURATIONS_OVERALL`,
        activeMenu: `CONFIGURATIONS`
      }
    }
  },
  getters: {
    getPermissions: state => (flagId, guildId) => {
      const guild = state.featureFlags[guildId || (state.guild ? state.guild.id : null)]
      if (!guild) return {}
      return guild.find(flag => flag.ID === flagId.toUpperCase()).PERMISSIONS
    }
  },
  mutations: {
    updateFeatureFlags(state, val) {
      state.featureFlags = val
    },
    updateSideNavRemoteReload(state, val) {
      state.sideNavigation.remoteReload = val
    },
    lockSideNavigation(state, val) {
      state.sideNavigation.lockButton = val
    },
    updateSortPopover(state, val) {
      state.sortPopover = val
    },
    updateNavigationDrawer(state, val) {
      state.topNavigation.drawer = val
    },
    toggleDarkTheme(state, forceState=null) {
      if (forceState !== null) return state.darkTheme = forceState
      state.darkTheme = state.darkTheme ? false : true
    },
    updateGuild(state, guild) {
      state.guild = guild
    },
    updateProfilePopover(state, val) {
      state.profilePopover = val
    },
    login(state, user) {
      state.isLoggedIn = true
      state.user = user
    },
    logout(state) {
      state.isLoggedIn = false
      state.user = null
    },
    updateModal(state, val) {
      state.modal = val
    },
    updateSideNavigation(state, menu) {
      state.sideNavigation.activeMenu = menu
    },
    updateSubSideNavigation(state, menu) {
      state.sideNavigation.activeSubMenu = menu
    },
    updateTopNavigation(state, menu) {
      state.topNavigation.activeMenu = menu
    },
    sendToast(state, toast) {
      state.toast = null
      state.toast = toast
      state.toastTimeout = setTimeout(() => state.toast = null, 5000)
    },
    ejectToast(state) {
      state.toast = null
      clearTimeout(state.toastTimeout)
    }
  }
})
export default store
