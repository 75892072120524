<template>
  <nav v-if="ready" style="padding-left: 32px;" class="spectrum nav">
    <div style="display: flex; align-items: padding-left: 12px; center; width: 228px;">
      <div style="display: flex; flex-direction: column; width: auto">
        <ServerSwitcher />
        <ServerMiniCard>
          <template v-slot:caption>
            Editing
          </template>
        </ServerMiniCard>
      </div>
    </div>
    <ul style="overflow-y: scroll; max-height: calc(100vh - 170px - 64px);" class="spectrum-SideNav spectrum-SideNav--multiLevel">
      <li v-if="$store.getters.getPermissions(`SERVER_CONFIGURATIONS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0">
        <a href="#" @click="toggleCategory('CONFIGURATIONS')" class="spectrum-SideNav-itemLink">Configurations</a>
        <ul v-if="openedCategory.CONFIGURATIONS" style="display: inherit" class="spectrum-SideNav">
          <li class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_OVERALL'), lockOnFetch]">
            <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_OVERALL')" class="spectrum-SideNav-itemLink">Overall</a>
          </li>
          <li class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_LOGS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_LOGS')" class="spectrum-SideNav-itemLink">Logs</a>
          </li>
          <li class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_MODERATIONS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_MODERATIONS')" class="spectrum-SideNav-itemLink">Moderations</a>
          </li>
          <li class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_LEVELS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_LEVELS')" class="spectrum-SideNav-itemLink">Levels</a>
          </li>
          <li class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_TICKETS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_TICKETS')" class="spectrum-SideNav-itemLink">Tickets</a>
          </li>
        </ul>
      </li>
      <li v-if="$store.getters.getPermissions(`SERVER_LEADERBOARDS`).READ 
      && enabledSubs([`SERVER_LEADERBOARDS_GLOBAL`, `SERVER_LEADERBOARDS_LOCAL`])" class="spectrum-SideNav-item" role="menuitem" tabindex="0">
        <a href="#" @click="toggleCategory('LEADERBOARDS')" class="spectrum-SideNav-itemLink" >Leaderboards</a>
        <ul v-if="openedCategory.LEADERBOARDS" style="display: inherit" class="spectrum-SideNav">
          <li v-if="$store.getters.getPermissions(`SERVER_LEADERBOARDS_GLOBAL`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LEADERBOARDS_GLOBAL'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LEADERBOARDS', 'LEADERBOARDS_GLOBAL')" class="spectrum-SideNav-itemLink">Global</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LEADERBOARDS_LOCAL`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LEADERBOARDS_LOCAL'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LEADERBOARDS', 'LEADERBOARDS_LOCAL')" class="spectrum-SideNav-itemLink">Server</a>
          </li>
        </ul>
      </li>
      <li v-if="$store.getters.getPermissions(`SERVER_WARNINGS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0">
        <a href="#" @click="toggleCategory('WARNINGS')" class="spectrum-SideNav-itemLink" >Warnings</a>
        <ul v-if="openedCategory.WARNINGS" style="display: inherit" class="spectrum-SideNav">
          <li class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('WARNINGS_REGULAR'), lockOnFetch]">
            <a href="#" @click="updateNavigation('WARNINGS', 'WARNINGS_REGULAR')" class="spectrum-SideNav-itemLink">Regular</a>
          </li>
        </ul>
      </li>
      <li v-if="$store.getters.getPermissions(`SERVER_COMMANDS`).READ 
      && enabledSubs([`SERVER_COMMANDS_OVERVIEW`, `SERVER_COMMANDS_REGISTRATION`, `SERVER_COMMANDS_IMPORT`])" class="spectrum-SideNav-item" role="menuitem" tabindex="0">
        <a href="#" @click="toggleCategory('COMMANDS')" class="spectrum-SideNav-itemLink" >Commands</a>
        <ul v-if="openedCategory.COMMANDS" style="display: inherit" class="spectrum-SideNav">
          <li v-if="$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('COMMANDS_OVERVIEW'), lockOnFetch]">
            <a href="#" @click="updateNavigation('COMMANDS', 'COMMANDS_OVERVIEW')" class="spectrum-SideNav-itemLink">Overview</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_COMMANDS_REGISTRATION`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('COMMANDS_REGISTRATION'), lockOnFetch]">
            <a href="#" @click="updateNavigation('COMMANDS', 'COMMANDS_REGISTRATION')" class="spectrum-SideNav-itemLink">Registration</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_COMMANDS_IMPORT`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('COMMANDS_IMPORT'), lockOnFetch]">
            <a href="#" @click="updateNavigation('COMMANDS', 'COMMANDS_IMPORT')" class="spectrum-SideNav-itemLink">Import</a>
          </li>
        </ul>
      </li>
      <li v-if="$store.getters.getPermissions(`SERVER_REACTION_ROLES`).READ
      && enabledSubs([`SERVER_REACTION_ROLES_OVERVIEW`, `SERVER_REACTION_ROLES_REGISTRATION`])" class="spectrum-SideNav-item" role="menuitem" tabindex="0">
        <a href="#" @click="toggleCategory('REACTION_ROLES')" class="spectrum-SideNav-itemLink" >Reaction Roles</a>
        <ul v-if="openedCategory.REACTION_ROLES" style="display: inherit" class="spectrum-SideNav">
          <li v-if="$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('REACTION_ROLES_OVERVIEW'), lockOnFetch]">
            <a href="#" @click="updateNavigation('REACTION_ROLES', 'REACTION_ROLES_OVERVIEW')" class="spectrum-SideNav-itemLink">Overview</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('REACTION_ROLES_REGISTRATION'), lockOnFetch]">
            <a href="#" @click="updateNavigation('REACTION_ROLES', 'REACTION_ROLES_REGISTRATION')" class="spectrum-SideNav-itemLink">Registration</a>
          </li>
        </ul>
      </li>
      <li v-if="$store.getters.getPermissions(`SERVER_TICKETS`).READ
      && enabledSubs([`SERVER_TICKETS_OVERVIEW`, `SERVER_TICKETS_ARCHIVE`])" class="spectrum-SideNav-item" role="menuitem" tabindex="0">
        <a href="#" @click="toggleCategory('TICKETS')" class="spectrum-SideNav-itemLink" >Tickets</a>
        <ul v-if="openedCategory.TICKETS" style="display: inherit" class="spectrum-SideNav">
          <li v-if="$store.getters.getPermissions(`SERVER_TICKETS_OVERVIEW`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('TICKETS_OVERVIEW'), lockOnFetch]">
            <a href="#" @click="updateNavigation('TICKETS', 'TICKETS_OVERVIEW')" class="spectrum-SideNav-itemLink">Overview</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_TICKETS_ARCHIVE`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('TICKETS_ARCHIVE'), lockOnFetch]">
            <a href="#" @click="updateNavigation('TICKETS', 'TICKETS_ARCHIVE')" class="spectrum-SideNav-itemLink">Archive</a>
          </li>
        </ul>
      </li>
      <li v-if="$store.getters.getPermissions(`SERVER_LOGS`).READ && enabledSubs([
        `SERVER_LOGS_WARNINGS`, 
        `SERVER_LOGS_MESSAGES`, 
        `SERVER_LOGS_ROLES`, 
        `SERVER_LOGS_THREADS`, 
        `SERVER_LOGS_MEMBERS`, 
        `SERVER_LOGS_BANS`, 
        `SERVER_LOGS_CHANNELS`, 
        `SERVER_LOGS_LOCKDOWNS`, 
        `SERVER_LOGS_EMOJIS`, 
        `SERVER_LOGS_CONFIGS`])" class="spectrum-SideNav-item" role="menuitem" tabindex="0">
        <a href="#" @click="toggleCategory('LOGS')" class="spectrum-SideNav-itemLink" >Logs</a>
        <ul v-if="openedCategory.LOGS" style="display: inherit" class="spectrum-SideNav">
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_WARNINGS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_WARNINGS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_WARNINGS')" class="spectrum-SideNav-itemLink">Warnings</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_MESSAGES`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_MESSAGES'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_MESSAGES')" class="spectrum-SideNav-itemLink">Messages</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_ROLES`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_ROLES'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_ROLES')" class="spectrum-SideNav-itemLink">Roles</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_THREADS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_THREADS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_THREADS')" class="spectrum-SideNav-itemLink">Threads</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_MEMBERS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_MEMBERS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_MEMBERS')" class="spectrum-SideNav-itemLink">Members</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_BANS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_BANS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_BANS')" class="spectrum-SideNav-itemLink">Bans</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_CHANNELS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_CHANNELS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_CHANNELS')" class="spectrum-SideNav-itemLink">Channels</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_LOCKDOWNS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_LOCKDOWNS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_LOCKDOWNS')" class="spectrum-SideNav-itemLink">Lockdowns</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_EMOJIS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_EMOJI'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_EMOJI')" class="spectrum-SideNav-itemLink">Emojis</a>
          </li>
          <li v-if="$store.getters.getPermissions(`SERVER_LOGS_CONFIGS`).READ" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_CONFIGS'), lockOnFetch]">
            <a href="#" @click="updateNavigation('LOGS', 'LOGS_CONFIGS')" class="spectrum-SideNav-itemLink">Configs</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import ServerSwitcher from '../ServerSwitcher.vue'
import ServerMiniCard from '../ServerMiniCard.vue'
export default {
  name: 'SideNavigation',
  components: {
    ServerSwitcher,
    ServerMiniCard
  },
  data() {
    return {
      ready: false,
      openedCategory: {
        CONFIGURATIONS: true,
        LEADERBOARDS: false,
        WARNINGS: false,
        COMMANDS: false,
        REACTION_ROLES: false,
        TICKETS: false,
        LOGS: false
      }
    }
  },
  computed: {
    selectedMenu() {
      return this.$store.state.sideNavigation.activeSubMenu
    },
    getActiveNagivation() {
      return this.$store.state.sideNavigation.activeMenu
    },
    lockOnFetch() {
      return this.$store.state.sideNavigation.lockButton ? ` is-disabled` : null
    },
    getGuild() {
      return this.$store.state.guild
    }
  },
  methods: {
    enabledSubs(list=[]) {
      let totalDisabled = 0
      for (let i = 0; i < list.length; i++) {
        if (this.$store.getters.getPermissions(list[i]).READ === 0) totalDisabled++
      }
      return totalDisabled === list.length ? false : true
    },
    isSelected(menu) {
      return this.selectedMenu === menu ? ` is-selected` : null
    },
    toggleCategory(menu) {
      this.openedCategory[menu] = this.openedCategory[menu] ? false : true
    },
    updateNavigation(menu, defaultSub) {
      //  Act as toggle
      this.$store.commit(`updateSideNavRemoteReload`, true)
      this.$store.commit(`updateSubSideNavigation`, defaultSub)
      this.$store.commit(`updateSideNavigation`, menu)
    }
  },
  created() {
    for (let key in this.openedCategory) this.openedCategory[key] = this.getActiveNagivation !== key ? false : true
    new Promise(resolve => setTimeout(resolve, 500)).then(() => this.ready = true)
  }
}
</script>

<style scoped>
  ::-webkit-scrollbar-track {
    background: var(--spectrum-global-color-gray-75);
  }
  ::-webkit-scrollbar {
    width: 12px;
    background: var(--spectrum-global-color-gray-75);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--spectrum-global-color-gray-300);
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--spectrum-global-color-gray-400);
  }

  .nav {
    display: flex;
    align-items: left;
    flex-direction: column;
    height: 100vh;
    background-color: var(--spectrum-global-color-gray-75);
  }
  .nav ul li {
    padding-right: 32px;
  }
  .spectrum-Card {
    margin: 0 auto 42px auto;
  }
  .switch-btn {
    margin-top: 32px;
    margin-bottom: 16px;
    align-self: flex-start;
  }
</style>