<template>
  <div :class="`spectrum spectrum--medium spectrum--${isDarkTheme ? 'darkest' : 'lightest'} top-container`">
    <Toast />
    <transition name="fade-reverse">
      <ProfilePopover v-if="profilePopoverState" class="popover is-open"/>
    </transition>
    <transition name="slide">
      <MobileMenu v-if="$store.state.topNavigation.drawer && isMobile" />
    </transition>
    <NavBar />
    <Modal v-if="$store.state.modal" :config="$store.state.modal.config"></Modal>
    <router-view v-slot="{ Component }" @click="updateProfilePopover(false); updateNagivationDrawer(false);" class="content">
      <transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import ProfilePopover from './components/ProfilePopover.vue'
import NavBar from './components/NavigationBar.vue'
import Toast from './components/Toast.vue'
import Modal from './components/Modal.vue'
import MobileMenu from './components/MobileMenu.vue'
export default {
  name: `App`,
  inject: [`mq`],
  components: {
    ProfilePopover,
    NavBar,
    Toast,
    Modal,
    MobileMenu
  },
  computed: {
    profilePopoverState() {
      return this.$store.state.profilePopover
    },
    isDarkTheme() {
      return this.$store.state.darkTheme
    },
    isMobile() {
      return this.mq.current === `mobile`
    }
  },
  methods: {
    updateNagivationDrawer(val) {
      return this.$store.commit(`updateNavigationDrawer`, val)
    },
    updateProfilePopover(val) {
      return this.$store.commit(`updateProfilePopover`, val)
    }
  }
}
</script>
<style>
  .top-container {
    max-width: 100vw;
  }
  body {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  li {
    list-style-type: none;
  }
  .spectrum-Card,
  .spectrum-Popover,
  .spectrum-InLineAlert {
    background-color: var(--spectrum-global-color-gray-75);
  }
  .spectrum-InLineAlert {
    color: var(--spectrum-global-color-static-orange-700);
  }
  .spectrum-Textfield-input {
    background-color: var(--spectrum-global-color-gray-200);
  }
  * {
    margin: 0;
  }
  .content {
    max-width: 100vw;
    min-height: calc(100vh - 63px);
    margin-top: 63px;
    max-width: 100%;
  }

  /** Animation collections */
  .fade-enter-active {
    transition: all 0.2s ease-out;
  }
  .fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-enter-from,
  .fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }

</style>
<style scoped>
  .popover {
    z-index: 4;
    position: fixed;
    right: 20px;
    top: 52px;
  }

  /** Animation collections */
  .fade-enter-active {
    transition: all 0.2s ease-out;
  }
  .fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-enter-from,
  .fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }

  /** Animations */
  .slide-enter-active {
    transition: all 0.6s cubic-bezier(.59,.06,.58,.92)
  }
  .slide-leave-active {
    transition: all 0.6s cubic-bezier(.59,.06,.58,.92)
  }
  .slide-enter-from,
  .slide-leave-to {
    transform: translateY(-762px);
  }

  .fade-reverse-enter-active {
    transition: all 0.2s ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>
