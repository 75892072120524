<template>
  <div class="spectrum">
  </div>
</template>
<script>
export default {
  name: 'Login',
  methods: {
    getRandomString() {
      let randomString = ``
			const randomNumber = Math.floor(Math.random() * 10);
			for (let i = 0; i < 20 + randomNumber; i++) {
				randomString += String.fromCharCode(33 + Math.floor(Math.random() * 94))
			}
			return randomString
    }
  },
  mounted() {
    this.$store.commit(`sendToast`, {
      text: `Checking your session`,
      variant: `info`
    })
    this.$http
    .get(`/api/auth`)
    .then(res => {
      if (res.data.sessionAlive) {
        this.$store.commit(`login`, res.data.user)
        return this.$router.push(`/auth`)
      }
      this.$store.commit(`sendToast`, {
        text: `Redirecting to Discord`,
        variant: `info`
      })
      const { redirectUri, clientId } = res.data
      let url = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=identify%20guilds`
      const key = this.getRandomString()
      localStorage.setItem(`state-key`, key)
      url += `&state=${encodeURIComponent(btoa(key))}`
      return location.replace(url)
    })
  }
}
</script>