<template>
  <div :style="{'padding': isMobile ? '32px 28px' : '32px 80px'}" class="spectrum container">
    <hr role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
    <div class="spectrum-Body nav-container">
        <ul class="spectrum-Body spectrum-Body--sizeXS mini-nav">
            <li>
                <a class="spectrum-Link spectrum-Link--quiet spectrum-Link--secondary" target="_blank" rel="noopener noreferrer nofollow" href="https://adobe.com/legal/terms.html">
                Terms of use
                </a>
            </li>
            <li>
                <a class="spectrum-Link spectrum-Link--quiet spectrum-Link--secondary" target="_blank" rel="noopener noreferrer nofollow" href="https://adobe.com/privacy.html">
                Privacy policy
                </a>
            </li>
            <li>
                <a class="spectrum-Link spectrum-Link--quiet spectrum-Link--secondary" target="_blank" rel="noopener noreferrer nofollow" href="https://adobe.com/privacy/cookies.html">
                Cookies
                </a>
            </li>   
        </ul>
        <div class="spectrum-Body spectrum-Body--sizeXS">
            Copyright © {{ getYear() }} Adobe. All rights reserved.
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: `Footer`,
  inject: [`mq`],
  props: {
    transparent: Boolean
  },
  computed: {
    isMobile() {
      return this.mq.current === `mobile`
    }
  },
  methods: {
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
<style scoped>
  .container {
    margin-top: auto;
    box-sizing: border-box;
    width: 100%;
    padding: 32px 80px;
  }
  .spectrum-Divider {
    position: relative;
    margin-top: var(--spectrum-global-dimension-size-200);
    width: 100%;
    border: 1px solid var(--spectrum-divider-m-background-color,var(--spectrum-global-color-gray-300));
  }
  .nav-container {
    display: flex;
    margin-top: 8px;
    width: 100%;
    justify-content: space-between;
  }
  .mini-nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }
  .mini-nav li {
    margin-right: var(--spectrum-global-dimension-size-400);
  }
  .mini-nav li a {
    text-decoration: none;
    color: inherit;
  }
</style>