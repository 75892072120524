<template>
  <div style="padding: 16px 16px 8px 16px;" class="spectrum-Popover">
    <ul style="display: flex; flex-direction: column;" class="spectrum-Menu" role="listbox">
      <li style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <img style="width: 64px; height: 64px;" class="spectrum-Avatar" :src="userAvatar" alt="Avatar">
        <div style="margin-left: 16px" class="spectrum-Typography">
          <h1 style="margin: 0;" class="spectrum-Heading spectrum-Heading--sizeS">{{  user.username  }}</h1>
          <p class="spectrum-Body spectrum-Body--sizeXS">{{ user.username + `#` + user.discriminator }}</p>
        </div>
      </li>
      <div style="display: flex; margin: 16px 0 0 0; padding: 8px 0 0 0;" class="spectrum-Card-footer">
        <transition name="fade-reverse">
          <DevPortalButton v-if="devPortalAccess" style="margin-left: auto" />
        </transition>
        <transition name="fade-reverse">
          <DarkThemeSwitcher v-if="isMobile" />
        </transition>
        <transition name="fade-reverse">
          <button :style="{'margin-left': devPortalAccess ? '0' : 'auto'}" @click="signOut" target="_blank" class="spectrum-Button spectrum-Button--sizeS spectrum-Button--primary spectrum-Button">
            Sign Out
          </button>
        </transition>
      </div>
    </ul>
  </div>
</template>
<script>
import DarkThemeSwitcher from './DarkThemeSwitcher.vue'
import DevPortalButton from './DevPortalButton.vue'
export default {
  name: `ProfilePopover`,
  inject: [`mq`],
  data() {
    return {
      devPortalAccess: null
    }
  },
  components: {
    DarkThemeSwitcher,
    DevPortalButton
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    userAvatar() {
      return `https://cdn.discordapp.com/avatars/${this.user.id}/${this.user.avatar}.png`
    },
    getUsername() {
      if (!this.$store.state.user) return `Not found`
      return this.$store.state.user.username + `#` + this.$store.state.user.discriminator
    },
    isDarkTheme() {
      return this.$store.state.darkTheme
    },
    isMobile() {
      return this.mq.current === `mobile`
    }
  },
  methods: {
    toggleDarkTheme() {
      this.$store.commit(`toggleDarkTheme`)
    },
    signOut() {
      this.$store.commit(`updateProfilePopover`, false)
      this.$store.commit(`logout`)
      this.$http.get(`/api/logout`)
      this.$store.commit(`sendToast`, {
        text: `Logged out`,
        variant: `positive`
      })
      this.$router.push(`/`)
    }
  },
  created() {
    this.$http
    .get(`/api/users/${this.$store.state.user.id}/permission`)
    .then(res => {
      if (res.data.permissionLevel < 3) return this.devPortalAccess = false
      this.devPortalAccess = true
    })
  }
}
</script>
<style scoped>
  .fade-reverse-enter-active {
    transition: all 300ms ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 100ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>