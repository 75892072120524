<template>
  <div style="background-color: transparent;" class="spectrum status">
    <div style="margin-bottom: 32px;" class="spectrum-Typography">
      <h1 style="margin-bottom: 0;" class="spectrum-Heading spectrum-Heading--sizeXXL">Commands</h1>
      <transition name="fade-reverse">
        <div v-if="selectedMenu === 'COMMANDS_OVERVIEW'" class="spectrum-Body">
          <p class="spectrum-Body spectrum-Body--sizeXL">{{ `The overview of ${accumulateCommands()} commands in the server` }}</p>
          <div style="display: flex; flex-direction: row;" class="spectrum-Body">
            <div class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
              <span class="spectrum-Tag-label">Developer</span>
            </div>
            <p style="margin-left: 8px;" class="spectrum-Body spectrum-Body--sizeS">indicates commands that can be only accessed by the developer.</p>
          </div>
          <div style="display: flex; flex-direction: row;" class="spectrum-Body">
            <p style="margin-right: 8px" class="spectrum-Body spectrum-Body--sizeS">While</p>
            <div class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
              <span class="spectrum-Tag-label">Admin</span>
            </div>
            <p style="margin-left: 8px;" class="spectrum-Body spectrum-Body--sizeS">indicates server admin's specific command.</p>
          </div>
          <div style="display: flex; flex-direction: row;" class="spectrum-Body">
            <p style="margin-right: 8px" class="spectrum-Body spectrum-Body--sizeS">Anything outside those permissions will be accessible by the rest of the community and tagged as</p>
            <div class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
              <span class="spectrum-Tag-label">Community</span>
            </div>
            <p style="margin-left: 8px;" class="spectrum-Body spectrum-Body--sizeS">command.</p>
          </div>
        </div>
      </transition>
      <transition name="fade-reverse">
        <div v-if="selectedMenu === 'COMMANDS_REGISTRATION'" class="spectrum-Body">
          <p class="spectrum-Body spectrum-Body--sizeXL">{{ edit.mode ? `Editing metadata of the selected custom command` : `Registering new custom command for current server` }}</p>
        </div>
      </transition>
      <transition name="fade-reverse">
        <div v-if="selectedMenu === 'COMMANDS_IMPORT'" class="spectrum-Body">
          <p class="spectrum-Body spectrum-Body--sizeXL">Import existing custom command from other server</p>
        </div>
      </transition>
    </div>
    <transition name="fade-reverse">
      <div v-if="selectedMenu === 'COMMANDS_OVERVIEW'" class="spectrum-Body">
        <transition name="fade-reverse">
          <Loading :style="{'margin-left': '-1px', 'padding-right': '24px', 'position': 'absolute', 'height': '300px', 'width': '100%'}" :text="'Loading user warnings...'" v-if="loading" />
        </transition>
        <div v-if="$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).READ" class="spectrum-Body">
          <h1 class="spectrum-Heading spectrum-Heading--sizeM">Custom Commands</h1>
          <div style="margin-top: 8px; display: flex;" class="spectrum-Body">
            <form :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE}"  style="width: 350px;" @submit.prevent="" class="spectrum-Search">
              <div :class="{ 'is-disabled': customCommands === false || !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE}" class="spectrum-Textfield" style="width: 100%" >
                <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-Textfield-icon" focusable="false" aria-hidden="true">
                  <use xlink:href="#spectrum-icon-18-Magnify" />
                </svg>
                <input :disabled="customCommands === false || !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE" type="search" placeholder="Search for specific customs" name="search" v-model="query.customs" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
              </div>
              <button :disabled="customCommands === false || !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE" @click="query.customs = ``" type="reset" class="spectrum-ClearButton spectrum-Search-clearButton">
                <svg class="spectrum-Icon spectrum-UIIcon-Cross75" focusable="false" aria-hidden="true">
                  <use xlink:href="#spectrum-css-icon-Cross75" />
                </svg>
              </button>
            </form>
            <button :disabled="!$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE" v-if="customCommands.length > 0" style="margin-left: auto;" @click="$store.commit(`updateSideNavRemoteReload`, true); $store.commit(`updateSubSideNavigation`, `Registration`)" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeM">
              <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                <use xlink:href="#spectrum-icon-18-Add" />
              </svg>
              <span class="spectrum-Button-label">Create new</span>
            </button>
          </div>
          <div style="margin-bottom: 48px;" class="spectrum-Body">
            <hr style="margin: 16px 0 16px 0;" role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
            <div class="spectrum-Body">
              <transition name="fade-reverse">
                <div v-if="customCommandsHandler.length <= 0 && query.customs.length > 0" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
                  <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-icon-18-Info" />
                  </svg>
                  <div class="spectrum-InLineAlert-header">There's not matched result</div>
                  <p class="spectrum-Body spectrum-Body--sizeXS">Try again using different keyword</p>
                </div>
              </transition>
              <transition name="fade-reverse">
                <div v-if="customCommands === false" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
                  <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
                    <use xlink:href="#spectrum-icon-18-Info" />
                  </svg>
                  <div class="spectrum-InLineAlert-header">There are no registered custom commands at the moment</div>
                  <p class="spectrum-Body spectrum-Body--sizeXS">Have pre-made commands in other product servers?</p>
                  <button @click="$store.commit(`updateSideNavRemoteReload`, true); $store.commit(`updateSubSideNavigation`, `Import`)" style="border-radius: 25px; margin-top: 16px;" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeM">
                    <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                      <use xlink:href="#spectrum-icon-18-BoxImport" />
                    </svg>
                    <span class="spectrum-Button-label">Import commands</span>
                  </button>
                  <button @click="$store.commit(`updateSideNavRemoteReload`, true); $store.commit(`updateSubSideNavigation`, `Registration`)" style="margin-left: 8px; border-radius: 25px; margin-top: 16px;" class="spectrum-Button spectrum-Button--primary spectrum-Button--quiet spectrum-Button--sizeM">
                    <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                      <use xlink:href="#spectrum-icon-18-Add" />
                    </svg>
                    <span class="spectrum-Button-label">or create new</span>
                  </button>
                </div>
              </transition>
              <transition-group style="padding: 0;" class="spectrum-Body" tag="ul" name="fade-reverse">
                <li :style="{'display': 'flex', 'flex-direction': 'row', 'align-items': 'center', 'min-height': '64px', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '0 32px 0 32px'}" :key="key" class="card" v-for="(value, key) in customCommandsHandler">
                  <div class="spectrum-Body">
                    <h1 class="spectrum-Heading spectrum-Heading--sizeXS">{{ prefix + value.name }}</h1>
                    <p class="spectrum-Body spectrum-Body--sizeXS">{{ `Updated ${parseTimestamp(Date.now() - new Date(value.meta.updated_at).getTime())} ago`}}</p>
                  </div>
                  <img style="margin-left: auto; width: 24px; height: 24px;" class="spectrum-Avatar" :src="`https://cdn.discordapp.com/avatars/${value.meta.user_id}/${users[value.meta.user_id].avatar}.png`" alt="Avatar">
                  <p style="margin-left: 12px;" class="spectrum-Body spectrum-Body--sizeS">{{ `Created by ${users[value.meta.user_id].displayName}` }}</p>
                  <div style="margin-left: 16px; width: 128px;" class="spectrum-Body">
                    <button :disabled="userPermissionLevel <= 2 || !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE" @click="permissionPopover = permissionPopover === `custom`+key ? null : `custom`+key" class="spectrum-Picker spectrum-Picker--sizeM is-open" aria-haspopup="listbox">
                      <span class="spectrum-Picker-label">{{ parsePermissionLevel(getPermissionLevel(value)) }}</span>
                      <svg class="spectrum-Icon spectrum-UIIcon-ChevronDown100 spectrum-Picker-menuIcon" focusable="false" aria-hidden="true">
                        <use xlink:href="#spectrum-css-icon-Chevron100" />
                      </svg>
                    </button>
                    <transition name="fade-reverse">
                      <div v-if="permissionPopover === `custom`+key" style="margin-top: 8px; width: 128px; z-index: 2" class="spectrum-Popover is-open">
                        <ul class="spectrum-Menu" role="listbox">
                          <li @click="permissionPopover = null; updatePermission(value, 3);" class="spectrum-Menu-item" role="option" aria-selected="true" tabindex="0">
                            <span class="spectrum-Menu-itemLabel">Developer</span>
                          </li>
                          <li @click="permissionPopover = null; updatePermission(value, 2);" class="spectrum-Menu-item" role="option" tabindex="0">
                            <span class="spectrum-Menu-itemLabel">Admin</span>
                          </li>
                          <li @click="permissionPopover = null; updatePermission(value, 1);" class="spectrum-Menu-item" role="option" tabindex="0">
                            <span class="spectrum-Menu-itemLabel">Moderator</span>
                          </li>
                          <li @click="permissionPopover = null; updatePermission(value, 0);" class="spectrum-Menu-item" role="option" tabindex="0">
                            <span class="spectrum-Menu-itemLabel">Community</span>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                  <button :disabled="!$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE" @click="edit.data = value.meta.meta; edit.mode = true; $store.commit(`updateSideNavRemoteReload`, true); $store.commit(`updateSubSideNavigation`, `Registration`);" style="border-radius: 5px;" class="spectrum-Button spectrum-Button--quiet spectrum-Button--primary spectrum-Button--sizeS">
                    <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="Edit">
                      <use xlink:href="#spectrum-icon-18-Edit" />
                    </svg>
                  </button>
                  <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="background-color: var(--spectrum-global-color-gray-400); margin: 16px 0; height: auto; align-self: stretch;"></div>
                  <button :disabled="!$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE" @click="deleteCommand(value.name);" style="border-radius: 5px;" class="spectrum-Button spectrum-Button--quiet spectrum-Button--primary  spectrum-Button--sizeS">
                    <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="Delete">
                      <use xlink:href="#spectrum-icon-18-Delete" />
                    </svg>
                  </button>
                </li>
              </transition-group>
            </div>
          </div>
        </div>
        <div v-if="$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_DEFAULT_COMMANDS`).READ" class="spectrum-Body">
          <h1 class="spectrum-Heading spectrum-Heading--sizeM">Default Commands</h1>
          <form :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_DEFAULT_COMMANDS`).WRITE}" style="width: 350px; margin-top: 8px;" @submit.prevent="" class="spectrum-Search">
            <div :class="{ 'is-disabled': defaultCommands === false || !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_DEFAULT_COMMANDS`).WRITE}" class="spectrum-Textfield" style="width: 100%" >
              <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-Textfield-icon" focusable="false" aria-hidden="true">
                <use xlink:href="#spectrum-icon-18-Magnify" />
              </svg>
              <input :disabled="defaultCommands === false || !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_DEFAULT_COMMANDS`).WRITE" type="search" placeholder="Search for specific defaults" name="search" v-model="query.defaults" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
            </div>
            <button :disabled="defaultCommands === false || !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_DEFAULT_COMMANDS`).WRITE" @click="query.defaults = ``" type="reset" class="spectrum-ClearButton spectrum-Search-clearButton">
              <svg class="spectrum-Icon spectrum-UIIcon-Cross75" focusable="false" aria-hidden="true">
                <use xlink:href="#spectrum-css-icon-Cross75" />
              </svg>
            </button>
          </form>
          <hr style="margin: 16px 0 16px 0;" role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
          <div class="spectrum-Body">
            <div v-if="defaultCommands === false" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
              <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
                <use xlink:href="#spectrum-icon-18-Info" />
              </svg>
              <div class="spectrum-InLineAlert-header">There are no registered default commands at the moment</div>
            </div>
            <transition name="fade-reverse">
              <div v-if="defaultCommandsHandler.length <= 0 && query.defaults.length > 0" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
                <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
                  <use xlink:href="#spectrum-icon-18-Info" />
                </svg>
                <div class="spectrum-InLineAlert-header">There's not matched result</div>
                <p class="spectrum-Body spectrum-Body--sizeXS">Try again using different keyword</p>
              </div>
            </transition>
            <transition-group style="padding: 0;" class="spectrum-Body" tag="ul" name="fade-reverse">
              <li :style="{'display': 'flex', 'flex-direction': 'row', 'align-items': 'center', 'min-height': '64px', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '0 32px 0 32px'}" :key="key" class="card" v-for="(value, key) in defaultCommandsHandler">
                <div class="spectrum-Body">
                  <h1 class="spectrum-Heading spectrum-Heading--sizeXS">{{ prefix + value.name.toLowerCase() }}</h1>
                  <p class="spectrum-Body spectrum-Body--sizeXS">{{ parseAlias(value.aliases) }}</p>
                </div>
                <p style="margin-left: auto;" class="spectrum-Body spectrum-Body--sizeS">{{ value.description }}</p>
                <div style="margin-left: 16px; width: 128px;" class="spectrum-Body">
                  <button :disabled="userPermissionLevel <= 2 || !$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE" @click="permissionPopover = permissionPopover === `default`+key ? null : `default`+key" class="spectrum-Picker spectrum-Picker--sizeM is-open" aria-haspopup="listbox">
                    <span class="spectrum-Picker-label">{{ parsePermissionLevel(getPermissionLevel(value)) }}</span>
                    <svg class="spectrum-Icon spectrum-UIIcon-ChevronDown100 spectrum-Picker-menuIcon" focusable="false" aria-hidden="true">
                      <use xlink:href="#spectrum-css-icon-Chevron100" />
                    </svg>
                  </button>
                  <transition name="fade-reverse">
                    <div v-if="permissionPopover === `default`+key" style="margin-top: 8px; width: 128px; z-index: 2" class="spectrum-Popover is-open">
                      <ul class="spectrum-Menu" role="listbox">
                        <li @click="permissionPopover = null; updatePermission(value, 3);" class="spectrum-Menu-item" role="option" aria-selected="true" tabindex="0">
                          <span class="spectrum-Menu-itemLabel">Developer</span>
                        </li>
                        <li @click="permissionPopover = null; updatePermission(value, 2);" class="spectrum-Menu-item" role="option" tabindex="0">
                          <span class="spectrum-Menu-itemLabel">Admin</span>
                        </li>
                        <li @click="permissionPopover = null; updatePermission(value, 1);" class="spectrum-Menu-item" role="option" tabindex="0">
                          <span class="spectrum-Menu-itemLabel">Moderator</span>
                        </li>
                        <li @click="permissionPopover = null; updatePermission(value, 0);" class="spectrum-Menu-item" role="option" tabindex="0">
                          <span class="spectrum-Menu-itemLabel">Community</span>
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
              </li>
            </transition-group>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade-reverse">
      <div style="display: flex; flex-direction: column; width: 100%;" v-if="selectedMenu === 'COMMANDS_REGISTRATION'" class="spectrum-Body">
        <transition name="fade-reverse">
          <Loading :style="{'margin-left': '-1px', 'padding-right': '24px', 'position': 'absolute', 'height': '300px', 'width': '100%'}" :text="'Loading user warnings...'" v-if="loading" />
        </transition>
        <div style="display: flex; align-items: center; justify-content: center; border-radius: 5px; background-color: var(--spectrum-global-color-gray-200); width: 100%; height: max-content;" class="spectrum-Body">
          <div :style="{ 'display': 'flex', 'border-radius': '5px', 'background-color': embed.simple ? 'transparent' : 'var(--spectrum-global-color-gray-100)', 'width': '75%',  'margin': '70px 0', 'height': 'max-content'}" class="spectrum-Body">
            <div :style="{'border-radius': '5px 0 0 5px', 'background-color': embed.simple ? `transparent` : embed.color, 'width': '8px'}" class="spectrum-Body"></div>
            <div class="spectrum-Body" style="width: 100%; margin: 32px;">
              <h1 v-if="embed.title && !embed.simple" style="margin-bottom: 16px;" class="spectrum-Heading spectrum-Heading--sizeM">{{ embed.title }}</h1>
              <p v-html="parseEmbedContent" style="overflow: hidden;" class="spectrum-Body spectrum-Body--sizeM"></p>
              <img v-if="!embed.simple && embed.image" :src="embed.image" style="padding-top: 24px; max-width: 100%;" />
              <p v-if="embed.footer && !embed.simple" class="spectrum-Body spectrum-Body--sizeS" style="padding-top: 16px;">{{ embed.footer }}</p>
            </div>
            <img v-if="embed.thumbnail && !embed.simple" :src="embed.thumbnail || 'https://pbs.twimg.com/profile_images/1266101503862779905/_tlf7MWH_400x400.png'" style="border-radius: 40px; padding: 32px; width: 128px; height: 128px;" />
          </div>
        </div>
        <p style="font-style: italic; padding: 8px; margin-left: auto;" class="spectrum-Body spectrum-Body--sizeXS">Preview automatically generated in real-time.</p>
        <div style="display: flex;" class="spectrum-Body">
          <h1 style="margin-top: 64px;" class="spectrum-Heading spectrum-Heading--sizeL">Command's metadata</h1>
          <button :disabled="!$store.getters.getPermissions(`SERVER_COMMANDS_REGISTRATION`).WRITE" @click="edit.mode ? updateCommand() : createCustom()" style="margin-left: auto; border-radius: 25px; margin-top: 64px;" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeL">
            <svg class="spectrum-Icon spectrum-Icon--sizeL" focusable="false" aria-hidden="true" aria-label="Edit">
              <use xlink:href="#spectrum-icon-18-GearsAdd" />
            </svg>
            <span class="spectrum-Button-label">{{ edit.mode ? `Update` : `Build` }}</span>
          </button>
        </div>
        <hr style="margin: 16px 0 32px 0;" role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
        <h1 class="spectrum-Heading spectrum-Heading--sizeS" :class="{ 'disabled': edit.mode }">Name or Identifier</h1>
        <p class="spectrum-Body spectrum-Body--sizeS" :class="{ 'disabled': edit.mode }">The name will be used to trigger the command on front of prefix. It is required for each command registration, must be unique and advised to keep it short so it can be easily recognizable.</p>
        <form style="margin-top: 8px;" @submit.prevent="null" class="spectrum-Search">
          <div :class="{ 'is-disabled': edit.mode }" class="spectrum-Textfield" style="width: 100%" >
            <input :disabled="edit.mode" type="search" placeholder="e.g. ping, test" name="search" v-model="embed.name" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
          </div>
        </form>
        <h1 style="margin-top: 32px;" class="spectrum-Heading spectrum-Heading--sizeS" :class="{ 'disabled': embed.simple }">Title</h1>
        <p class="spectrum-Body spectrum-Body--sizeS" :class="{ 'disabled': embed.simple }">The title of the embed. Optional.</p>
        <div :class="{ 'is-disabled': embed.simple }" style="margin-top: 8px; display: inline-block; width: inherit;" class="spectrum-Textfield spectrum-Textfield--multiline">
          <textarea :disabled="embed.simple" style="resize: none; height: 100%; width: 100%;" placeholder="e.g. The origin of Cat'" v-model="embed.title" name="field" class="spectrum-Textfield-input"></textarea>
        </div>
        <h1 style="margin-top: 32px;" class="spectrum-Heading spectrum-Heading--sizeS">Content</h1>
        <p class="spectrum-Body spectrum-Body--sizeS">The primary information to be displayed within the embed. Keep it under 2048 characters at maximum. If you wish to attach command variables for better command interactivity, you can use one of the tags to the text box below</p>
        <ul style="padding: 0;" class="spectrum-Body">
          <li style="padding: 5px 0; list-style-type: none; display: flex; flex-direction: row;" class="spectrum-Body">
            <div class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
              <span class="spectrum-Tag-label">{usertag}</span>
            </div>
            <p style="margin-left: 8px;" class="spectrum-Body spectrum-Body--sizeS">Mentioning the user of the command.</p>
          </li>
          <li style="padding: 5px 0; list-style-type: none; display: flex; flex-direction: row;" class="spectrum-Body">
            <div class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
              <span class="spectrum-Tag-label">{nickname}</span>
            </div>
            <p style="margin-left: 8px;" class="spectrum-Body spectrum-Body--sizeS">Same as tag above, but will display the user nickname instead of mentioning them.</p>
          </li>
          <li style="padding: 5px 0; list-style-type: none; display: flex; flex-direction: row;" class="spectrum-Body">
            <div class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
              <span class="spectrum-Tag-label">{usermention}</span>
            </div>
            <p style="margin-left: 8px;" class="spectrum-Body spectrum-Body--sizeS">Mentioning the target user when the user of the command tagged them.</p>
          </li>
          <li style="padding: 5px 0; list-style-type: none; display: flex; flex-direction: row;" class="spectrum-Body">
            <div class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
              <span class="spectrum-Tag-label">{content}</span>
            </div>
            <p style="margin-left: 8px;" class="spectrum-Body spectrum-Body--sizeS">Including additional message when user of the command specifying it.</p>
          </li>
        </ul>
        <div style="margin-top: 8px; display: inline-block; width: inherit;" class="spectrum-Textfield spectrum-Textfield--multiline">
          <textarea style="height: 100%; max-width: 100%; width: 100%:" placeholder="The content of the embed" v-model="embed.description" name="field" class="spectrum-Textfield-input"></textarea>
        </div>
        <h1 style="margin-top: 32px;" class="spectrum-Heading spectrum-Heading--sizeS" :class="{ 'disabled': embed.simple }">Thumbnail</h1>
        <p class="spectrum-Body spectrum-Body--sizeS" :class="{ 'disabled': embed.simple }">A small image/thumbnail that will be displayed on the right side of the embed. The image must be a valid jpg/png/gif url. Optional</p>
        <div :class="{ 'is-disabled': embed.simple }" style="margin-top: 8px; display: inline-block; width: inherit;" class="spectrum-Textfield spectrum-Textfield--multiline">
          <textarea :disabled="embed.simple" style="resize: none; height: 100%; width: 100%;" placeholder="Image URL" v-model="embed.thumbnail" name="field" class="spectrum-Textfield-input"></textarea>
        </div>
        <h1 style="margin-top: 32px;" class="spectrum-Heading spectrum-Heading--sizeS" :class="{ 'disabled': embed.simple }">Image</h1>
        <p class="spectrum-Body spectrum-Body--sizeS" :class="{ 'disabled': embed.simple }">The primary image that will be displayed on the bottom part of the embed. The image must be a valid jpg/png/gif url. Optional.</p>
        <div :class="{ 'is-disabled': embed.simple }" style="margin-top: 8px; display: inline-block; width: inherit;" class="spectrum-Textfield spectrum-Textfield--multiline">
          <textarea :disabled="embed.simple" style="resize: none; height: 100%; width: 100%;" placeholder="Image URL" v-model="embed.image" name="field" class="spectrum-Textfield-input"></textarea>
        </div>
        <h1 style="margin-top: 32px;" class="spectrum-Heading spectrum-Heading--sizeS" :class="{ 'disabled': embed.adaptiveColor || embed.simple }">Color</h1>
        <p class="spectrum-Body spectrum-Body--sizeS" :class="{ 'disabled': embed.adaptiveColor || embed.simple }">Custom hexcolor for the embed. This will only affect the leftside tip of the embed.</p>
        <form style="margin-top: 8px;" @submit.prevent="null" class="spectrum-Search">
          <div class="spectrum-Textfield" :class="{ 'is-disabled': embed.adaptiveColor || embed.simple }"  style="width: 100%" >
            <input :disabled="embed.adaptiveColor || embed.simple" type="search" placeholder="e.g. #FFF" name="search" v-model="embed.color" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
          </div>
        </form>
        <h1 style="margin-top: 32px;" class="spectrum-Heading spectrum-Heading--sizeS" :class="{ 'disabled': embed.simple }">Footer</h1>
        <p class="spectrum-Body spectrum-Body--sizeS" :class="{ 'disabled': embed.simple }">This part will add a new footer section to the embed.</p>
        <form style="margin-top: 8px;" @submit.prevent="null" class="spectrum-Search">
          <div class="spectrum-Textfield" :class="{ 'is-disabled': embed.simple }"  style="width: 100%" >
            <input :disabled="embed.simple" type="search" placeholder="e.g. just some random footer" name="search" v-model="embed.footer" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
          </div>
        </form>
        <h1 style="margin-top: 32px;" class="spectrum-Heading spectrum-Heading--sizeS" :class="{ 'disabled': embed.simple }">Adaptive Color</h1>
        <p class="spectrum-Body spectrum-Body--sizeS" :class="{ 'disabled': embed.simple }">Toggling this option will use the user's role color as embed color.</p>
        <div @click="embed.adaptiveColor = embed.adaptiveColor ? false : true" class="spectrum-Switch spectrum-Switch--emphasized spectrum-Switch--sizeL">
          <input :checked="embed.adaptiveColor" :disabled="embed.simple" type="checkbox" class="spectrum-Switch-input" id="switch-onoff">
          <span class="spectrum-Switch-switch"></span>
          <label class="spectrum-Switch-label" for="switch-onoff">{{ embed.adaptiveColor ? `Enabled` : `Disabled` }}</label>
        </div>
        <h1 style="margin-top: 32px;" class="spectrum-Heading spectrum-Heading--sizeS">Simplified</h1>
        <p class="spectrum-Body spectrum-Body--sizeS">Toggling this option will make the command to be rendered without an embed. Thumbnail, color, and title will be unavailable in this mode.</p>
        <div @click="embed.simple = embed.simple ? false : true" class="spectrum-Switch spectrum-Switch--emphasized spectrum-Switch--sizeL">
          <input :checked="embed.simple" type="checkbox" class="spectrum-Switch-input" id="switch-onoff">
          <span class="spectrum-Switch-switch"></span>
          <label class="spectrum-Switch-label" for="switch-onoff">{{ embed.simple ? `Enabled` : `Disabled` }}</label>
        </div>
      </div>
    </transition>
    <transition name="fade-reverse">
      <div style="display: flex; flex-direction: column;" v-if="selectedMenu === 'COMMANDS_IMPORT'" class="spectrum-Body">
        <transition name="fade-reverse">
          <Loading :style="{'margin-left': '-1px', 'padding-right': '24px', 'position': 'absolute', 'height': '100%', 'width': '100%'}" :text="'Loading custom commands...'" v-if="loading"/>
        </transition>
        <transition name="fade-reverse">
          <div v-if="allCustomCommands === false" style="width: 468px;" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
            <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
              <use xlink:href="#spectrum-icon-18-Info" />
            </svg>
            <div class="spectrum-InLineAlert-header">There are no available custom commands to be imported</div>
          </div>
        </transition>
        <transition-group style="padding: 0; display: flex; flex-wrap: wrap;" class="spectrum-Body" tag="ul" name="fade-reverse">
          <li :style="{'width': '100%', 'display': 'flex', 'flex-direction': 'column', 'min-height': '64px', 'margin': '1px', 'border-radius': '5px'}" :key="key" class="card" v-for="(value, key) in allCustomCommands">
            <div style="display: flex; width: 100%; padding: 8px 0; align-items: center;" class="spectrum-Body">
              <img style="width: 32px; height: 32px;" class="spectrum-Avatar" :src="`https://cdn.discordapp.com/icons/${value.guild}/${guilds[value.guild].icon}.png?size=512`" alt="Avatar">
              <div style="margin-left: 12px;" class="spectrum-Body">
                <h1 class="spectrum-Heading spectrum-Heading--sizeXS">{{ guilds[value.guild].name }}</h1>
                <p class="spectrum-Body spectrum-Body--sizeXS">{{ `${value.commands.length} custom commands` }}</p>
              </div>
              <button style="margin-left: auto;" @click="value.commands.map(cmd => updateBatch(cmd, `${value.guild}_${cmd.name}`, true))" class="spectrum-Button spectrum-Button--primary spectrum-Button--quiet spectrum-Button--sizeM">
                <span class="spectrum-Button-label">Select all</span>
              </button>
            </div>
            <transition-group style="padding: 0; display: flex; flex-wrap: wrap; width: 100%; align-items: center;" class="spectrum-Body" tag="ul" name="fade-reverse">
              <li style="list-style-type: none; padding: 8px;" :key="id" class="card" v-for="(cmd, id) in value.commands">
                <button @click="updateBatch(cmd, `${value.guild}_${cmd.name}`)" class="spectrum-Button spectrum-Button--primary spectrum-Button--sizeM" :id="`${value.guild}_${cmd.name}`" :class="{selected: selectedCustomCommands.includes(`${value.guild}_${cmd.name}`)}">
                  <span class="spectrum-Button-label">{{ prefix + cmd.name }}</span>
                </button>
              </li>
            </transition-group>
            <hr v-if="(key + 1) !== allCustomCommands.length" style="margin: 32px 0;" role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
          </li>
        </transition-group>
        <div v-if="allCustomCommands.length > 0" style="display: flex; flex-direction: column;" class="spectrum-Body">
          <p style="margin: 24px 0;" class="spectrum-Body spectrum-Body-sizeM">{{ `${batch.length} selected custom commands will be imported to '${$store.state.guild.name}' server and will be available to be used by the community upon confirmation. Please double check to make sure that the commands are correctly selected.` }}</p>
          <div style="margin-left: auto; display: flex;" class="spectrum-ButtonGroup">
            <button style="margin-right: 16px;" :disabled="batch.length <= 0" @click="batch = []; selectedCustomCommands = [];" class="spectrum-Button spectrum-Button--primary spectrum-Button--quiet spectrum-Button--sizeM">
              <span class="spectrum-Button-label">Clear all</span>
            </button>
            <button :disabled="batch.length <= 0 || !$store.getters.getPermissions(`SERVER_COMMANDS_IMPORT`).WRITE" @click="importCommands()" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeM">
              <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                <use xlink:href="#spectrum-icon-18-BoxImport" />
              </svg>
              <span class="spectrum-Button-label">Import now</span>
            </button>
          </div>
        </div>
      </div>
    </transition>
    <div style="display: none;" v-if="isRemoteReload && [`COMMANDS_OVERVIEW`, `COMMANDS_REGISTRATION`, `COMMANDS_IMPORT`].includes(selectedMenu)">{{ refresh() }}</div>
  </div>
</template>

<script>
import ms from 'ms'
import Loading from '../Loading.vue'
export default {
  name: 'Commands',
  inject: [`mq`],
  components: {
    Loading
  },
  data() {
    return {
      batch: [],
      selectedCustomCommands: [],
      defaultCommands: [],
      customCommands: [],
      allCustomCommands: [],
      guilds: {},
      users: {},
      prefix: ``,
      userPermissionLevel: 0,
      edit: {
        mode: false,
        data: {}
      },
      permissionPopover: null,
      embed: {
        name: `test`,
        title: `Adobe's Embed`,
        description: `Adobe's Embed is a powerful tool that allows you to embed Adobe products in your Discord server.`,
        thumbnail: `https://pbs.twimg.com/profile_images/1266101503862779905/_tlf7MWH_400x400.png`,
        image: null,
        color: `#00bfff`,
        footer: `This is a custom command.`,
        simple: false,
        adaptiveColor: false
      },
      query: {
        defaults: ``,
        customs: ``
      },
      loading: false
    }
  },
  computed: {
    selectedMenu() {
      return this.$store.state.sideNavigation.activeSubMenu
    },
    isMobile() {
      return this.mq.current === `mobile`
    },
    isRemoteReload() {
      return this.$store.state.sideNavigation.remoteReload
    },
    parseEmbedContent() {
      let str = `<p>${this.embed.description}`
      const tagStyle = `style="color: ${this.$store.state.darkTheme ? `#FFF` : `#505cdc`}; background-color: ${this.$store.state.darkTheme ? '#3c406f' : '#dbdef4'}; border-radius: 5px; padding: 0 5px;"`
      if (str.match(new RegExp(/{usermention}/g, `g`))) str = str.replace(/{usermention}/g, `<span ${tagStyle}>@yourfriend</span>`)
      if (str.match(new RegExp(/{nickname}/g, `g`))) str = str.replace(/{nickname}/g, this.$store.state.user.username)
      if (str.match(new RegExp(/{usertag}/g, `g`))) str = str.replace(/{usertag}/g, `<span ${tagStyle}>@${this.$store.state.user.username}</span>`)
      if (str.match(new RegExp(/{content}/g, `g`))) str = str.replace(/{content}/g, `(user's follow-up message will be put here)`)
      return str + `</p>`
    },
    customCommandsHandler() {
      if (this.query.customs === ``) return this.customCommands
      const res = this.customCommands.filter(cmd => cmd.name.toLowerCase().match(new RegExp(this.query.customs.toLowerCase(), `g`)))
      if (res.length <= 0) return []
      return res
    },
    defaultCommandsHandler() {
      if (this.query.defaults === ``) return this.defaultCommands
      const res = this.defaultCommands.filter(cmd => cmd.name.toLowerCase().match(new RegExp(this.query.defaults.toLowerCase(), `g`)))
      if (res.length <= 0) return []
      return res
    }
  },
  methods: {
    parseTimestamp(timestamp) {
      return ms(timestamp, {long: true})
    },
    getPermissionLevel(cmd) {
      if (cmd.guildPermissionLevel === undefined) return cmd.permissionLevel
      //  Custom cmd
      if (cmd.guild !== undefined) return cmd.guildPermissionLevel
      //  Default cmd
      if (cmd.guildPermissionLevel[this.$store.state.guild.id] === undefined) return cmd.permissionLevel
      return cmd.guildPermissionLevel[this.$store.state.guild.id]
    },
    async getUserPermissionLevel() {
      const res = await this.$http
      .get(`/api/users/${this.$store.state.user.id}/permission?guild=${this.$store.state.guild.id}`)
      this.userPermissionLevel = res.data.permissionLevel
      return res
    },
    updatePermission(command, permissionLevel) {
      this.getUserPermissionLevel()
      .then(res => {
        if (res.data.permissionLevel <= 2) return
        if (command.guild) {
          command.guildPermissionLevel = permissionLevel
        }
        else {
          if (command.guildPermissionLevel === undefined) command.guildPermissionLevel = {}
          command.guildPermissionLevel[this.$store.state.guild.id] = permissionLevel
        }
        this.$http.put(`/api/commands/permission/${this.$store.state.guild.id}`, { command })
        .then(() => {
          this.$store.commit(`sendToast`, {
            variant: `success`,
            text: `${command.name} command permission has been updated`
          })
        })
      })
    },
    updateCommand() {
      if (!this.$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE) return
      this.$http
      .put(`/api/commands/custom/${this.$store.state.guild.id}/${this.embed.name}`, {
        command: this.embed
      })
      .then(() => {
        this.$store.commit(`sendToast`, {
          variant: `success`,
          text: `${this.embed.name} command has been updated`
        })
        this.$store.commit(`updateSubSideNavigation`, `Overview`)
        this.refresh()
      })
    },
    deleteCommand(commandName) {
      if (!this.$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE) return
      this.$http.delete(`/api/commands/custom/${this.$store.state.guild.id}/${commandName}`)
      .then(() => {
        this.$store.commit(`sendToast`, {
          variant: `success`,
          text: `Command successfully deleted`
        })
        this.customCommands = this.customCommands.filter(cmd => cmd.name !== commandName)
        if (this.customCommands.length <= 0) this.customCommands = false
      })    
    },
    async importCommands() {
      if (!this.$store.getters.getPermissions(`SERVER_COMMANDS_IMPORT`).WRITE) return
      const batchNames = this.batch.map(cmd => cmd.name)
      if (batchNames.filter((item, index) => batchNames.indexOf(item) !== index).length > 0) {
        return this.$store.commit(`sendToast`, {
          variant: `error`,
          text: `Cannot import multiple commands with duplicate name`
        })  
      }
      const customCommands = (await this.$http.get(`/api/commands/custom/${this.$store.state.guild.id}`)).data.commands
      let hasDuplicate = false
      await Promise.all(this.batch.map(cmd => {
        if (customCommands.map(c => c.name).includes(cmd.name)) hasDuplicate = true
      }))
      if (hasDuplicate) {
        return this.$store.commit(`sendToast`, {
          variant: `error`,
          text: `One of the selected commmands already exists in the server`
        })
      }
      this.$store.commit(`sendToast`, {
        variant: `info`,
        text: `Importing commands`
      })
      await Promise.all(this.batch.map(cmd => {
        return this.$http.post(`/api/commands/custom/${this.$store.state.guild.id}`, { command: cmd.meta.meta })
      }))
      this.$store.commit(`sendToast`, {
        variant: `success`,
        text: `${this.batch.length} custom commands successfully imported`
      })
      this.refresh()
    },
    updateBatch(cmd, id, appendOnly = false) {
      if (this.batch.includes(cmd)) {
        if (appendOnly) return
        this.batch = this.batch.filter(c => c !== cmd)
        this.selectedCustomCommands = this.selectedCustomCommands.filter(c => c !== id)
      } else {
        this.batch.push(cmd)
        this.selectedCustomCommands.push(id)
      }
    },
    async fetchGuild(guildId) {
      const res = await this.$http.get(`/api/guilds/${guildId}`)
      return this.guilds[guildId] = res.data.guild
    },
    parsePermissionLevel(level) {
      switch (level) {
        case 0:
          return `Community`
        case 1:
          return `Moderator`
        case 2:
          return `Admin`
        case 3:
          return `Developer`
        default:
          return null
      }
    },
    async createCustom() {
      if (!this.$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW_CUSTOM_COMMANDS`).WRITE) return
      //  Check for duplicate
      this.customCommands = this.customCommands || []
      this.defaultCommands = this.defaultCommands || []
      let src = [...this.defaultCommands, ...this.customCommands]
      if (src.length <= 0) {
        const customCommands = (await this.$http.get(`/api/commands/custom/${this.$store.state.guild.id}`)).data.commands
        const defaultCommands = (await this.$http.get(`/api/commands/default`)).data.commands
        src = [...defaultCommands, ...customCommands]
      }
      const aliases = src.map(c => c.aliases)
      src = [...src.map(c => c.name.toLowerCase()), ...aliases.reduce((a, b) => a.concat(b), [])]
      if (src.includes(this.embed.name.toLowerCase())) return this.$store.commit(`sendToast`, {
        variant: `error`,
        text: `The command's name already taken`
      })
      if (this.embed.simple) {
        this.embed.title = null
        this.embed.thumbnail = null
      }
      this.$http.post(`/api/commands/custom/${this.$store.state.guild.id}`, {
        command: this.embed
      })
      .then(() => {
        this.$store.commit(`sendToast`, {
          variant: `success`,
          text: `${this.embed.name} command successfully created`
        })
        this.$store.commit(`updateSubSideNavigation`, `Overview`)
        this.refresh()
      })
    },
    accumulateCommands() {
      const a = this.customCommands.length || 0
      const b = this.defaultCommands.length || 0
      return a + b
    },
    sort(src) {
      return src.sort((a,b) => (a.name > b.name) 
      ? 1 : ((b.name > a.name) 
      ? -1 : 0))
    },
    parseAlias(arr) {
      if (arr.length <= 0) return `Alias unavailable`
      return arr.map(a => this.prefix + a).join(`, `)
    },
    fetchPrefix() {
      this.$http
      .get(`/api/configurations/local/${this.$store.state.guild.id}`)
      .then(response => {
        const prefix = response.data.configs.find(c => c.ID === `PREFIX`)
        this.prefix = prefix.VALUE
      })
    },
    async fetchCustomCommands() {
      const res = await this.$http.get(`/api/commands/custom/${this.$store.state.guild.id}`)
      this.loading = false
      if (res.data.commands.length <= 0) return this.customCommands = false
      const sorted = this.sort(res.data.commands)
      for (let i=0; i<sorted.length; i++) {
        await new Promise(r => setTimeout(r, 20))
        if (!this.users[sorted[i].meta.user_id]) {
          this.users[sorted[i].meta.user_id] = (await this.$http.get(`/api/users/${sorted[i].meta.user_id}?guild=${this.$store.state.guild.id}`)).data.user
        }
        this.customCommands.push(sorted[i])
      }
    },
    async fetchDefaultCommands() {
      const res = await this.$http.get('/api/commands/default')
      this.loading = false
      if (res.data.commands.length <= 0) return this.defaultCommands = false
      const sorted = this.sort(res.data.commands)
      for (let i=0; i<sorted.length; i++) {
        await new Promise(r => setTimeout(r, 20))
        this.defaultCommands.push(sorted[i])
      }
    },
    async fetchAllCustomCommands() {
      const res = await this.$http.get(`/api/commands/custom`)
      if (res.data.commands.length <= 0) {
        this.loading = false
        return this.allCustomCommands = false
      }
      const ownCustomCommands = (await this.$http.get(`/api/commands/custom/${this.$store.state.guild.id}`)).data.commands
      const customCommandNames = ownCustomCommands.map(c => c.name)
      const sorted = this.sort(res.data.commands.filter(c => (c.guild !== this.$store.state.guild.id) && !customCommandNames.includes(c.name)))
      if (sorted.length <= 0) {
        this.loading = false
        return this.allCustomCommands = false
      }
      for (let i=0; i<sorted.length; i++) {
        await new Promise(r => setTimeout(r, 50))
        //  Fetch guild's meta if not present
        if (!this.guilds[sorted[i].guild]) await this.fetchGuild(sorted[i].guild)
        if (this.allCustomCommands.find(g => g.guild === sorted[i].guild)) {
          this.allCustomCommands.find(g => g.guild === sorted[i].guild).commands.push(sorted[i])
          continue
        }
        this.allCustomCommands.push({
          guild: sorted[i].guild,
          commands: [sorted[i]]
        })
      }
      this.loading = false
    },
    async refresh() {
      if (this.selectedMenu === `COMMANDS_OVERVIEW`) {
        this.permissionPopover = null
        this.$http
        .get(`/api/users/${this.$store.state.user.id}/permission?guild=${this.$store.state.guild.id}`)
        .then(res => this.userPermissionLevel = res.data.permissionLevel)
        this.edit.mode = false
        this.edit.data = {}
        this.$store.commit(`lockSideNavigation`, true)
        this.$store.commit(`updateSideNavRemoteReload`, false)
        this.defaultCommands = []
        this.customCommands = []
        this.prefix = ``
        this.embed = {
          name: `test`,
          title: `Adobe's Embed`,
          description: `Adobe's Embed is a powerful tool that allows you to embed Adobe products in your Discord server.`,
          thumbnail: `https://pbs.twimg.com/profile_images/1266101503862779905/_tlf7MWH_400x400.png`,
          color: `#00bfff`
        }
        this.loading = true
        await this.fetchCustomCommands()
        await this.fetchDefaultCommands()
        this.fetchPrefix()
        return this.$store.commit(`lockSideNavigation`, false)
      }
      else if (this.selectedMenu === `COMMANDS_IMPORT`) {
        this.edit.mode = false
        this.edit.data = {}
        this.loading = true
        this.allCustomCommands = []
        this.batch = []
        this.selectedCustomCommands = []
        this.fetchPrefix()
        this.fetchAllCustomCommands()
      }
      else if (this.selectedMenu === `COMMANDS_REGISTRATION`) {
        if (this.edit.mode) {
          this.embed =  {
            name: this.edit.data.name,
            title: this.edit.data.title,
            description: this.edit.data.description,
            thumbnail: this.edit.data.thumbnail,
            image: this.edit.data.image,
            color: this.edit.data.color,
            footer: this.edit.data.footer,
            simple: this.edit.data.simple,
            adaptiveColor: this.edit.data.adaptiveColor,
            author: []
          }
        }
      }
    }
  },
  created() {
    this.getUserPermissionLevel()
    this.refresh()
  }
}
</script>

<style scoped>
  .selected {
    background-color: var(--spectrum-global-color-gray-800);
    color: var(--spectrum-global-color-gray-200);
  }
  .disabled {
    color: var(--spectrum-global-color-gray-500);
  }
  .fade-reverse-enter-active {
    transition: all 300ms ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 100ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>