<template>
  <div style="background-color: transparent;" class="spectrum reaction-roles">
    <div style="margin-bottom: 32px;" class="spectrum-Typography">
      <h1 style="margin-bottom: 0;" class="spectrum-Heading spectrum-Heading--sizeXXL">Reaction Roles</h1>
      <transition name="fade-reverse">
        <div v-if="selectedMenu === 'REACTION_ROLES_OVERVIEW'" class="spectrum-Body">
          <p class="spectrum-Body spectrum-Body--sizeXL">The overview of currently active reaction roles messages</p>
        </div>
      </transition>
      <transition name="fade-reverse">
        <div v-if="selectedMenu === 'REACTION_ROLES_REGISTRATION'" class="spectrum-Body">
          <p class="spectrum-Body spectrum-Body--sizeXL">Create a new reaction role for message in server</p>
        </div>
      </transition>
    </div>
    <transition name="fade-reverse">
      <div v-if="selectedMenu === 'REACTION_ROLES_OVERVIEW'" class="spectrum-Body" style="width: 100%;">
        <transition name="fade-reverse">
          <Loading :style="{'z-index': '10', 'margin-left': '-1px', 'padding-right': '24px', 'position': 'absolute', 'height': '100px', 'width': '100%'}" :text="'Fetching reaction roles'" v-if="statsLoading" />
        </transition>
        <div class="spectrum-Body" style="margin-top: 48px; display: flex; justify-content: space-evenly">
          <div class="spectrum-Typography">
            <p style="margin: 0" class="spectrum-Body spectrum-Body--sizeS">Total reactive messages</p>
            <transition name="fade-reverse">
              <h1 style="margin-top: 0" class="spectrum-Heading spectrum-Heading--sizeXL">{{ total.reactiveMessages }}</h1>
            </transition>
          </div>
          <div class="spectrum-Typography">
            <p style="margin: 0" class="spectrum-Body spectrum-Body--sizeS">Total reactive roles</p>
            <h1 style="margin-top: 0" class="spectrum-Heading spectrum-Heading--sizeXL">{{ total.reactiveRoles }}</h1>
          </div>
          <div class="spectrum-Typography">
            <p style="margin: 0" class="spectrum-Body spectrum-Body--sizeS">Total blacklisted roles</p>
            <h1 style="margin-top: 0" class="spectrum-Heading spectrum-Heading--sizeXL">{{ total.blacklistedRoles  }}</h1>
          </div>
          <div class="spectrum-Typography">
            <p style="margin: 0" class="spectrum-Body spectrum-Body--sizeS">Total whitelisted roles</p>
            <h1 style="margin-top: 0" class="spectrum-Heading spectrum-Heading--sizeXL">{{ total.whitelistedRoles }}</h1>
          </div>
          <div class="spectrum-Typography">
            <p style="margin: 0" class="spectrum-Body spectrum-Body--sizeS">Total link groups</p>
            <h1 style="margin-top: 0" class="spectrum-Heading spectrum-Heading--sizeXL">{{ total.linkedMessages }}</h1>
          </div>
        </div>
        <div style="margin-top: 32px; display: flex;" class="spectrum-Body">
          <form :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE}" style="width: 350px;" @submit.prevent="" class="spectrum-Search">
            <div :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE}" class="spectrum-Textfield" style="width: 100%" >
              <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-Textfield-icon" focusable="false" aria-hidden="true">
                <use xlink:href="#spectrum-icon-18-Magnify" />
              </svg>
              <input :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE" type="search" placeholder="Search for specific message ID" name="search" v-model="query" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
            </div>
            <button :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE" @click="query = ``" type="reset" class="spectrum-ClearButton spectrum-Search-clearButton">
              <svg class="spectrum-Icon spectrum-UIIcon-Cross75" focusable="false" aria-hidden="true">
                <use xlink:href="#spectrum-css-icon-Cross75" />
              </svg>
            </button>
          </form>
          <button :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE" style="margin-left: auto;" @click="$store.commit(`updateSideNavRemoteReload`, true); $store.commit(`updateSubSideNavigation`, `ReactionRoles-Registration`)" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeM">
            <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
              <use xlink:href="#spectrum-icon-18-Add" />
            </svg>
            <span class="spectrum-Button-label">Create new</span>
          </button>
          </div>
        <hr style="margin-top: 16px; margin-bottom: 16px;"  role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
        <transition name="fade-reverse">
          <div v-if="reactionDataHandler.length <= 0 && query.length > 0" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
            <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
              <use xlink:href="#spectrum-icon-18-Info" />
            </svg>
            <div class="spectrum-InLineAlert-header">There's not matched result</div>
            <p class="spectrum-Body spectrum-Body--sizeXS">Please make sure to put the correct message ID</p>
          </div>
        </transition>
        <div v-if="reactions === false" style="margin: 16px 0;" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
          <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-icon-18-Info" />
          </svg>
          <div class="spectrum-InLineAlert-header">There are no registered reaction roles at the moment</div>
          <p class="spectrum-Body spectrum-Body--sizeXS">The list will be displayed once new one has been created</p>
        </div>
        <transition-group key="0" style="padding: 0;" class="spectrum-Body" tag="ul" name="fade-reverse">
          <li :style="{'display': 'flex', 'flex-direction': 'column', 'min-height': '64px', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '0 32px 0 32px'}" :key="key" class="card" v-for="(value, key) in reactionDataHandler">
            <div class="spectrum-Body" style="display: flex; flex-direction: row; align-items: center; margin: 16px 0;">
              <div @click="redirectToChannel(value.channel.id);" style="cursor: pointer;" class="spectrum-Body">
                <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                  <use xlink:href="#spectrum-icon-18-LinkOut" />
                </svg>
              </div>
              <div style="margin-left: 16px;" class="spectrum-Body">
                <h1 class="spectrum-Heading spectrum-Heading--sizeM">{{ value.message.id }}</h1>
                <p class="spectrum-Body spectrum-Body--sizeXS">{{ `Located at #${value.channel.name}`}}</p>
              </div>
              <p class="spectrum-Body spectrum-Body--sizeXS" style="margin-left: auto">{{ `${value.emojis.length} emojis`}}</p>
              <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="background-color: var(--spectrum-global-color-gray-400); margin: 16px; height: auto; align-self: stretch;"></div>
              <p class="spectrum-Body spectrum-Body--sizeXS">{{ `${value.blacklistStaticCount} blacklist`}}</p>
              <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="background-color: var(--spectrum-global-color-gray-400); margin: 16px; height: auto; align-self: stretch;"></div>
              <p class="spectrum-Body spectrum-Body--sizeXS">{{ `${value.whitelistStaticCount} whitelist`}}</p>
              <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="background-color: var(--spectrum-global-color-gray-400); margin: 16px; height: auto; align-self: stretch;"></div>
              <div style="display: flex; align-items: center;" class="spectrum-Body">
                <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                  <use xlink:href="#spectrum-icon-18-Link" />
                </svg>
                <p class="spectrum-Body spectrum-Body--sizeM" style="margin-left: 4px">{{ value.linksStaticCount - (value.linksStaticCount ? 1 : 0) }}</p>
              </div>
              <button @click="openedTab = openedTab === key ? null : key" :style="{'margin-left': '16px', 'z-index': '5', 'opacity': openedTab !== key ? '1' : '0'}" class="spectrum-Button spectrum-Button--sizeS spectrum-Button--primary">
                Edit
              </button>
              <button @click="openedTab = null" :style="{'position': 'absolute', 'right': '32px', 'opacity': openedTab === key ? '1' : '0'}" class="spectrum-Button spectrum-Button--sizeS spectrum-Button--primary spectrum-Button--quiet">
                <svg class="spectrum-Icon spectrum-UIIcon-Cross200 spectrum-Picker-menuIcon" focusable="false" aria-hidden="true">
                  <use xlink:href="#spectrum-css-icon-Cross200" />
                </svg>
              </button>
            </div>
            <div v-if="openedTab === key" :style="{'display': 'flex', 'flex-direction': 'column', 'padding': '0', 'width': '100%', 'margin': '16px 0'}" class="spectrum-Body">
              <div style="padding-right: 16px; margin-bottom: 16px; display: flex; justify-content: right;" class="spectrum-ButtonGroup">
                <div class="spectrum-Body" style="display: flex; align-items: center; margin-right: auto;">
                  <p>Switch type</p>
                  <div style="margin-left: 12px;" class="spectrum-Body">
                    <button :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE" style="width: 128px;" @click="typePopover = typePopover === key ? null : key" class="spectrum-Picker spectrum-Picker--sizeM is-open" aria-haspopup="listbox">
                      <span class="spectrum-Picker-label">{{ value.type.charAt(0) + value.type.slice(1).toLowerCase() }}</span>
                      <svg class="spectrum-Icon spectrum-UIIcon-ChevronDown100 spectrum-Picker-menuIcon" focusable="false" aria-hidden="true">
                        <use xlink:href="#spectrum-css-icon-Chevron100" />
                      </svg>
                    </button>
                    <transition name="fade-reverse">
                      <div v-if="typePopover === key" style="margin-top: 8px; width: 128px; z-index: 2" class="spectrum-Popover is-open">
                        <ul :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE}" class="spectrum-Menu" role="listbox">
                          <li @click="value.type = `UNIQUE`; typePopover = null" :class="{'is-disabled': value.type === `UNIQUE`}" class="spectrum-Menu-item" role="option" aria-selected="true" tabindex="0">
                            <span class="spectrum-Menu-itemLabel">Unique</span>
                          </li>
                          <li @click="value.type = `VERIFY`; typePopover = null" :class="{'is-disabled': value.type === `VERIFY`}" class="spectrum-Menu-item" role="option" tabindex="0">
                            <span class="spectrum-Menu-itemLabel">Verify</span>
                          </li>
                          <li @click="value.type = `BINDING`; typePopover = null" :class="{'is-disabled': value.type === `BINDING`}" class="spectrum-Menu-item" role="option" tabindex="0">
                            <span class="spectrum-Menu-itemLabel">Binding</span>
                          </li>
                          <li @click="value.type = `NORMAL`; typePopover = null" :class="{'is-disabled': value.type === `NORMAL`}" class="spectrum-Menu-item" role="option" tabindex="0">
                            <span class="spectrum-Menu-itemLabel">Normal</span>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>
                <button @click="unlink(value.message.id);" tyle="margin-left: 8px; padding: 0 18px;" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary" :disabled="getSource(value.message.id).links.length <= 0 || !$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE">
                  <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="Unlink">
                    <use xlink:href="#spectrum-icon-18-Unlink" />
                  </svg>
                  <p style="margin-left: 8px;">Unlink</p>
                </button>
                <button @click="repair(value.message.id);" style="margin-left: 8px; padding: 0 18px;" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary" :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE">
                  <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="Wrench">
                    <use xlink:href="#spectrum-icon-18-Wrench" />
                  </svg>
                  <p style="margin-left: 8px;">Repair</p>
                </button>
                <button @click="clear(value.message.id);" style="margin-left: 8px; padding: 0 18px;" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary" :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE">
                  <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="Delete">
                    <use xlink:href="#spectrum-icon-18-Delete" />
                  </svg>
                  <p style="margin-left: 8px;">Clear</p>
                </button>
                <button @click="saveUpdate(value.message.id);" :disabled="!hasUpdate(value.message.id) || !$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE" style="margin-left: 8px; padding: 0 18px;" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--cta">
                  <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="SaveFloppy">
                    <use xlink:href="#spectrum-icon-18-SaveFloppy" />
                  </svg>
                  <p style="margin-left: 8px;">Update</p>
                </button>
              </div>
              <div class="spectrum-Body" style="display: flex; margin-top: 16px; flex-direction: column;">
                <h1 class="spectrum-Heading spectrum-Heading--sizeXS">Blacklisted roles</h1>
                <p class="spectrum-Body spectrum-Body--sizeXS">The mentioned roles inside this list will be unavailable to claim any registered reaction roles within this message. Has higher priority over whitelist. Separate the IDs with comma.</p>
                <div style="margin-top: 8px; display: inline-block; width: inherit;" class="spectrum-Textfield spectrum-Textfield--multiline">
                  <textarea style="height: 100%; max-width: 100%; width: 100%; resize: none;" placeholder="Role IDs to be blacklisted" v-model="value.blacklist" name="field" class="spectrum-Textfield-input"></textarea>
                </div>
              </div>
              <div class="spectrum-Body" style="display: flex; margin-top: 16px; flex-direction: column;">
                <h1 class="spectrum-Heading spectrum-Heading--sizeXS">Whitelisted roles</h1>
                <p class="spectrum-Body spectrum-Body--sizeXS">The mentioned roles inside this list will enforce the users to own the role first before able to claim the registered roles. In case of user owning both the blacklisted and whitelisted role at the same time, the former will be put in effect first before checking for whitelist. Separate the IDs with comma.</p>
                <div style="margin-top: 8px; display: inline-block; width: inherit;" class="spectrum-Textfield spectrum-Textfield--multiline">
                  <textarea style="height: 100%; max-width: 100%; width: 100%; resize: none;" placeholder="Role IDs to be whitelisted" v-model="value.whitelist" name="field" class="spectrum-Textfield-input"></textarea>
                </div>           
              </div>
              <div class="spectrum-Body" style="display: flex; margin-top: 16px; flex-direction: column;">
                <h1 class="spectrum-Heading spectrum-Heading--sizeXS">Linked group</h1>
                <p class="spectrum-Body spectrum-Body--sizeXS">Linking messages allows behavior-share between message, depending on what type has been set. Altering the type of linked's member will also update the type of whole link group. Separate the IDs with comma.</p>
              <div v-if="value.linksStaticCount > 0" class="spectrum-Body">
                  <p class="spectrum-Body spectrum-Body--sizeXS">{{  `This message already linked with ${value.linksStaticCount - 1} other messages.`  }}</p>
                </div>
                <div v-else style="display: flex; flex-direction: column;" class="spectrum-Body">
                  <div style="margin-top: 8px; display: inline-block; width: inherit;" class="spectrum-Textfield spectrum-Textfield--multiline">
                    <textarea style="height: 100%; max-width: 100%; width: 100%; resize: none;" placeholder="Specify the message ID you want to link with. Make sure they aren't linked to any messages before." v-model="value.links" name="field" class="spectrum-Textfield-input"></textarea>
                  </div>
                </div>
              </div>
              <li class="card" :key="index" v-for="(val, index) in value.emojis">
                <hr style="margin: 16px 0;" role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeS">
                <div style="margin-top: 16px; display: flex; flex-direction: row; align-items: center;" class="spectrum-Body">
                  <div :style="{'opacity': deletionQueue.includes(value.message.id+`_`+index) ? '0.25' : '1'}" style="display: flex; flex-direction: row;" class="spectrum-Body">
                    <h1 class="spectrum-Heading spectrum-Heading--sizeXS">{{ val.emoji }}</h1>
                    <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="margin: 0 16px; background-color: var(--spectrum-global-color-gray-400); height: auto; align-self: stretch;"></div>
                    <li class="card" :key="roleIndex" v-for="(roleValue, roleIndex) in val.roles">
                      <p :style="{'margin-left': roleIndex === 0 ? '0' : '12px'}" class="spectrum-Body spectrum-Body--sizeXS">{{ roleValue.name + (roleIndex === val.roles.length-1 ? `` : `, `)  }}</p>
                    </li>
                  </div>
                  <p :style="{'opacity': deletionQueue.includes(value.message.id+`_`+index) ? '0.25' : '1'}" class="spectrum-Body spectrum-Body--sizeXS" style="font-style: italic; margin-left: auto;">{{ `Added ` + parseTimestamp(val.created_at) + ` ago` }}</p>
                  <button :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).WRITE" v-if="!deletionQueue.includes(value.message.id+`_`+index)" @click="deletionQueue.push(value.message.id+`_`+index);" style="border-radius: 5px; margin-left: 16px;" class="spectrum-Button spectrum-Button--quiet spectrum-Button--primary  spectrum-Button--sizeS">
                    <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="Delete">
                      <use xlink:href="#spectrum-icon-18-Delete" />
                    </svg>
                  </button>
                  <button v-if="deletionQueue.includes(value.message.id+`_`+index)" @click="deletionQueue = deletionQueue.filter(q => q !== value.message.id+`_`+index)" style="margin-left: 8px; padding: 0 18px;" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary spectrum-Button--quiet">
                    <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="Wrench">
                      <use xlink:href="#spectrum-icon-18-Back" />
                    </svg>
                    <p style="margin-left: 8px;">Undo</p>
                  </button>
                </div>
              </li>
            </div>
          </li>
        </transition-group>
      </div>
    </transition>
    <transition name="fade-reverse">
      <div v-if="selectedMenu === 'REACTION_ROLES_REGISTRATION'" class="spectrum-Body" style="display: flex; flex-direction: column; width: 100%;">
        <div class="spectrum-Body">
          <h1 class="spectrum-Heading spectrum-Heading--sizeS">Message ID</h1>
          <p class="spectrum-Body spectrum-Body--sizeS">The target message where the reaction role will be applied on</p>
          <form :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE}" style="margin-top: 8px; width: 100%;" @submit.prevent="null" class="spectrum-Search">
            <div :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE}" class="spectrum-Textfield" style="width: 100%" >
              <input :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE" type="search" placeholder="Message ID" name="search" v-model="registration.message" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
            </div>
          </form>
        </div>
        <div style="margin-top: 32px;" class="spectrum-Body">
          <h1 class="spectrum-Heading spectrum-Heading--sizeS">Channel</h1>
          <p class="spectrum-Body spectrum-Body--sizeS">The target channel where the specified message is sourced from</p>
          <form :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE}" style="margin-top: 8px; width: 100%;" @submit.prevent="null" class="spectrum-Search">
            <div :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE}" class="spectrum-Textfield" style="width: 100%" >
              <input :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE" type="search" placeholder="Channel ID or name" name="search" v-model="registration.channel" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
            </div>
          </form>
        </div>
        <div style="margin-top: 32px;" class="spectrum-Body">
          <h1 class="spectrum-Heading spectrum-Heading--sizeS">Emoji</h1>
          <p class="spectrum-Body spectrum-Body--sizeS">The emoji that will used for the reaction. Emoji can be unicode, name or ID if its server's custom emoji</p>
          <form :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE}" style="margin-top: 8px; width: 100%;" @submit.prevent="null" class="spectrum-Search">
            <div :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE}" class="spectrum-Textfield" style="width: 100%" >
              <input :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE" type="search" placeholder="Emoji unicode, name or ID" name="search" v-model="registration.emoji" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
            </div>
          </form>
        </div>
        <div style="margin-top: 32px;" class="spectrum-Body">
          <h1 class="spectrum-Heading spectrum-Heading--sizeS">Roles</h1>
          <p class="spectrum-Body spectrum-Body--sizeS">The list of roles that will be given once user react to the specified emoji. Roles can be an ID or name. Separate them by a comma if its using multiple roles.</p>
          <form :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE}" style="margin-top: 8px; width: 100%;" @submit.prevent="null" class="spectrum-Search">
            <div :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE}" class="spectrum-Textfield" style="width: 100%" >
              <input :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE" type="search" placeholder="Role name or ID" name="search" v-model="registration.roles" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
            </div>
          </form>
        </div>
        <button :disabled="!$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE" @click="create();" style="margin-left: auto; border-radius: 25px; margin-top: 48px;" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeL">
          <svg class="spectrum-Icon spectrum-Icon--sizeL" focusable="false" aria-hidden="true" aria-label="Edit">
            <use xlink:href="#spectrum-icon-18-GearsAdd" />
          </svg>
          <span class="spectrum-Button-label">Create</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import ms from 'ms'
import Loading from '../Loading'
export default {
  name: 'ReactionRoles',
  inject: ['mq'],
  components: {
    Loading
  },
  data() {
    return {
      typePopover: null,
      openedTab: null,
      query: ``,
      displayStats: false,
      total: {
        reactiveMessages: 0,
        reactiveRoles: 0,
        blacklistedRoles: 0,
        whitelistedRoles: 0,
        linkedMessages: 0,
      },
      statsLoading: false,
      deletionQueue: [],
      reactions: [],
      source: [],
      registration: {
        message: ``,
        channel: ``,
        emoji: ``,
        roles: []
      },
    }
  },
  computed: {
    selectedMenu() {
      return this.$store.state.sideNavigation.activeSubMenu
    },
    reactionDataHandler() {
      //  Search query
      if (this.query !== ``) {
        const res = this.reactions.filter(r => r.message.id.toLowerCase().match(new RegExp(this.query.toLowerCase(), `g`)))
        if (res.length <= 0) return []
        return res
      }
      return this.reactions
    }
  },
  methods: {
    unlink(messageId) {
      if (!this.$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE) return
      this.$http.put(`/api/roles/reactions/${this.$store.state.guild.id}/${messageId}/unlink`)
      .then(() => {
        this.$store.commit(`sendToast`, {
          text: `Successfully unlinked the reaction message`,
          variant: `success`
        })
        this.refreshOverview()
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          text: `Failed to unlink the target message`,
          variant: `error`
        })
      })
    },
    clear(messageId) {
      if (!this.$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE) return
      this.$http.delete(`/api/roles/reactions/${this.$store.state.guild.id}/${messageId}`)
      .then(() => {
        this.$store.commit(`sendToast`, {
          text: `Successfully deleted the reaction roles`,
          variant: `success`
        })
        this.refreshOverview()
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          text: `Failed to delete the reaction roles`,
          variant: `error`
        })
      })
    },
    repair(messageId) {
      if (!this.$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE) return
      this.$http.put(`/api/roles/reactions/${this.$store.state.guild.id}/${messageId}/repair`)
      .then(() => {
        this.$store.commit(`sendToast`, {
          text: `Successfully repaired the reaction message`,
          variant: `success`
        })
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          text: `Failed to repair the target message`,
          variant: `error`
        })
      })
    },
    create() {
      if (!this.$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE) return
      this.$http.post(`/api/roles/reactions/${this.$store.state.guild.id}`, { reaction: this.registration })
      .then(() => {
        this.$store.commit(`sendToast`, {
          text: `Reaction role has been created`,
          variant: `success`
        })
        this.registration = {
          message: ``,
          channel: ``,
          emoji: ``,
          roles: []
        },
        this.$store.commit(`updateSideNavRemoteReload`, true)
        this.$store.commit(`updateSubSideNavigation`, `ReactionRoles-Overview`)
        this.refreshOverview()
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          text: `Failed to create the reaction role`,
          variant: `error`
        })
      })
    },
    saveUpdate(messageId) {
      if (!this.$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).WRITE) return
      let meta = this.reactions.find(r => r.message.id === messageId)
      if (!meta) return this.$store.commit(`sendToast`, {
        text: `The reaction role doesn't exist`,
        variant: `error`
      })
      //  Parse the new data
      meta.links = this.parseStringAsArray(meta.links)
      meta.blacklist = this.parseStringAsArray(meta.blacklist)
      meta.whitelist = this.parseStringAsArray(meta.whitelist)
      meta.deletionQueue = this.deletionQueue
      this.$http.put(`/api/roles/reactions/${this.$store.state.guild.id}/${messageId}`, { reaction: meta })
      .then(() => {
        this.$store.commit(`sendToast`, {
          text: `Changes saved`,
          variant: `success`
        })
        this.refreshOverview()
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          text: `Something went wrong while saving the changes`,
          variant: `error`
        })
      })
    },
    parseStringAsArray(str) {
      if (str === ``) return []
      const arr = str.split(`,`)
      return arr.map(r => r.trim())
    },
    refreshOverview() {
      this.typePopover = null
      this.openedTab = null
      this.query = ``
      this.displayStats = false
      this.total = {
        reactiveMessages: 0,
        reactiveRoles: 0,
        blacklistedRoles: 0,
        whitelistedRoles: 0,
        linkedMessages: 0,
      }
      this.statsLoading = false
      this.deletionQueue = []
      this.reactions = []
      this.source = []
      this.loadOverview()
    },
    async loadOverview() {
      const res = await this.$http.get(`/api/roles/reactions/${this.$store.state.guild.id}`)
      this.source = res.data.reactions
      if (this.source.length <= 0) return this.reactions = false
      this.statsLoading = true
      this.reactions = []
      for (let i=0; i<this.source.length; i++) {
        let dup = JSON.parse(JSON.stringify(this.source[i]))
        dup.blacklistStaticCount = dup.blacklist.length
        dup.whitelistStaticCount = dup.whitelist.length
        dup.linksStaticCount = dup.links.length
        this.reactions.push(dup)
        await new Promise(resolve => setTimeout(resolve, 50))
        if (i === this.source.length - 1) {
          this.statsLoading = false
        }
      }
      this.updateCounter(`reactiveMessages`, this.reactions.length)
      const totalRoles = this.reactions.map(r => r.emojis.reduce((acc, val) => acc + val.roles.length, 0)).reduce((acc, val) => acc + val, 0)
      this.updateCounter(`reactiveRoles`, totalRoles)
      this.updateCounter(`blacklistedRoles`, this.reactions.reduce((acc, val) => acc + val.blacklist.length, 0))
      this.updateCounter(`whitelistedRoles`, this.reactions.reduce((acc, val) => acc + val.whitelist.length, 0))
      this.updateCounter(`linkedMessages`, this.reactions.reduce((acc, val) => acc + val.links.length > 0 ? 1 : 0, 0))
    },
    hasUpdate(messageId) {
      const newMeta = this.reactions.find(r => r.message.id === messageId)
      if (!newMeta) return false
      const originMeta = this.source.find(r => r.message.id === messageId)
      //  Check for type change
      if (newMeta.type !== originMeta.type) return true
      //  Compare newMeta[list] and originMeta[list] to see if there's any change
      if (typeof newMeta.whitelist === `object`) newMeta.whitelist = newMeta.whitelist.join(`,`)
      if (typeof newMeta.blacklist === `object`) newMeta.blacklist = newMeta.blacklist.join(`,`)
      if (typeof originMeta.whitelist === `object`) originMeta.whitelist = originMeta.whitelist.join(`,`)
      if (typeof originMeta.blacklist === `object`) originMeta.blacklist = originMeta.blacklist.join(`,`)
      if (newMeta.whitelist !== originMeta.whitelist) return true
      if (newMeta.blacklist !== originMeta.blacklist) return true
      //  Comapare newMeta.links and originMeta.links
      if (typeof newMeta.links === `object`) newMeta.links = newMeta.links.join(`,`)
      if (typeof originMeta.links === `object`) originMeta.links = originMeta.links.join(`,`)
      if (newMeta.links !== originMeta.links) return true
      //  Check for filled deletion queue
      if (this.deletionQueue.length > 0) return true
      return false
    },
    getSource(messageId) {
      return this.source.find(s => s.message.id === messageId)
    },
    async updateCounter(type, max) {
      for (let i=0; i<max; i++) {
        this.total[type] += 1
        await new Promise(resolve => setTimeout(resolve, 15))
      }
    },
    redirectToChannel(channel) {
      window.open(`https://discord.com/channels/${this.$store.state.guild.id}/${channel}`, '_blank')
    },
    parseTimestamp(timestamp) {
      return ms(Date.now() - new Date(timestamp).getTime(), {long: true})
    }
  },
  created() {
    this.loadOverview()
  }
}
</script>

<style scoped>
  .invis-button {
    opacity: 0;
  }
  .visible-button {
    opacity: 1;
  }
  .fade-reverse-enter-active {
    transition: all 300ms ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 100ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>