<template>
  <section class="spectrum-Body">
    <h2 class="spectrum-Heading spectrum-Heading--sizeS">{{ conf.NAME }}</h2>
    <hr style="margin: 8px 0 16px 0;" class="spectrum-Divider spectrum-Divider--sizeM">
    <p class="spectrum-Body">{{ conf.DESCRIPTION }}</p>
    <p class="spectrum-Body">{{ conf.VALUE_DESCRIPTION }}</p>
    <p v-if="!writeAccess()" class="spectrum-Body">You can't update this setting at the moment.</p>

    <div :style="{'display': writeAccess() ? 'inherit' : 'none'}" v-if="conf.TYPE === 'boolean'" @click="toggleSwitch" class="spectrum-Switch spectrum-Switch--emphasized">
      <input type="checkbox" class="spectrum-Switch-input" id="switch-onoff" :checked="conf.PARSED_VALUE === 1">
      <span class="spectrum-Switch-switch"></span>
      <label class="spectrum-Switch-label" for="switch-onoff">{{ conf.NAME }}</label>
    </div>

    <div :style="{'display': writeAccess() ? 'flex' : 'none'}" style="flex-wrap: wrap; margin-top: 16px;"  v-else-if="conf.TYPE === 'object@boolean'">
      <div style="width: 20%; box-sizing: border-box; min-width: 200px;" v-for="(value, key, index) in conf.PARSED_VALUE" :key="index" @click="toggleSwitch(key)" class="spectrum-Switch spectrum-Switch--emphasized">
        <input type="checkbox" class="spectrum-Switch-input" id="switch-onoff" :checked="value">
        <span class="spectrum-Switch-switch"></span>
        <label class="spectrum-Switch-label" for="switch-onoff">{{ normalizeKey(key)}}</label>
      </div>
    </div>

    <div :style="{'display': writeAccess() ? 'flex' : 'none'}" style="flex-wrap: wrap; margin-top: 16px; max-width: 700px;"  v-else-if="conf.TYPE === 'object@string:channel'">
      <form style="margin: 8px;" v-for="(value, key, index) in conf.PARSED_VALUE" :key="index" @submit.prevent="saveConfig" class="spectrum-Form spectrum-Form--labelsAbove">
        <div class="spectrum-Form-item">
          <label class="spectrum-FieldLabel spectrum-FieldLabel--sizeM spectrum-Form-itemLabel" for="fieldLabelExample-emailaddress">{{  normalizeKey(key)  }}</label>
          <div class="spectrum-Form-itemField">
            <div class="spectrum-Textfield">
              <input :placeholder="conf.PARSED_VALUE[key] ? '#' + (conf.PARSED_VALUE[key].name || 'Unreachable channel') : 'No channel specified'" v-model="inputAsObject[key]" class="spectrum-Textfield-input" aria-invalid="false" type="text">
            </div>
          </div>
        </div>
      </form>
    </div>

    <div :style="{'display': writeAccess() ? 'inherit' : 'none'}" v-else-if="conf.ID === 'WARNING_PENALTY_LEVELS'">
      <form style="margin-top: 16px;" @submit.prevent="save" class="spectrum-Form spectrum-Form--labelsAbove">
        <div class="spectrum-Form-item">
          <label class="spectrum-FieldLabel spectrum-FieldLabel--sizeM spectrum-Form-itemLabel" for="fieldLabelExample-emailaddress">3rd warning</label>
          <div class="spectrum-Form-itemField">
            <div class="spectrum-Textfield">
              <input :placeholder="conf.PARSED_VALUE[3]" v-model="inputAsObject[3]" class="spectrum-Textfield-input" aria-invalid="false" type="text">
            </div>
          </div>
        </div>
        <div class="spectrum-Form-item">
          <label class="spectrum-FieldLabel spectrum-FieldLabel--sizeM spectrum-Form-itemLabel" for="fieldLabelExample-emailaddress">4th warning</label>
          <div class="spectrum-Form-itemField">
            <div class="spectrum-Textfield">
              <input :placeholder="conf.PARSED_VALUE[4]" v-model="inputAsObject[4]" class="spectrum-Textfield-input" aria-invalid="false" type="text">
            </div>
          </div>
        </div>
      </form>
      <p class="spectrum-Body">{{ `On 5th warning, user will be automatically banned from the server.` }}</p>
    </div>

    <form v-else @submit.prevent="save" :style="{'display': writeAccess() ? 'inherit' : 'none'}"  style="margin-top: 16px;" class="spectrum-Form spectrum-Form--labelsAbove">
      <div v-if="conf.TYPE !== 'array'" class="spectrum-Form-item">
        <label class="spectrum-FieldLabel spectrum-FieldLabel--sizeM spectrum-Form-itemLabel" for="fieldLabelExample-emailaddress">
          {{ conf.NAME }}
        </label>
        <div class="spectrum-Form-itemField">
          <div class="spectrum-Textfield">
            <input :placeholder="conf.FORM_PLACEHOLDER" v-model="input" class="spectrum-Textfield-input" aria-invalid="false" type="text">
          </div>
        </div>
      </div>
    </form>

    <div :style="{'opacity': writeAccess() ? '1' : '0'}" style="justify-content: flex-end; margin: 24px 0;" class="spectrum-ButtonGroup">
      <button @click="reset" style="margin-right: 8px;" target="_blank" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--secondary spectrum-Button--quiet">
        Reset
      </button>
      <button v-if="conf.TYPE === 'array'" style="margin-right: 8px;" @click="openAsModal" target="_blank" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary">
        Edit
      </button>
      <button v-if="conf.TYPE !== 'array'"   @click="save" target="_blank" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary" :disabled="isInputUnchanged()">
        Save
      </button>

    </div>
  </section>
</template>
<script>
import ms from 'ms'
export default {
  name: `ConfigurationListItem`,
  data() {
    return {
      input: null,
      inputAsDeletion: null,
      inputAsObject: {}
    }
  },
  props: {
    conf: Object,
    permissions: Object,
    parentPermissions: Object
  },
  methods: {
    writeAccess() {
      if (this.parentPermissions.WRITE === 0) return false
      return this.permissions.WRITE
    },
    normalizeKey(key) {
      const tokens = key.split(`_`)
      return (tokens[0].charAt(0).toUpperCase() + tokens[0].slice(1).toLowerCase()) + ` ` + tokens.slice(1).join(` `).toLowerCase()
    },
    isInputUnchanged() {
      if (this.conf.TYPE.startsWith(`object`)) {
        if (Object.keys(this.inputAsObject).length <= 0) return true
        for (let key in this.inputAsObject) {
          const i = this.inputAsObject[key]
          if ((i === undefined) || (i === ``) || (i === this.conf.VALUE[key])) return true
        }
        return false
      }
      const input = this.inputAsDeletion || this.input
      if ((input === null) || (input === this.conf.VALUE) || (input === ``)) return true
      return false
    },
    //  Provide key for object-based input
    toggleSwitch(key=null) {
      if (this.conf.TYPE.startsWith(`object`)) {
        if (this.inputAsObject[key] === undefined) this.inputAsObject[key] = this.conf.PARSED_VALUE[key]
        return this.inputAsObject[key] = this.inputAsObject[key] === 1 ? 0 : 1
      }
      if (this.input === null) this.input = this.conf.VALUE
      return this.input = this.input ? 0 : 1
    },
    openAsModal() {
      this.$store.commit(`updateModal`, {
        header: this.conf.NAME,
        config: this.conf
      })
    },
    reset() {
      if (!this.$store.getters.getPermissions(`SERVER_CONFIGURATIONS_${this.conf.ID}`).WRITE
        || !this.$store.getters.getPermissions(`SERVER_CONFIGURATIONS`).WRITE
      ) {
        this.$store.commit(`sendToast`, {
          text: `Unathorized updates`,
          variant: `error`
        })
        this.$store.commit(`logout`)
        this.$http.get(`/api/logout`)
        return this.$router.push(`/`)
      }
      const id = this.conf.ID
      this.$store.commit(`sendToast`, {
        text: `${id.charAt(0).toUpperCase() + id.slice(1).toLowerCase()} has been reset to default`,
        variant: `positive`
      })
      this.$http
      .delete(`/api/configurations/local/${this.$store.state.guild.id}/${id}`)
      .then(() => this.$emit(`reload`))
    },
    async save() {
      if (!this.$store.getters.getPermissions(`SERVER_CONFIGURATIONS_${this.conf.ID}`).WRITE
        || !this.$store.getters.getPermissions(`SERVER_CONFIGURATIONS`).WRITE
      ) {
        this.$store.commit(`sendToast`, {
          text: `Unathorized updates`,
          variant: `error`
        })
        this.$store.commit(`logout`)
        this.$http.get(`/api/logout`)
        return this.$router.push(`/`)
      }
      let newValue = this.input
      const baseURL = `/api/configurations/local/${this.$store.state.guild.id}`
      if (this.conf.TYPE === `object@string:channel`) {
        newValue = this.inputAsObject
        const src = await this.$http.get(baseURL)
        let currentObject = src.data.configs.find(c => c.ID === this.conf.ID).VALUE
        for (const key in currentObject) {
          if (newValue[key] === null || newValue[key] === undefined) continue
          try {
            const res = await this.$http.get(`/api/channels`, {
              params: {
                guild: this.$store.state.guild.id, 
                channel: newValue[key]
              }
            })
            currentObject[key] = res.data.channel.id
          }
          catch(e) {
            return this.$store.commit(`sendToast`, {
              text: `Channel doesn't exists in the server`,
              variant: `negative`
            })
          }
        }
        newValue = currentObject
      }

      else if (this.conf.TYPE === `object@boolean`) {
        newValue = this.inputAsObject
        const src = await this.$http.get(baseURL)
        let currentObject = src.data.configs.find(c => c.ID === this.conf.ID).VALUE
        for (const key in currentObject) {
          if (newValue[key] === null || newValue[key] === undefined) continue
          currentObject[key] = newValue[key]
        }
        newValue = currentObject
      }

      else if (this.conf.TYPE === `object@duration`) {
        newValue = this.inputAsObject
        const src = await this.$http.get(baseURL)
        let currentObject = src.data.configs.find(c => c.ID === this.conf.ID).VALUE
        for (const key in currentObject) {
          if (newValue[key] === null || newValue[key] === undefined) continue
          const evaluated = ms(newValue[key])
          if (!evaluated) return this.$store.commit(`sendToast`, {
            text: `${this.conf.NAME} has invalid duration`,
            variant: `negative`
          })
          currentObject[key] = evaluated
        }
        newValue = currentObject
      }


      else if (this.conf.TYPE === `number@duration`) {
        const evaluated = ms(newValue)
        if (!evaluated) return this.$store.commit(`sendToast`, {
          text: `${this.conf.NAME} is invalid`,
          variant: `negative`
        })
        newValue = evaluated
      }


      else if (this.conf.TYPE === `array`) {
        //  Handle list deletion
        newValue = this.inputAsDeletion || this.input
        const src = await this.$http.get(baseURL)
        let currentList = src.data.configs.find(c => c.ID === this.conf.ID).VALUE
        const tokens = newValue.replace(/"|'| /g, ``).split(/,| /)
        for (let i=0; i<tokens.length; i++) {
          if (!this.inputAsDeletion) {
            currentList.push(tokens[i])
            continue
          }
          currentList = currentList.filter(v => v !== tokens[i])
        }
        newValue = currentList
      }


      else if (this.conf.TYPE === `string@role`) {
        try {
          const res = await this.$http.get(`/api/roles`, {
            params: {
              guild: this.$store.state.guild.id, 
              role: newValue
            }
          })
          newValue = res.data.role.id
        }
        catch(e) {
          return this.$store.commit(`sendToast`, {
            text: `Role doesn't exists in the server`,
            variant: `negative`
          })
        }
      }


      else if (this.conf.TYPE === `string@channel`) {
        try {
          const res = await this.$http.get(`/api/channels`, {
            params: {
              guild: this.$store.state.guild.id, 
              channel: newValue
            }
          })
          newValue = res.data.channel.id
        }
        catch(e) {
          return this.$store.commit(`sendToast`, {
            text: `Channel doesn't exists in the server`,
            variant: `negative`
          })
        }
      }

      else if (this.conf.TYPE === `number`) {
        const val = parseInt(newValue)
        if (val <= 0) return this.$store.commit(`sendToast`, {
          text: `The number must be positive`,
          variant: `negative`
        })
        newValue = val
      }

      this.$store.commit(`sendToast`, {
        text: `${this.conf.NAME} updated`,
        variant: `positive`
      })
      await this.$http
      .put(baseURL, {
        configId: this.conf.ID,
        newValue: newValue
      })
      this.$emit(`reload`)
    }
  }
}
</script>
<style scoped>
  button {
    margin-left: 8px;
  }
  form {
    margin: 16px 0;
  }
</style>