<template>
  <nav :style="{'overflow-y': 'scroll', 'padding': isMobile ? '0 var(--spectrum-global-dimension-size-300)' : '0 var(--spectrum-global-dimension-size-700)'}" role="navigation" class="spectrum navbar">
    <div class="desktop" v-if="!isMobile">

      <div class="spectrum-Body logo" @click="closeDrawer; $router.push('/');">
          <img src="../assets/adobe_logo.svg" alt="adobelogo" style="margin-right: var(--spectrum-global-dimension-size-100); width: 24px; height: 20px">
          <strong :style="{color: $store.state.darkTheme ? '#fff' : '#fa0f00'}" class="spectrum-Heading spectrum-Heading--sizeS">
            Adobe
          </strong>
      </div>

      <div class="spectrum-Tabs spectrum-Tabs--horizontal spectrum-Tabs--quiet tabs">
          <div @click="$router.push('/')" :class="dynamicActiveMenu('Home')" tabindex="0">
            <span class="spectrum-Tabs-itemLabel">Home</span>
          </div>
          <div @click="$router.push('/server-selection')" :class="dynamicActiveMenu('Server Selection')" tabindex="0">
            <span class="spectrum-Tabs-itemLabel">Management</span>
          </div>
          <div :class="'spectrum-Tabs-selectionIndicator'+dynamicSelectionIndicator"></div>
      </div>
      <ul style="display: flex; cursor: pointer; list-style-type: none; padding: 0;" class="profile">
        <DarkThemeSwitcher />
        <li @click="toggleProfilePopover(getProfilePopoverState ? false : true)" class="profile" v-if="isLoggedIn">
          <img class="spectrum-Avatar" :src="userAvatar" alt="Avatar">
        </li>
        <button v-if="!isLoggedIn" @click="$router.push('/login')" class="spectrum-Button spectrum-Button--primary spectrum-Button--sizeM">
          <span class="spectrum-Button-label">Sign In</span>
        </button>
      </ul>

    </div>
    
    <div class="mobile" v-if="isMobile">
      <div style="flex: 1; cursor: pointer; width: 20px; height: 20px; margin-right: auto;" @click="toggleDrawer()">
        <svg v-if="!drawer" :style="{color: isDarkTheme ? 'white' : 'black'}" class="spectrum-Icon spectrum-Icon--sizeM spectrum-Toast-typeIcon menu-icon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-icon-18-ShowMenu" />
        </svg>
        <svg v-if="drawer" class="spectrum-Icon spectrum-UIIcon-Cross600" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-css-icon-Cross600" />
        </svg>
      </div>
  
      <div class="spectrum-Body logo" @click="closeDrawer(); $router.push('/');">
          <img src="../assets/adobe_logo.svg" alt="adobelogo" style="margin-right: var(--spectrum-global-dimension-size-100); width: 24px; height: 20px">
          <strong :style="{color: $store.state.darkTheme ? '#fff' : '#fa0f00'}" class="spectrum-Heading spectrum-Heading--sizeS">
            Adobe
          </strong>
      </div>

      <ul style="flex: 1; display: flex; justify-content: right; cursor: pointer; list-style-type: none; padding: 0;" class="profile">
        <li @click="toggleProfilePopover(getProfilePopoverState ? false : true)" class="profile" v-if="isLoggedIn">
          <img class="spectrum-Avatar" :src="userAvatar" alt="Avatar">
        </li>
        <li class="login-btn" v-if="!isLoggedIn">
          <button @click="$router.push('/login')" class="spectrum-Button spectrum-Button--primary spectrum-Button--sizeM">
            <span class="spectrum-Button-label">Sign In</span>
          </button>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import DarkThemeSwitcher from './DarkThemeSwitcher.vue'
export default {
  name: 'NavBar',
  inject: ['mq'],
  components: {
    DarkThemeSwitcher
  },
  computed: {
    drawer() {
      return this.$store.state.topNavigation.drawer
    },
    isMobile() {
      return this.mq.current === `mobile`
    },
    isDarkTheme() {
      return this.$store.state.darkTheme
    },
    activeMenu() {
      return this.$store.state.topNavigation.activeMenu
    },
    getProfilePopoverState: function() {
      return this.$store.state.profilePopover
    },
    userName: function() {
      const user = this.$store.state.user
      if (!user) return null
      return user.username + `#` + user.discriminator 
    },
    isLoggedIn: function() { 
      return this.$store.state.isLoggedIn 
    },
    userAvatar: function() {
      const avatar = this.$store.state.user.avatar
      const id = this.$store.state.user.id
      if (!avatar && !id) return null
      return `https://cdn.discordapp.com/avatars/${id}/${avatar}.png`
    },
    dynamicSelectionIndicator() {
      if (this.activeMenu === `Server Selection`) return ` move`
      return ``
    }
  },
  methods: {
    openDrawer() {
      this.$store.commit(`updateNavigationDrawer`, true)
    },
    closeDrawer() {
      this.$store.commit(`updateNavigationDrawer`, false)
    },
    toggleDrawer() {
      this.$store.state.topNavigation.drawer ? this.closeDrawer() : this.openDrawer()
    },
    dynamicActiveMenu(menu) {
      if (this.activeMenu === menu) return `spectrum-Tabs-item is-selected`
      return `spectrum-Tabs-item`
    },
    setActiveMenu(menu) {
      return this.$store.commit(`updateTopNavigation`, menu)
    },
    toggleProfilePopover: function(val, explicit=false) {
      if (explicit) {
        if ((val === false) && (this.getProfilePopoverState === false)) return
        if ((val === true) && (this.getProfilePopoverState === true)) return
      }
      this.$store.commit(`updateProfilePopover`, val)
    },
    toggleProfile() {
      this.profileOpened = this.profileOpened ? false : true
    },
    logout() {
      this.$store.commit(`logout`)
      this.$http.get(`/logout`)
      this.$store.commit(`sendToast`, {
        text: `Logged out`,
        variant: `positive`
      })
    },
  }
}
</script>
<style scoped>
  .navbar {
    top: 0;
    box-sizing: border-box;
    position: fixed;
    display: flex;
    z-index: 3;
    height: var(--spectrum-global-dimension-size-800);
    border-bottom: var(--spectrum-global-dimension-size-10) solid var(--spectrum-global-color-gray-200);
    justify-content: space-between;
    width: 100vw;
  }
  .logo {
    display: flex;
    cursor: pointer;
  }
  .logo .spectrum-Heading {
    color: #fa0f00;
  }
  .spectrum-Tabs-item {
    margin-left: 0;
    height: 100%;
    padding: 0 12px;
  }
  .spectrum-Tabs-selectionIndicator {
    transform: translateX(12px);
    transition: all 200ms cubic-bezier(.57,.13,.17,.85);
    width: 29px;
    top: 54px;
    left: 28px;
  }
  .spectrum-Tabs-selectionIndicator.move {
    transform: translateX(98px);
    width: 62px;
  }
  .spectrum-Avatar {
    width: var(--spectrum-global-dimension-size-400);
    height: var(--spectrum-global-dimension-size-400);
  }
  .tabs {
    margin: 0;
    padding-left: 24px;
  }
  .right-nav .is-open,
  .right-nav .is-close {
    top: 55px;
    right: 90px;
  }
  .left-nav {
    display: flex;
    align-items: center;
    list-style-type: none;
    height: 64px;
    padding: 0;
  }
  .left-nav li {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .left-nav li a,
  .right-nav li a {
    text-decoration: none;
    color: #323232;
  }
  .right-nav li a,
  .left-nav li a {
    padding: 22px 20px;
  }
  .left-nav .logo {
    height: 100%;
  }
  .left-nav .logo li a {
    margin: 0;
    padding-right: 10px;
    padding-left: 0;
  }
  .right-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0;
    max-height: 64px;
  }
  .right-nav .profile {
    cursor: pointer;
  }
  .desktop {
    width: 100%;
  }
  .desktop .profile {
    margin-left: auto;
  }
  .desktop,
  .logo {
    display: flex;
    align-items: center;
    list-style-type: none;
    height: 100%;
    padding: 0;
  }
  .logo li a {
    color: #ff0000 
  }
  li a {
    text-decoration: none;
  }
  .desktop li a,
  .logo li a {
    font-weight: 100;
    font-size: 14px;
  }
  .desktop li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
  }
  .navbar ul .adobediscord-text {
    display: inline-block;

  }
  .navbar ul .adobediscord-text a {
    font-weight: 700;
    font-size: 16px;
  }
  .desktop li a {
    text-decoration: none;
    color: black;
  }
  .desktop li {
    margin-left: 2.5em;
  }
  .logo li {
    color: red;
    margin: 0 0.25em;
  }
  .logo img {
    width: 27px;
    height: 27px;
  }
  .mobile {
    display: flex;
    flex-direction: columns;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .mobile-Menu {
    z-index: 5;
    background-color: var(--spectrum-global-color-gray-75);
    width: 100vw;
    top: 59px;
    left: 0;
    position: absolute;
    padding: 24px;
  }
  .mobile .list li {
    display: flex;
    padding: 15px 40px;
    font-weight: 900;
  }
  .mobile .list li a {
    text-decoration: none;
    color: black;
  }
</style>
