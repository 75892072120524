<template>
  <div style="background-color: transparent;" class="spectrum">
    <div class="spectrum-Typography">
      <h1 class="spectrum-Heading spectrum-Heading--sizeXL">{{ parseSectionName(selectedMenu) }} events</h1>
      <p style="margin-bottom: 0;" class="spectrum-Body spectrum-Body--sizeM">{{ `Recorded logs for ` + descriptions[selectedMenu] }}</p>
      <p class="spectrum-Body spectrum-Body--sizeM">{{ `Automatically refreshed every ${refreshInterval/1000} seconds` }}</p>
    </div>
    <div style="display: flex; flex-direction: row; align-items: center;" class="spectrum-Body">
      <form @submit.prevent="filterLogs();" style="margin: 16px 0; width: 100%;" class="spectrum-Search" >
        <div style="width: 100%;" class="spectrum-Textfield" :class="{'is-disabled': logs === false}" >
          <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-Textfield-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-icon-18-Magnify" />
          </svg>
          <input type="search" placeholder="Filter logs by particular keywords" name="search" v-model="query" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off" :disabled="logs === false">
        </div>
        <button type="reset" @click="restoreLogs();" class="spectrum-ClearButton spectrum-Search-clearButton" :disabled="logs === false">
          <svg class="spectrum-Icon spectrum-UIIcon-Cross75" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-css-icon-Cross75" />
          </svg>
        </button>
      </form>
      <div style="margin-left: 32px;" class="spectrum-Body">
        <button @click="sortPopover = sortPopover ? false : true" style="margin-left: auto;" class="spectrum-Picker spectrum-Picker--sizeM spectrum-Picker--quiet is-open" aria-haspopup="listbox" :disabled="!logs">
          <span class="spectrum-Picker-label">{{ sortMethod }}</span>
          <svg class="spectrum-Icon spectrum-UIIcon-ChevronDown100 spectrum-Picker-menuIcon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-css-icon-Chevron100" />
          </svg>
        </button>
        <transition name="fade-reverse" mode="out-in">
          <div v-if="sortPopover" class="spectrum-Popover is-open" style="position: absolute; right: 0; z-index: 2; margin-top: 8px; width: 70px;">
            <ul style="text-align: right;" class="spectrum-Menu" role="listbox">
              <li @click="updateSortMethod('Newest')" class="spectrum-Menu-item" role="option" aria-selected="true" tabindex="0">
                <span class="spectrum-Menu-itemLabel">Newest</span>
              </li>
              <li @click="updateSortMethod('Oldest')" class="spectrum-Menu-item" role="option" tabindex="0">
                <span class="spectrum-Menu-itemLabel">Oldest</span>
              </li>
              <li @click="updateSortMethod('Type')" class="spectrum-Menu-item" role="option" tabindex="0">
                <span class="spectrum-Menu-itemLabel">Type</span>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
    <transition name="fade-reverse">
      <Loading :style="{'margin-left': '-1px', 'padding-right': '24px', 'position': 'absolute', 'height': '300px', 'width': '100%'}" :text="'Loading logs...'" v-if="loading" />
    </transition>
    <div v-if="logs === false" style="width: 100%;" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
      <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
        <use xlink:href="#spectrum-icon-18-Info" />
      </svg>
      <div class="spectrum-InLineAlert-header">There's no logs data to be displayed yet</div>
      <div class="spectrum-InLineAlert-content">Make sure the option for this log has been enabled via server configurations and wait for another couple of minutes whilst the events are being collected</div>
    </div>
    <div style="width: 100%; display: flex; flex-direction: column; align-items: center; margin: 32px 0;" v-if="logs === null">
      <h1 class="spectrum-Heading spectrum-Heading--sizeL">No matched logs</h1>
      <p class="spectrum-Body spectrum-Body--sizeM">Try use different keyword</p>
    </div>
    <transition-group :style="{'border-radius': '5px', 'display': 'flex', 'flex-direction': 'column', 'padding': '0'}" class="spectrum-Body" tag="ul" name="fade-reverse">
      <li class="card" :key="key" v-for="(value, key) in logs[page-1]">
        <img style="width: 36px; height: 36px;" class="spectrum-Avatar" :src="value.avatar" alt="Avatar" onerror="this.src='https://www.adobe.com/content/dam/offers-homepage/us/en/homepage/twitter_adobe.png';">
        <div style="width: 100%; margin-left: 24px;" class="spectrum-Body">
          <div style="flex-basis: 60%; display: flex; flex-direction: column;" class="spectrum-Body">
            <h1 style="margin: auto 0;" class="spectrum-Heading spectrum-Heading--sizeXS">{{ value.title }}</h1>
            <p class="spectrum-Body spectrum-Body--sizeXS">{{ value.content }}</p>
          </div> 
        </div>
        <p style="text-align: right; margin-left: 16px;" class="spectrum-Body">{{ parseISODate(value.timestamp) }}</p>
      </li>
    </transition-group>
    <div v-if="logs" style="display: flex; margin-top: 16px; justify-content: center;" class="spectrum-Body">
    <p style="margin: auto 8px;" class="spectrum-Body spectrum-Body--sizeS">Show</p>
    <form @submit.prevent="updateChunks();">
      <div class="spectrum-Textfield spectrum-Pagination-textfield">
        <input style="padding: 8px; text-align: center; width: 40px;" type="number" name="field" autocomplete="off" v-model="chunkSize" class="spectrum-Textfield-input">
      </div>
    </form>
    <p style="margin: auto 0;" class="spectrum-Body spectrum-Body--sizeS">logs per page</p>
    <nav style="margin-left: auto;" class="spectrum-Pagination spectrum-Pagination--explicit">
      <a style="border: none;" @click="updatePageCounter(`-`)" class="spectrum-ActionButton spectrum-ActionButton--sizeM spectrum-ActionButton--quiet spectrum-Pagination-prevButton">
        <svg class="spectrum-Icon spectrum-UIIcon-ChevronLeft100" focusable="false" aria-hidden="true" aria-label="ChevronLeft">
          <use xlink:href="#spectrum-css-icon-Chevron100"></use>
        </svg>
      </a>
      <form @submit.prevent="updatePageCounter(null, pageJump)">
        <div class="spectrum-Textfield spectrum-Pagination-textfield">
          <input type="number" style="width: 40px; text-align: center;" name="field" autocomplete="off" v-model="pageJump" class="spectrum-Textfield-input">
        </div>
      </form>
      <span class="spectrum-Body--secondary spectrum-Pagination-counter">{{ `of ${logs.length} pages` }}</span>
      <a style="border: none;" @click="updatePageCounter(`+`)" class="spectrum-ActionButton spectrum-ActionButton--sizeM spectrum-ActionButton--quiet spectrum-Pagination-nextButton">
        <svg class="spectrum-Icon spectrum-UIIcon-ChevronRight100" focusable="false" aria-hidden="true" aria-label="ChevronLeft">
          <use xlink:href="#spectrum-css-icon-Chevron100"></use>
        </svg>
      </a>
    </nav>
    </div>
    <div style="display: none" v-if="isRemoteReload && types.includes(selectedMenu)">{{ freshPooling() }}</div>
  </div>
</template>

<script>
import ms from 'ms'
import moment from 'moment'
import Loading from '../../components/Loading.vue'
export default {
  name: 'Logs',
  data() {
    return {
      page: 1,
      pageJump: 1,
      chunkSize: 6,
      manualPage: 0,
      sortPopover: false,
      sortMethod: `Newest`,
      query: ``,
      descriptions: {
        'LOGS_WARNINGS': `new warnings and warnings removal`,
        'LOGS_MESSAGES': `message deletion and edited message`,
        'LOGS_ROLES': `new roles, roles deletion, and roles metadata update`,
        'LOGS_THREADS': `new thread and thread deletion`,
        'LOGS_MEMBERS': `member joining, member leave, member avatar updates, member nickname/username updates, and their roles update`,
        'LOGS_BANS': `new bans and bans revoke`,
        'LOGS_CHANNELS': `new channels, channels deletion, and channels metadata update`,
        'LOGS_LOCKDOWNS': `locked down and unlocked channel updates that was performed via lockdown command`,
        'LOGS_EMOJIS': `new emojis, emojis deletion, and emoji metadata updates`,
        'LOGS_CONFIGS': `server configuration updates that was performed either via web dashboard or in-discord commands`
      },
      types: [
      `LOGS_WARNINGS`,
      `LOGS_MESSAGES`,
      `LOGS_ROLES`,
      `LOGS_THREADS`,
      `LOGS_MEMBERS`,
      `LOGS_BANS`,
      `LOGS_CHANNELS`,
      `LOGS_LOCKDOWNS`,
      `LOGS_EMOJIS`,
      `LOGS_CONFIGS`
      ],
      loading: true,
      logs: [],
      source: [],
      timer: ``,
      refreshInterval: 15000
    }
  },
  components: {
    Loading
  },
  computed: {
    isRemoteReload() {
      return this.$store.state.sideNavigation.remoteReload
    },
    selectedMenu() {
      return this.$store.state.sideNavigation.activeSubMenu
    },
    guild() {
      return this.$store.state.guild.id
    }
  },
  methods: {
    parseSectionName(section) {
      const trimmedSection = section.split(`_`).slice(1).join(``)
      return trimmedSection.charAt(0).toUpperCase() + trimmedSection.slice(1).toLowerCase()
    },
    updatePageCounter(operator, jumpTo=false) {
      const newCounter = jumpTo ? jumpTo : operator === `+` ? parseInt(this.page) + 1 : parseInt(this.page) - 1
      if (newCounter < 1 || newCounter > this.logs.length) return
      this.page = newCounter
      this.pageJump = newCounter
    },
    autoRefresh() {
      this.timer = setInterval(function() {
        this.fetchLogs(this.selectedMenu, true)
      }.bind(this), this.refreshInterval)
    },
    updateChunks() {
      this.page = 1
      this.pageJump = 1
      this.logs = []
      this.splitLogsPerChunks(this.sortLogs(this.source))
    },
    updateSortMethod(method) {
      //  Prevent from double sorting on same type
      if (this.sortMethod === method) return
      this.sortMethod = method
      this.sortPopover = false
      this.logs = []
      this.splitLogsPerChunks(this.sortLogs(this.source))
    },
    sortLogs(data) {
      let sorted = this.sortMethod === `Type`
      ? data.sort((a, b) => (a.title > b.title) ? 1 : (a.title < b.title) ? -1 : 0)
      : this.sortMethod === `Oldest`
      ? data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      : data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      return sorted
    },
    parseISODate(date) {
      return moment(date).format(`LLL`)
    },
    parseTimestamp(timestamp) {
      return ms(Date.now() - new Date(timestamp).getTime(), { long:true })
    },
    restoreLogs() {
      this.page = 1
      this.pageJump = 1
      this.query = ``
      this.logs = []
      this.splitLogsPerChunks(this.sortLogs(this.source))
    },
    filterLogs() {
      this.page = 1
      this.pageJump = 1
      const res = this.source.filter(log => {
        const regex = new RegExp(this.query, 'i')
        return regex.test(log.content)
      })
      if (res.length <= 0) return this.logs = null
      this.logs = []
      this.splitLogsPerChunks(res)
    },
    fetchLogs(type, refresh=false, force=false) {
      if (!refresh) {
        this.$store.commit(`lockSideNavigation`, true)
        this.$store.commit(`updateSideNavRemoteReload`, false)
        this.loading = true
      }
      
      //  Hit API
      this.$http
      .get(`/api/logs/${this.guild}/${this.parseSectionName(type).toLowerCase()}`)
      .then(async res => {
        this.loading = false
        //  Empty logs
        if (res.data.logs.length <= 0) {
          this.$store.commit(`lockSideNavigation`, false)
          return this.logs = false
        }
        //  Sorting
        const sorted = this.sortLogs(res.data.logs)
        //  Update logs if it has different size
        if (force || (this.source.length !== sorted.length)) {
          this.splitLogsPerChunks(sorted, refresh)
          this.source = sorted
        }
        else {
          this.source = sorted
          this.$store.commit(`lockSideNavigation`, false)
        }
      })
    },
    async splitLogsPerChunks(arr, isRefresh=false) {
      const chunk = this.chunkSize
      this.logs = arr.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/chunk)
        if(!resultArray[chunkIndex]) resultArray[chunkIndex] = []
        resultArray[chunkIndex][isRefresh ? `unshift` : `push`](item)
        return resultArray
      }, [])
      this.$store.commit(`lockSideNavigation`, false)
    },
    freshPooling() {
      this.page = 1
      this.pageJump = 1
      this.chunkSize = 6
      this.sortPopover = false
      this.logs = []
      this.source = []
      this.fetchLogs(this.selectedMenu)
    }
  },
  created() {
    this.freshPooling()
    this.autoRefresh()
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    background-color: var(--spectrum-global-color-gray-200);
    padding: 12px 24px;
    border-radius: 5px;
    margin: 2px 0;
  }
  .fade-reverse-move {
    transition: transform 150ms ease;
  }
  .fade-reverse-enter-active,
  .fade-reverse-leave-active {
    transition: all 150ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>