<template>
  <div style="background-color: transparent;" class="spectrum container">
    <div class="spectrum-Body" style="box-sizing: border-box;">
      <div style="margin-bottom: 16px" class="spectrum-Typography">
        <h1 class="spectrum-Heading spectrum-Heading--sizeXL">Server warnings</h1>
        <p class="spectrum-Body spectrum-Body--sizeM">List of users that currently have active regular warnings and at higher risks of getting infraction penalties.</p>
      </div>
      <transition name="fade-reverse">
        <Loading :style="{'margin-left': '-1px', 'padding-right': '24px', 'position': 'absolute',  'margin-top': '100px', 'height': '300px', 'width': '100%'}" :text="'Loading user warnings...'" v-if="loading" />
      </transition>
      <div style="display: flex;" class="spectrum-Body">
        <form :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_WARNINGS`).WRITE}" style="width: 250px;" @submit.prevent="findUser(); registrationInterface = false;" class="spectrum-Search">
          <div :class="{'is-disabled': !$store.getters.getPermissions(`SERVER_WARNINGS`).WRITE}" class="spectrum-Textfield" style="width: 100%" >
            <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-Textfield-icon" focusable="false" aria-hidden="true">
              <use xlink:href="#spectrum-icon-18-Magnify" />
            </svg>
            <input :disabled="!$store.getters.getPermissions(`SERVER_WARNINGS`).WRITE" type="search" placeholder="Find member for new warning" name="search" v-model="keyword" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
          </div>
          <button :disabled="!$store.getters.getPermissions(`SERVER_WARNINGS`).WRITE" @click="refreshFindUserPopover(); target = null; registrationInterface = false;" type="reset" class="spectrum-ClearButton spectrum-Search-clearButton">
            <svg class="spectrum-Icon spectrum-UIIcon-Cross75" focusable="false" aria-hidden="true">
              <use xlink:href="#spectrum-css-icon-Cross75" />
            </svg>
          </button>
        </form>
        <transition name="fade-reverse">
          <div v-if="findUserPopover" class="spectrum-Popover is-open" style="box-sizing: border-box; z-index: 2; margin-top: 40px;">
            <ul class="spectrum-Menu" role="listbox" style="flex; justify-content; center; align-items: center;">
              <li v-if="findUserLoading" class="spectrum-Menu-item" role="option" aria-selected="true" tabindex="0">
                <div class="spectrum-ProgressCircle spectrum-ProgressCircle--indeterminate">
                  <div class="spectrum-ProgressCircle-track"></div>
                  <div class="spectrum-ProgressCircle-fills">
                    <div class="spectrum-ProgressCircle-fillMask1">
                      <div class="spectrum-ProgressCircle-fillSubMask1">
                        <div class="spectrum-ProgressCircle-fill"></div>
                      </div>
                    </div>
                    <div class="spectrum-ProgressCircle-fillMask2">
                      <div class="spectrum-ProgressCircle-fillSubMask2">
                        <div class="spectrum-ProgressCircle-fill"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <div style="margin: 16px 32px;" v-else-if="matchedTarget === false">
                <h1 class="spectrum-Heading spectrum-Heading--sizeXS">No matched member</h1>
                <p class="spectrum-Body spectrum-Body--sizeXS">Try use different keyword</p>
              </div>
              <div v-else-if="matchedTarget" class="spectrum-Body" style="display: flex;"> 
                <transition-group style="display: flex; padding: 0; flex-direction: column;" tag="ul" name="fade-reverse">
                  <div style="display: flex; width: 256px;" @click="target = value; registrationInterface = true; refreshFindUserPopover()" v-for="(value, key) in matchedTarget" :key="key" class="spectrum-Menu-item"  role="option" aria-selected="true" tabindex="0">
                    <img style="width: 32px; height: 32px;" class="spectrum-Avatar" :src="avatar(value)" alt="Avatar">
                    <div style="padding-left: 16px;" class="spectrum-Body">
                      <h1 class="spectrum-Heading spectrum-Heading--sizeS">{{ value.displayName }}</h1>
                      <p class="spectrum-Body spectrum-Body--sizeXS">{{ `ID: ${value.userId}` }}</p>
                    </div>
                  </div>
                </transition-group>
              </div>
            </ul>
          </div>
        </transition>
      </div>
      <transition name="fade-reverse">
        <div v-if="registrationInterface" class="spectrum-Body">
          <div class="spectrum-Body">
            <h1 style="margin: 16px 0 8px 0;" class="spectrum-Heading spectrum-Heading--sizeM">Registering new warning</h1>
            <hr style="margin-bottom: 32px;" role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
          </div>
          <div style="max-width: 500px; display: flex; flex-direction: column;" class="spectrum-Body">
            <div style="display: flex;" class="spectrum-Body">
              <img style="width: 64px; height: 64px;" class="spectrum-Avatar" :src="avatar(target)" alt="Avatar">
              <div style="padding-left: 24px;" class="spectrum-Body">
                <h1 class="spectrum-Heading spectrum-Heading--sizeM">{{ target.displayName }}</h1>
                <p class="spectrum-Body spectrum-Body--sizeXS">{{ `ID: ${target.userId}` }}</p>
                <p style="margin-top: 8px;" class="spectrum-Body spectrum-Body--sizeXS">{{ `You are going to give ${target.displayName} a server warning. Please provide a reason for this action in the provided text box below (can be omitted if its not needed).` }}</p>
              </div>
            </div>
            <div style="display: inline-block; width: inherit; margin: 16px 0;" class="spectrum-Textfield spectrum-Textfield--multiline">
              <textarea style="resize: none; height: 100%;" placeholder="e.g. 'They stole our cake.'" v-model="reason" name="field" class="spectrum-Textfield-input"></textarea>
            </div>
            <button @click="saveWarning()" style="margin-left: auto; border-radius: 25px;" class="spectrum-Button spectrum-Button--cta spectrum-Button--sizeM">
              <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                <use xlink:href="#spectrum-icon-18-SaveFloppy" />
              </svg>
              <span class="spectrum-Button-label">Save</span>
            </button>
          </div>
        </div>
      </transition>
      <h1 style="margin: 16px 0 8px 0;" class="spectrum-Heading spectrum-Heading--sizeM">Currently active warnings</h1>
      <hr style="margin-bottom: 16px;" role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
      <div v-if="regular === false" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
        <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-icon-18-Info" />
        </svg>
        <div class="spectrum-InLineAlert-header">There are no active warnings at this time</div>
      </div>
      <transition-group :style="{'display': 'flex', 'flex-direction': 'column', 'padding': '0'}" class="spectrum-Body" tag="ul" name="fade-reverse">
        <li :key="key" :style="{'display': 'flex', 'flex-direction': 'column', 'align-items': 'flex-start', 'min-height': '64px', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '0 32px 0 32px'}" class="card" v-for="(value, key) in regular">
          <div style="display: flex; margin: 16px 0; width: 100%;">
            <img style="width: 32px; height: 32px; margin: auto 0;" class="spectrum-Avatar" :src="value.warnings[0].user ? value.warnings[0].user.avatar : 'https://pbs.twimg.com/profile_images/1266101503862779905/_tlf7MWH_400x400.png'" alt="Avatar">
            <div class="spectrum-Body">
              <h1 style="padding-left: 16px;" class="spectrum-Heading spectrum-Heading--sizeS">{{ value.warnings[0].user ? value.warnings[0].user.username : 'User unreachable' }}</h1>
              <h1 style="padding-left: 16px;" class="spectrum-Body spectrum-Body--sizeXS">{{ value.warnings.length + ` total warnings` }}</h1>
            </div>
            <div style="margin: auto 0 auto auto" class="buttons">
              <button v-if="openedTab !== key" @click="openedTab = key" class="spectrum-Button spectrum-Button--sizeS spectrum-Button--primary">
                Details
              </button>
              <a v-if="openedTab === key" @click="openedTab = null" style="border: none; width: 59.05px;" class="spectrum-Button spectrum-Button--sizeS close">
                <span style="color: var(--spectrum-global-color-gray-800);" class="spectrum-Button-label">Close</span>
              </a>
            </div>
          </div>
          <transition-group v-if="openedTab === key" :style="{'display': 'flex', 'flex-direction': 'column', 'padding': '0', 'width': '100%'}" class="spectrum-Body" tag="ul" name="fade-reverse">
            <li style="margin-bottom: 16px;" class="card" :key="index" v-for="(val, index) in value.warnings">
              <hr role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
              <div class="spectrum-Body" style="display: flex; align-items: center; flex-direction: row; width: 100%; height: 64px;">
                <h1 class="spectrum-Heading spectrum-Heading--sizeS">{{ `Warning ID:${val.id}` }}</h1>
                <p style="font-style: italic; margin-left: auto; padding-right: 16px;" class="spectrum-Body spectrum-Body--sizeS">{{ parseTimestamp(Date.now() - new Date(val.created_at).getTime()) + ` ago` }}</p>
              </div>
              <div class="spectrum-Body">
                <p class="spectrum-Body spectrum-Body--sizeS">{{ `This warning was issued to the user at ${val.created_at} due to following reason:: "${val.reason || `Not provided`}"` }}</p>
                <p class="spectrum-Body spectrum-Body--sizeS">{{ `Currently the user has a total of ${getUserWarnings(val.user_id).length} active warnings at this time and will be scheduled to get reset at ${distanceToReset(val.user_id).toISOString()} (in ${parseTimestamp(new Date(distanceToReset(val.user_id)).getTime() - Date.now())}) assuming the user does not receiving any kind of infraction within the specified timeframe.` }}</p>
                <button :disabled="!$store.getters.getPermissions(`SERVER_WARNINGS`).WRITE" @click="revokeWarning(val.id)" style="margin: 16px 0 16px 0;" class="spectrum-Button spectrum-Button--primary spectrum-Button--negative spectrum-Button--sizeM">
                  <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Delete">
                    <use xlink:href="#spectrum-icon-18-Delete" />
                  </svg>
                  <span class="spectrum-Button-label">Revoke</span>
                </button>
              </div>
            </li>
          </transition-group>
        </li>
      </transition-group>
    </div>
    <p style="margin-top: 16px;" class="spectrum-Body spectrum-Body--sizeXS">The displayed data is reflecting to the current state of active warnings in the current server.<br />It's not updated in realtime, so it is advised to frequently refresh the page to get the newest data.</p>
    <div style="display: none;" v-if="isRemoteReload && ['WARNINGS_REGULAR'].includes(subCategory)">{{ refreshPool() }}</div>
  </div>
</template>

<script>
import ms from 'ms'
import Loading from '../Loading.vue'
export default {
  name: 'Warning',
  inject: [`mq`],
  components: {
    Loading
  },
  data() {
    return {
      regular: [],
      warningDuration: null,
      openedTab: null,
      keyword: ``,
      target: null,
      matchedTarget: [],
      loading: false,
      findUserLoading: false,
      findUserPopover: false,
      registrationInterface: false,
      reason: ``
    }
  },
  computed: {
    isRemoteReload() {
      return this.$store.state.sideNavigation.remoteReload
    },
    subCategory() {
      return this.$store.state.sideNavigation.activeSubMenu
    },
    isMobile() {
      return this.mq.current === `mobile`
    }
  },
  methods: {
    avatar(user) {
      if (user.avatar) return `https://cdn.discordapp.com/avatars/${user.userId}/${user.avatar}.png`
      let parseUrl = user.displayAvatarURL
      if (!parseUrl) return `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5}.png`
      parseUrl = parseUrl.replace(`size=2048`, `size=256`)
      parseUrl = parseUrl.replace(`webp`, `png`)
      return parseUrl
    },
    refreshFindUserPopover() {
      this.findUserLoading = false
      this.findUserPopover = false
    },
    async findUser() {
      if (!this.$store.getters.getPermissions(`SERVER_WARNINGS`).WRITE) return
      this.target = null
      this.matchedTarget = []
      this.findUserPopover = true
      this.findUserLoading = true
      const res = await this.$http.get(`/api/users/${this.keyword}/multimatch`, {
        params: {
          guild: this.$store.state.guild.id
        }
      })
      this.keyword = ``
      this.findUserLoading = false
      if (!res.data.users) return this.matchedTarget = false
      this.matchedTarget = res.data.users
    },
    distanceToReset(userId) {
      return new Date(new Date(this.getUserMostRecentWarning(userId).created_at).getTime() + this.warningDuration)
    },
    async getWarningResetDuration() {
      if (this.warningDuration !== null) return
      const res = await this.$http.get(`/api/configurations/local/${this.$store.state.guild.id}`)
      this.warningDuration = res.data.configs.find(v => v.ID === `WARNING_RESET_COUNTDOWN`).VALUE
    },
    getUserMostRecentWarning(userId) {
      const pool = this.regular.find(v => v.user_id === userId).warnings
      const res = pool.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((b.created_at < a.created_at) ? -1 : 0))
      return res[0]
    },
    getUserWarnings(userId) {
      return this.regular.find(v => v.user_id === userId).warnings
    },
    parseTimestamp(timestamp) {
      return ms(timestamp, {long: true})
    },
    saveWarning() {
      this.$http.post(`/api/warnings/regular/${this.$store.state.guild.id}/${this.target.userId}`, {
        reason: this.reason,
        author: this.$store.state.user.username
      })
      .then(() => {
        this.$store.commit(`sendToast`, {
          text: `${this.target.displayName} has been given a warning`,
          variant: `positive`
        })
        this.refreshPool()
      })
    },
    revokeWarning(warningId) {
      if (!this.$store.getters.getPermissions(`SERVER_WARNINGS`).WRITE) return
      this.$http
      .delete(`/api/warnings/regular/${this.$store.state.guild.id}/${warningId}`)
      .then(() => {
        this.$store.commit(`sendToast`, {
          text: `Warning ID:${warningId} has been deleted`,
          variant: `positive`
        })
        this.refreshPool()
      })
    },
    async refreshPool() {
      this.warningDuration = null
      this.keyword = ``
      this.target= null
      this.loading = false
      this.findUserLoading = false
      this.findUserPopover = false
      this.registrationInterface = false
      this.reason = ``
      this.loading = true
      this.$store.commit(`lockSideNavigation`, true)
      this.$store.commit(`updateSideNavRemoteReload`, false)
      this.regular = []
      this.openedTab = null
      await this.getWarningResetDuration()
      await this.fetchRegular()
      return this.$store.commit(`lockSideNavigation`, false)
    },
    async fetchRegular() {
      const pool = await this.$http.get(`/api/warnings/regular/${this.$store.state.guild.id}`)
      this.loading = false
      if (pool.data.warnings.length <= 0) return this.regular = false
      let merged = []
      pool.data.warnings.forEach(v => {
        if (merged.find(w => w.user_id === v.user_id)) {
          merged.find(w => w.user_id === v.user_id).warnings.push(v)
        } else {
          merged.push({
            user_id: v.user_id,
            warnings: [v]
          })
        }
      })
      for (let i=0; i<merged.length; i++) {
        await new Promise(r => setTimeout(r, 150))
        this.regular.push(merged[i])
      }
    }
  }
}
</script>

<style scoped>
  .close:hover {
    text-decoration: underline;
  }
  .card {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    list-style-type: none;
  }
  .fade-reverse-enter-active {
    transition: all 150ms ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 150ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>