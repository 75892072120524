<template>
  <ul class="spectrum-Menu container" role="listbox">
    <div style="padding: 0 12px; display: flex; flex-direction: column;" v-if="$route.name === 'Dashboard'">
      <ServerSwitcher />
      <ServerMiniCard>
        <template v-slot:caption>
          Editing
        </template>
      </ServerMiniCard>
    </div>
    <div v-if="$route.name === 'Dashboard'">
      <div style="margin: 14px 12px;" class="spectrum-Menu-divider" role="separator"></div>
      <span class="spectrum-Menu-sectionHeading" id="menu-heading-category-2" aria-hidden="true">Main</span>
      <ul class="spectrum-Menu" role="group" aria-labelledby="menu-heading-category-1" aria-disabled="true">
        <li @click="$router.push('/')" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Home</span>
        </li>
        <li @click="$router.push('/server-selection')" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Management</span>
        </li>
      </ul>
      <div style="margin: 14px 12px;" class="spectrum-Menu-divider" role="separator"></div>
      <span class="spectrum-Menu-sectionHeading" id="menu-heading-category-2" aria-hidden="true">Dashboard</span>
      <ul class="spectrum-Menu" role="group" aria-labelledby="menu-heading-category-1" aria-disabled="true">
        <li v-if="$store.getters.getPermissions(`SERVER_CONFIGURATIONS`).READ" style="display: flex; flex-direction: column; align-items: flex-start;" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Configurations</span>
          <ul style="display: inherit" class="spectrum-SideNav">
            <li style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_OVERALL'), lockOnFetch]">
              <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_OVERALL')" class="spectrum-SideNav-itemLink">Overall</a>
            </li>
            <li style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_LOGS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_LOGS')" class="spectrum-SideNav-itemLink">Logs</a>
            </li>
            <li style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_MODERATIONS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_MODERATIONS')" class="spectrum-SideNav-itemLink">Moderations</a>
            </li>
            <li style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('CONFIGURATIONS_LEVELS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('CONFIGURATIONS', 'CONFIGURATIONS_LEVELS')" class="spectrum-SideNav-itemLink">Levels</a>
            </li>
          </ul>
        </li>
        <li v-if="$store.getters.getPermissions(`SERVER_LEADERBOARDS`).READ && enabledSubs([`SERVER_LEADERBOARDS_GLOBAL`, `SERVER_LEADERBOARDS_LOCAL`])" style="display: flex; flex-direction: column; align-items: flex-start;" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Leaderboards</span>
          <ul style="display: inherit" class="spectrum-SideNav">
            <li v-if="$store.getters.getPermissions(`SERVER_LEADERBOARDS_GLOBAL`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LEADERBOARDS_GLOBAL'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LEADERBOARDS', 'LEADERBOARDS_GLOBAL')" class="spectrum-SideNav-itemLink">Global</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LEADERBOARDS_LOCAL`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LEADERBOARDS_LOCAL'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LEADERBOARDS', 'LEADERBOARDS_LOCAL')" class="spectrum-SideNav-itemLink">Server</a>
            </li>
          </ul>
        </li>
        <li v-if="$store.getters.getPermissions(`SERVER_WARNINGS`).READ" style="display: flex; flex-direction: column; align-items: flex-start;" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Warnings</span>
          <ul style="display: inherit" class="spectrum-SideNav">
            <li style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('WARNINGS_REGULAR'), lockOnFetch]">
              <a href="#" @click="updateNavigation('WARNINGS', 'WARNINGS_REGULAR')" class="spectrum-SideNav-itemLink">Regular</a>
            </li>
          </ul>
        </li>
        <li v-if="$store.getters.getPermissions(`SERVER_COMMANDS`).READ && enabledSubs([`SERVER_COMMANDS_OVERVIEW`, `SERVER_COMMANDS_REGISTRATION`, `SERVER_COMMANDS_IMPORT`])" style="display: flex; flex-direction: column; align-items: flex-start;" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Commands</span>
          <ul style="display: inherit" class="spectrum-SideNav">
            <li v-if="$store.getters.getPermissions(`SERVER_COMMANDS_OVERVIEW`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('COMMANDS_OVERVIEW'), lockOnFetch]">
              <a href="#" @click="updateNavigation('COMMANDS', 'COMMANDS_OVERVIEW')" class="spectrum-SideNav-itemLink">Overview</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_COMMANDS_REGISTRATION`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('COMMANDS_REGISTRATION'), lockOnFetch]">
              <a href="#" @click="updateNavigation('COMMANDS', 'COMMANDS_REGISTRATION')" class="spectrum-SideNav-itemLink">Registration</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_COMMANDS_IMPORT`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('COMMANDS_IMPORT'), lockOnFetch]">
              <a href="#" @click="updateNavigation('COMMANDS', 'COMMANDS_IMPORT')" class="spectrum-SideNav-itemLink">Import</a>
            </li>
          </ul>
        </li>
        <li v-if="$store.getters.getPermissions(`SERVER_REACTION_ROLES`).READ && enabledSubs([`SERVER_REACTION_ROLES_OVERVIEW`, `SERVER_REACTION_ROLES_REGISTRATION`])" style="display: flex; flex-direction: column; align-items: flex-start;" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Reaction Roles</span>
          <ul style="display: inherit" class="spectrum-SideNav">
            <li v-if="$store.getters.getPermissions(`SERVER_REACTION_ROLES_OVERVIEW`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('REACTION_ROLES_OVERVIEW'), lockOnFetch]">
              <a href="#" @click="updateNavigation('REACTION_ROLES', 'REACTION_ROLES_OVERVIEW')" class="spectrum-SideNav-itemLink">Overview</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_REACTION_ROLES_REGISTRATION`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('REACTION_ROLES_REGISTRATION'), lockOnFetch]">
              <a href="#" @click="updateNavigation('REACTION_ROLES', 'REACTION_ROLES_REGISTRATION')" class="spectrum-SideNav-itemLink">Registration</a>
            </li>
          </ul>
        </li>
        <li v-if="$store.getters.getPermissions(`SERVER_TICKETS`).READ && enabledSubs([`SERVER_TICKETS_OVERVIEW`, `SERVER_TICKETS_ARCHIVE`])" style="display: flex; flex-direction: column; align-items: flex-start;" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Tickets</span>
          <ul style="display: inherit" class="spectrum-SideNav">
            <li v-if="$store.getters.getPermissions(`SERVER_TICKETS_OVERVIEW`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('TICKETS_OVERVIEW'), lockOnFetch]">
              <a href="#" @click="updateNavigation('TICKETS', 'TICKETS_OVERVIEW')" class="spectrum-SideNav-itemLink">Overview</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_TICKETS_ARCHIVE`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('TICKETS_ARCHIVE'), lockOnFetch]">
              <a href="#" @click="updateNavigation('TICKETS', 'TICKETS_ARCHIVE')" class="spectrum-SideNav-itemLink">Archive</a>
            </li>
          </ul>
        </li>
        <li v-if="$store.getters.getPermissions(`SERVER_LOGS`).READ && enabledSubs([
        `SERVER_LOGS_WARNINGS`, 
        `SERVER_LOGS_MESSAGES`, 
        `SERVER_LOGS_ROLES`, 
        `SERVER_LOGS_THREADS`, 
        `SERVER_LOGS_MEMBERS`, 
        `SERVER_LOGS_BANS`, 
        `SERVER_LOGS_CHANNELS`, 
        `SERVER_LOGS_LOCKDOWNS`, 
        `SERVER_LOGS_EMOJIS`, 
        `SERVER_LOGS_CONFIGS`])" style="display: flex; flex-direction: column; align-items: flex-start;" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Logs</span>
          <ul style="display: inherit" class="spectrum-SideNav">
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_WARNINGS`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_WARNINGS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_WARNINGS')" class="spectrum-SideNav-itemLink">Warnings</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_MESSAGES`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_MESSAGES'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_MESSAGES')" class="spectrum-SideNav-itemLink">Messages</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_ROLES`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_ROLES'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_ROLES')" class="spectrum-SideNav-itemLink">Roles</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_THREADS`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_THREADS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_THREADS')" class="spectrum-SideNav-itemLink">Threads</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_MEMBERS`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_MEMBERS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_MEMBERS')" class="spectrum-SideNav-itemLink">Members</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_BANS`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_BANS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_BANS')" class="spectrum-SideNav-itemLink">Bans</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_CHANNELS`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_CHANNELS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_CHANNELS')" class="spectrum-SideNav-itemLink">Channels</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_LOCKDOWNS`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_LOCKDOWNS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_LOCKDOWNS')" class="spectrum-SideNav-itemLink">Lockdowns</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_EMOJIS`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_EMOJIS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_EMOJIS')" class="spectrum-SideNav-itemLink">Emojis</a>
            </li>
            <li v-if="$store.getters.getPermissions(`SERVER_LOGS_CONFIGS`).READ" style="margin: 8px 8px 8px 0;" class="spectrum-SideNav-item" role="menuitem" tabindex="0" :class="[isSelected('LOGS_CONFIGS'), lockOnFetch]">
              <a href="#" @click="updateNavigation('LOGS', 'LOGS_CONFIGS')" class="spectrum-SideNav-itemLink">Configs</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-else>
      <span class="spectrum-Menu-sectionHeading" id="menu-heading-category-2" aria-hidden="true">Main</span>
      <ul class="spectrum-Menu" role="group" aria-labelledby="menu-heading-category-1" aria-disabled="true">
        <li @click="$router.push('/')" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Home</span>
        </li>
        <li @click="$router.push('/server-selection')" class="spectrum-Menu-item" role="menuitem" tabindex="0">
          <span class="spectrum-Menu-itemLabel">Management</span>
        </li>
      </ul>
    </div>
  </ul>
</template>
<script>
import ServerSwitcher from './ServerSwitcher.vue'
import ServerMiniCard from './ServerMiniCard.vue'
export default {
  name: `MobileMenu`,
  components: {
    ServerSwitcher,
    ServerMiniCard
  },
  computed: {
    selectedMenu() {
      return this.$store.state.sideNavigation.activeSubMenu
    },
    getActiveNagivation() {
      return this.$store.state.sideNavigation.activeMenu
    },
    lockOnFetch() {
      return this.$store.state.sideNavigation.lockButton ? ` is-disabled` : null
    },
    getGuild() {
      return this.$store.state.guild
    }
  },
  methods: {
    enabledSubs(list=[]) {
      let totalDisabled = 0
      for (let i = 0; i < list.length; i++) {
        if (this.$store.getters.getPermissions(list[i]).READ === 0) totalDisabled++
      }
      return totalDisabled === list.length ? false : true
    },
    isSelected(menu) {
      return this.selectedMenu === menu ? ` is-selected` : null
    },
    updateNavigation(menu, defaultSub) {
      //  Act as toggle
      this.$store.commit(`updateSideNavRemoteReload`, true)
      this.$store.commit(`updateSubSideNavigation`, defaultSub)
      this.$store.commit(`updateSideNavigation`, menu)
    }
  }
}
</script>
<style scoped>
  .container {
    position: fixed;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    align-items: left;
    z-index: 3;
    background-color: var(--spectrum-global-color-gray-75);
    width: 100vw;
    top: 60px;
    max-height: calc(80vh - 64px);
    padding: 32px 16px;
  }
  .spectrum-SideNav {
    flex-wrap: wrap;
  }
</style>