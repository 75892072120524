<template>
    <div style="display: flex; justify-content: center; align-items: center; cursor: pointer;" @click="toggleDarkTheme">
      <svg v-if="isDarkTheme" style="color: var(--spectrum-global-color-static-gray-200)" class="spectrum-Icon spectrum-Icon--sizeM spectrum-Toast-typeIcon" focusable="false" aria-hidden="true">
        <use xlink:href="#spectrum-icon-18-Light" />
      </svg>
      <svg v-else style="color: var(--spectrum-global-color-static-gray-900)" class="spectrum-Icon spectrum-Icon--sizeM spectrum-Toast-typeIcon" focusable="false" aria-hidden="true">
        <use xlink:href="#spectrum-icon-18-Moon" />
      </svg>
    </div>
</template>
<script>
export default {
  name: `DarkThemeSwitcher`,
  computed: {
    isDarkTheme() {
      return this.$store.state.darkTheme
    }
  },
  methods: {
    toggleDarkTheme() {
      this.$store.commit(`toggleDarkTheme`)
      localStorage.setItem(`darkTheme`, this.isDarkTheme)
    }
  }
}
</script>