<template>
  <div v-if="server" style="background-color: var(--spectrum-global-color-gray-200);" class="spectrum-Card spectrum-Card--small spectrum-Card--horizontal" tabindex="0" role="figure">
    <div class="spectrum-Card-preview" style="padding: 0">
        <img style="width: 54px;" :src="server.image">
    </div>
    <div class="spectrum-Card-body">
        <div class="spectrum-Card-header">
        <div class="spectrum-Card-title spectrum-Heading spectrum-Heading--sizeXS">{{ textOverflow(server.name) }}</div>
        </div>
        <div class="spectrum-Card-content">
        <div class="spectrum-Card-subtitle spectrum-Detail spectrum-Detail--sizeS">
        <slot name="caption">
            Cool
        </slot>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: `ServerMiniCard`,
  computed: {
    server() {
      return this.$store.state.guild
    }
  },
  methods: {
    textOverflow(text) {
      if (text.length >= 13) return text.slice(0, 13) + `...`
      return text
    }
  } 
}
</script>
<style scoped>
</style>