<template>
  <div class="spectrum modal-backdrop">
    <div class="modal">
        <div class="spectrum-Modal-wrapper spectrum-CSSExample-dialog">
          <div class="spectrum-Modal is-open">
            <h1 class="spectrum-Dialog-heading">
              {{ config.NAME }}
            </h1>
            <hr class="spectrum-Divider spectrum-Divider--sizeM spectrum-Divider--horizontal spectrum-Dialog-divider">
            <p class="spectrum-Body" style="margin: 0 0 14px 0;">Add a new item for this configuration in the provided text box below.<br />For multiple entries, you can try separate them with a linebreak.</p>
          <div style="display: inline-block; width: 100%;" class="spectrum-Textfield spectrum-Textfield--multiline">
            <textarea style="resize: none; height: 100%;" placeholder="Got anything in mind?" v-model="content" name="field" class="spectrum-Textfield-input"></textarea>
          </div>
          <div style="display: flex; justify-content: flex-end; margin: 24px 0 0 0;" class="spectrum-ButtonGroup">
            <button @click="close()" target="_blank" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--secondary spectrum-Button--quiet">
              Close
            </button>
            <button @click="save()" target="_blank" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary" :disabled="originalContent === content" style="margin-left: 8px">
              Save
            </button>
          </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: `Modal`,
  data() {
    return {
      originalContent: null,
      content: null
    }
  },
  props: {
    config: Object
  },
  methods: {
    close() {
      this.$store.commit('updateModal', null)
    },
    async save() {
      if (!this.$store.getters.getPermissions(`SERVER_CONFIGURATIONS_${this.config.ID}`).WRITE
        || !this.$store.getters.getPermissions(`SERVER_CONFIGURATIONS`).WRITE
      ) {
        this.$store.commit(`sendToast`, {
          text: `Unathorized updates`,
          variant: `error`
        })
        this.$store.commit(`logout`)
        this.$http.get(`/api/logout`)
        return this.$router.push(`/`)
      }
      this.$store.commit(`sendToast`, {
        text: `Entries updated for ${this.config.NAME}`,
        variant: `positive`
      })
      let newContent = this.content.split(`\n`)
      //  Nullify emptied input
      if (newContent[0] === ``) newContent = []
      if (newContent.length <= 0) {
        await this.$http
        .delete(`/api/configurations/local/${this.$store.state.guild.id}/${this.config.ID}`)
      }
      else {
        await this.$http
        .put(`/api/configurations/local/${this.$store.state.guild.id}`, {
          configId: this.config.ID,
          newValue: newContent
        })
      }
      this.close()
      this.$store.commit(`updateSideNavRemoteReload`, true)
    }
  },
  created() {
    const content = this.config.VALUE.join(`, `).replace(/, /g, `\n`)
    this.originalContent = content
    this.content = content
  }
}
</script>
<style scoped>
  .modal-backdrop {
    position: fixed;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    background-color: var(--spectrum-global-color-gray-100);
    width: 50vw;
    height: 50vh;
    padding: 40px;
    border-radius: 5px;
  }
  .spectrum-Modal-wrapper,
  .spectrum-Modal.is-open {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .spectrum-Divider {
    margin-bottom: 24px;
    margin-top: var(--spectrum-global-dimension-size-200);
    width: 100%;
    border: 1px solid var(--spectrum-divider-m-background-color,var(--spectrum-global-color-gray-300));
  }
  .spectrum-Textfield,
  .spectrum-Textfield-input {
    flex-grow: 1;
  }
</style>  