<template>
  <div :style="{'align-items': 'center', 'flex-direction': 'column', 'display': 'flex'}" class="spectrum container">
    <transition name="fade">
      <Loading v-if="initLoading" style="position: fixed; width: 100vw; height: 100vh;" :text="'Fetching portal...'" />
    </transition>
    <transition name="fade">
      <section v-if="authorized" :style="{'max-width': isMobile ? '100%' : '1183px', 'padding': isMobile ? '24px 24px' : '24px 80px'}" class="spectrum-Body sub-container">
        <h1 style="margin: 64px 0;" class="spectrum-Heading spectrum-Heading--sizeXXL">Developer Portal</h1>
        <div style="margin-top: 64px;" class="spectrum-Body">
          <div class="spectrum-Typography">
            <h1 class="spectrum-Heading spectrum-Heading--sizeL">User Access Control</h1>
            <hr style="margin-bottom: 16px;"  role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
            <p class="spectrum-Body spectrum-Body--sizeL header-caption">User Access Control (UAC) allows controlling the permission of users who have access to certain part of the selected server's dashboard. As reference, user-specific permission has higher hierarchy over the role one and will get overriden if there's any. The access is granted to every server admins by default.</p>
          </div>
          <transition name="fade">
            <div v-if="session.uac.mostRecents.length > 0" class="spectrum-Typography">
              <h1 class="spectrum-Heading spectrum-Heading--sizeS">Recent permission changes</h1>
              <hr style="margin-bottom: 4px; margin-top: 8px;"  role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeS">
              <p class="spectrum-Body spectrum-Body--sizeS">The most recent updates of user permission access.</p>
            </div>
          </transition>
          <transition-group key="0" style="padding: 0; display: flex; box-sizing: border-box; margin-top: 8px; flex-direction: column;" class="spectrum-Body" tag="ul" name="fade-reverse">
            <li :style="{'height': '64px', 'align-items': 'center', 'display': 'flex', 'flex-direction': 'row', 'margin-top': '2px', 'padding': '0 32px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px'}" :key="key" v-for="(value, key) in recentPermissionChangesHandler">
              <img style="z-index: 2; border: solid 4px var(--spectrum-global-color-gray-200); width: 32px; height: 32px;" class="spectrum-Avatar" :src="avatar(cache.users[value.RAW.target_id])" alt="Avatar">
              <img style="position: absolute; margin-left: 24px; z-index: 1; width: 32px; height: 32px;" class="spectrum-Avatar" :src="avatar(cache.guilds[value.RAW.guild_id])" alt="Avatar">
              <div style="padding-left: 48px; display: flex; flex-direction: column;" class="spectrum-Body">
                <h1 class="spectrum-Heading spectrum-Heading--sizeXS">{{ value.NAME }}</h1>
                <h1 class="spectrum-Body spectrum-Body--sizeXS">{{ `${cache.users[value.RAW.target_id].userId ? cache.users[value.RAW.target_id].displayName : cache.users[value.RAW.target_id].name} (${cache.users[value.RAW.target_id].userId ? `user` : `role`}) in ${cache.guilds[value.RAW.guild_id].name}` }}</h1>
              </div>
              <div style="display: flex; flex-direction: row; margin-left: auto;" class="spectrum-Body">
                <div style="margin: 0;" v-if="value.PERMISSIONS.READ === 1" class="spectrum-Body spectrum-Body--sizeXS">{{ `Read${value.PERMISSIONS.WRITE ? ',' : ''}` }}</div>
                <div style="margin: 0;" v-if="value.PERMISSIONS.WRITE === 1" :style="{'margin-left': value.PERMISSIONS.READ ? '8px' : '0'}" class="spectrum-Body spectrum-Body--sizeXS">{{ `Write` }}</div>
                <div style="margin: 0;" v-if="
                  (value.PERMISSIONS.READ === 0 && value.PERMISSIONS.WRITE === 0) 
                  || (value.PERMISSIONS.READ === 0 && value.PERMISSIONS.WRITE === undefined)
                  || (value.PERMISSIONS.READ === undefined && value.PERMISSIONS.WRITE === 0)" 
                  class="spectrum-Body spectrum-Body--sizeXS">{{ `Disabled access` }}
                </div>
              </div>
              <div style="text-align: right; width: 8%; margin-top: 0; margin-bottom: 0; font-style: italic;" class="spectrum-Body spectrum-Body--sizeXS">{{ parseTimestamp(value.RAW.updated_at) + " ago" }}</div>
              <button style="margin-left: 24px;" @click="selectTargetViaMostRecent(value.RAW.target_id, value.RAW.guild_id)" class="spectrum-Button spectrum-Button--primary spectrum-Button--sizeS">
                <span class="spectrum-Button-label">View</span>
              </button>
            </li>
          </transition-group>
          <transition name="fade">
            <div v-if="session.uac.mostRecents.length > 0" style="margin-top: 48px" class="spectrum-Typography">
              <h1 class="spectrum-Heading spectrum-Heading--sizeS">Currently active permissions</h1>
              <hr style="margin-bottom: 4px; margin-top: 8px;"  role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeS">
              <p class="spectrum-Body spectrum-Body--sizeS">List of users in each server that have its permissions edited and not using the default one. Read-only.</p>
              <transition name="fade">
                <Loading v-if="session.uac.activePermissions.loading" style="left: 0; position: absolute; width: 100%; height: 128px;" :text="'Retrieving active permissions ...'" />
              </transition>
              <transition-group :style="{'display': 'flex', 'flex-direction': 'row', 'padding': '0'}" class="spectrum-Body" tag="ul" name="fade-reverse">
                <li @click="session.uac.activePermissions.guild = key; session.uac.activePermissions.target = Object.keys(session.uac.mostRecentsGrouped[key])[0]" 
                  class="spectrum-Menu-item" :key="key" :style="{
                    'opacity': session.uac.activePermissions.guild !== key && session.uac.activePermissions.guild !== null  ? `0.25` : `1`, 
                    'border-radius': session.uac.activePermissions.guild === key ? '5px 5px 0 0' : `5px`, 
                    'background-color': 'var(--spectrum-global-color-gray-200)', 
                    'display': 'flex', 
                    'flex-direction': 'row', 
                    'margin': '0 2px', 
                    'padding': '0 18px'}" 
                  v-for="(value, key) in session.uac.mostRecentsGrouped">
                  <div style="display: flex; flex-direction: row; margin: 8px 0;">
                    <img style="width: 32px; height: 32px; margin: auto 0;" class="spectrum-Avatar" :src="avatar(cache.guilds[key])" alt="Avatar">
                    <div style="padding-left: 12px;" class="spectrum-Body">
                      <h1 style="margin-bottom: 0;" class="spectrum-Heading spectrum-Heading--sizeXS">{{ cache.guilds[key].name }}</h1>
                      <p class="spectrum-Body spectrum-Body--sizeXS">{{ countStoredPermissions(value) + ` modified permissions` }}</p>
                    </div>
                  </div>
                </li>
              </transition-group>
              <transition name="fade">
                <div v-if="session.uac.activePermissions.guild" style="aling-items: left; justify-content: left; padding: 36px 24px; display: flex; margin: 0 2px; background-color: var(--spectrum-global-color-gray-200); height: 512px; box-sizing: border-box;" class="spectrum-Body">
                  <transition-group style="-ms-overflow-style: none; scrollbar-width: none;" :style="{'display': 'flex', 'width': '25%', 'justify-content': 'left', 'flex-direction': 'column', 'padding': '0'}" class="spectrum-Body hide-scrollbar" tag="ul" name="fade-reverse">
                    <li @click="session.uac.activePermissions.target = key" 
                      class="spectrum-Menu-item" :key="key" :style="{
                        'opacity': session.uac.activePermissions.target !== key && session.uac.activePermissions.target !== null  ? `0.25` : `1`,
                        'border-radius': `5px`, 
                        'display': 'flex',
                        'align-items': 'flex-start',
                        'padding': '0 16px',
                        'justify-content': 'left',
                        'flex-direction': 'column'}" 
                      v-for="(value, key) in session.uac.mostRecentsGrouped[session.uac.activePermissions.guild]">
                      <div style="display: flex; flex-direction: row;">
                        <img style="width: 32px; height: 32px; margin: auto 0;" class="spectrum-Avatar" :src="avatar(cache.users[key])" alt="Avatar">
                        <div style="padding-left: 12px;" class="spectrum-Body">
                          <h1 style="margin-bottom: 0;" class="spectrum-Heading spectrum-Heading--sizeXS">{{ cache.users[key].displayName || cache.users[key].name }}</h1>
                          <p class="spectrum-Body spectrum-Body--sizeXS">{{ `${cache.users[key].userId ? `User ID:` : `Role ID:`}` + (cache.users[key].userId ? cache.users[key].userId : cache.users[key].id) }}</p>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                  <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="background-color: var(--spectrum-global-color-gray-400); margin: 16px 0 16px 24px; height: auto; align-self: stretch;"></div>
                  <div v-if="session.uac.activePermissions.target" class="spectrum-Body" style="display: flex; flex-direction: column; width: 75%;"> 
                    <div style="margin: 0; padding-left: 40px; margin-bottom: 24px;" class="spectrum-Typography">
                      <h1 style="margin-bottom: 4px;" class="spectrum-Heading spectrum-Heading--sizeL">{{  `${session.uac.mostRecentsGrouped[session.uac.activePermissions.guild][session.uac.activePermissions.target].length} Modified Permissions` }}</h1>
                      <p class="spectrum-Body spectrum-Body--sizeS">{{ `Last updated ${parseTimestamp(session.uac.mostRecentsGrouped[session.uac.activePermissions.guild][session.uac.activePermissions.target][0].RAW.updated_at)} ago` }}</p>
                    </div> 
                    <div style="-ms-overflow-style: none; scrollbar-width: none; width: 100%; overflow: auto;" class="spectrum-Body hide-scrollbar">
                      <transition-group :style="{'display': 'flex', 'justify-content': 'left', 'flex-direction': 'column', 'padding': '0 40px'}" class="spectrum-Body" tag="ul" name="fade-reverse">
                        <li :key="key" :style="{
                            'border-radius': `5px`, 
                            'display': 'flex', 
                            'align-items': 'left',
                            'justify-content': 'left',
                            'flex-direction': 'column',
                            'margin': '0 2px'}" 
                          v-for="(value, key) in parseModifiedPermissions(session.uac.mostRecentsGrouped[session.uac.activePermissions.guild][session.uac.activePermissions.target])">
                          <hr v-if="!value.PARENT_AVAILABLE && key !== 0" style="margin-bottom: 8px;"  role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeS">
                          <div style="display: flex; margin: 8px 0;">
                            <div style="padding-left: 12px;" class="spectrum-Body">
                              <p :style="{'padding-left': value.PARENT_AVAILABLE ? '16px' : '0'}" style="margin-bottom: 0;" class="spectrum-Body spectrum-Body--sizeM">{{ value.NAME }}</p>
                              <p v-if="value.SUB_ID === null" style="margin-bottom: 0;" class="spectrum-Body spectrum-Body--sizeS">{{ value.DESCRIPTION }}</p>
                            </div>
                            <div style="margin-left: auto" class="spectrum-Body">
                              <div v-if="(value.PERMISSIONS.READ === 0 && value.PERMISSIONS.WRITE === 0) 
                                || (value.PERMISSIONS.READ === 0 && value.PERMISSIONS.WRITE === undefined)
                                || (value.PERMISSIONS.READ === undefined && value.PERMISSIONS.WRITE === 0)" class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
                                <span class="spectrum-Tag-label">Disabled access</span>
                              </div>
                              <div v-else-if="(value.PERMISSIONS.READ === 1) && (value.PERMISSIONS.WRITE === 1)" class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
                                <span class="spectrum-Tag-label">Full access</span>
                              </div>
                              <div v-else-if="value.PERMISSIONS.READ === 1" class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
                                <span class="spectrum-Tag-label">Read</span>
                              </div>
                              <div v-else-if="value.PERMISSIONS.WRITE === 1" class="spectrum-Tag spectrum-Tag--sizeS" tabindex="0">
                                <span class="spectrum-Tag-label">Write</span>
                              </div>
                            </div>
                            <div style="text-align: right; margin-left: 16px; margin-top: 0; margin-bottom: 0; font-style: italic;" class="spectrum-Body spectrum-Body--sizeXS">{{ parseTimestamp(value.RAW.updated_at) + " ago" }}</div>
                          </div>
                        </li>
                      </transition-group>
                    </div>                 
                  </div>
                </div>
              </transition>
            </div>
          </transition>
          <div v-if="session.uac.mostRecents.length > 0" style="margin-top: 48px;" class="spectrum-Typography">
            <h1 class="spectrum-Heading spectrum-Heading--sizeS">Search user or role</h1>
            <hr style="margin-bottom: 4px; margin-top: 8px;"  role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeS">
            <p class="spectrum-Body spectrum-Body--sizeS">Look up for specific user or role to manage the permissions with. The server will need to be selected for user-typed instance.<br />As for the result itself, can be displayed up to 5 closest matching entities at a time.</p>
          </div>
          <div style="display: flex; flex-direction: column; width: 400px;" class="spectrum-Body">
            <form style="width: 100%; margin-top: 16px" @submit.prevent="uacFetchTarget();" class="spectrum-Search">
              <div class="spectrum-Textfield" style="width: 100%" >
                <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-Textfield-icon" focusable="false" aria-hidden="true">
                  <use xlink:href="#spectrum-icon-18-Magnify" />
                </svg>
                <input type="search" placeholder="Search specific user or role" name="search" v-model="query.uac.target" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
              </div>
              <button @click="popover.uac.userSelection = false; popover.uac.serverSelection = false; query.uac.target = ``;" type="reset" class="spectrum-ClearButton spectrum-Search-clearButton">
                <svg class="spectrum-Icon spectrum-UIIcon-Cross75" focusable="false" aria-hidden="true">
                  <use xlink:href="#spectrum-css-icon-Cross75" />
                </svg>
              </button>
            </form>
            <transition name="fade-reverse">
              <Popover 
                v-if="popover.uac.userSelection"
                :loading="popover.uac.loading" 
              >
                <div v-if="query.uac.similarMatchList.length <= 0 && !query.uac.pending" style="margin: 16px 0; text-align: center;" class="spectrum-Typography">
                  <h1 style="margin: 0;" class="spectrum-Heading spectrum-Heading--sizeM">No matched result</h1>
                  <p style="margin: 0" class="spectrum-Body spectrum-Body--sizeS">Try again with different keyword or ID</p>
                </div>
                <transition-group v-else :style="{'display': 'flex', 'flex-direction': 'column', 'padding': '0'}" class="spectrum-Body" tag="ul" name="fade-reverse">
                  <li @click="selectUacTarget(value);" class="spectrum-Menu-item" :key="key" :style="{'justify-content': 'center', 'display': 'flex', 'flex-direction': 'column', 'align-items': 'center', 'min-height': '64px', 'margin': '2px', 'padding': '0 24px'}" v-for="(value, key) in query.uac.similarMatchList">
                    <div style="justify-content: center; align-items: center; display: flex; margin: 16px 0; width: 100%;">
                      <img style="width: 32px; height: 32px; margin: auto 0;" class="spectrum-Avatar" :src="avatar(value)" alt="Avatar">
                      <div class="spectrum-Body">
                        <h1 style="padding-left: 16px;" class="spectrum-Heading spectrum-Heading--sizeS">{{ value.userId ? value.displayName : value.name }}</h1>
                        <h1 style="padding-left: 16px;" class="spectrum-Body spectrum-Body--sizeXS">{{ `${value.userId ? `User` : `Role`} ID: ${value.userId || value.id}` }}</h1>
                      </div>
                      <h1 style="margin-left: auto;" class="spectrum-Body spectrum-Body--sizeXS">{{ value.guild ? cache.guilds[value.guild].name : `Choose server` }}</h1>
                    </div>
                  </li>
                </transition-group>
              </Popover>
            </transition>
            <transition name="fade-reverse">
              <Popover class="server-selection" v-if="popover.uac.serverSelection">
                <transition-group :style="{'display': 'flex', 'flex-direction': 'column', 'padding': '0', 'align-items': 'left', 'justify-content': 'center'}" class="spectrum-Body" tag="ul" name="fade-reverse">
                  <li 
                    @click="session.uac.selectedTarget.guild = value; popover.uac.serverSelection = false; fetchFeatureFlags(session.uac.selectedTarget.user.userId, value.id);" 
                    class="spectrum-Menu-item" :key="value.id" :style="{'justify-content': 'center', 'display': 'flex', 'flex-direction': 'column', 'align-items': 'center', 'min-height': '64px', 'margin': '2px', 'padding': '0 24px'}" 
                    v-for="value in cache.guilds">
                    <div style="display: flex; margin: 16px 0; width: 100%;">
                      <img style="width: 32px; height: 32px; margin: auto 0;" class="spectrum-Avatar" :src="avatar(value)" alt="Avatar">
                      <h1 style="padding-left: 16px;" class="spectrum-Heading spectrum-Heading--sizeS">{{ value.name }}</h1>
                    </div>
                  </li>
                </transition-group>
              </Popover>
            </transition> 
          </div>
          <transition name="fade-reverse">
            <div style="margin-top: 16px;" v-if="![session.uac.selectedTarget.user, session.uac.selectedTarget.guild].includes(null)" class="spectrum-Body">
              <p style="display: flex; align-items: center;" class="spectrum-Body spectrum-Body--sizeS">
                Currently editing 
                <img style="width: 16px; height: 16px; margin: auto 6px;" class="spectrum-Avatar" :src="avatar(session.uac.selectedTarget.user)" alt="Avatar"> 
                <strong>{{ (session.uac.selectedTarget.user.userId ? session.uac.selectedTarget.user.displayName : session.uac.selectedTarget.user.name) + (session.uac.selectedTarget.user.color ? ` role` : ``) }}</strong>
                <span style="margin: 0 4px;">in</span>
                <strong>{{ session.uac.selectedTarget.guild ? session.uac.selectedTarget.guild.name : `Not yet selected` }}</strong>
              </p>
              <div style="margin-top: 24px;" class="spectrum-Body">
                <div class="spectrum-Typography">
                  <h1 class="spectrum-Heading spectrum-Heading--sizeS">Toggle feature permissions</h1>
                  <hr style="margin-bottom: 4px; margin-top: 8px;"  role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeS">
                  <p class="spectrum-Body spectrum-Body--sizeS">Enable or disable certain features for the selected user or role. The changes will be put in effect in a few seconds once toggled.<br />Reverting permission to default will force it to use the previous one, depending on its current permission's hierarchy. User-specific will always have higher permissions and will override anything that have been previously set, while Role-specific permission comes in the second priority and will override the default one.<br />Lastly, if none of the mentioned permissions are set, the system will apply default permissions for them. Default permissions consist of admin and member type depending on what are their current privileges in the said server.</p>         
                </div>
                <transition-group key="0" style="padding: 0; margin-top: 24px;" class="spectrum-Body" tag="ul" name="fade-reverse">
                  <li :style="{'display': 'flex', 'flex-direction': 'column', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '0 32px;'}" :key="key" v-for="(value, key) in session.uac.featureFlags.parents">
                    <div style="display: flex; margin: 16px 0; width: 100%; align-items: left; flex-direction: column;">
                      <div class="spectrum-Body" style="display: flex; padding: 0 24px; align-items: center;">
                        <div style="padding-left: 16px;" class="spectrum-Body">
                          <h1 class="spectrum-Heading spectrum-Heading--sizeS">{{ value.NAME }}</h1>
                          <p class="spectrum-Body spectrum-Body--sizeXS">{{ value.DESCRIPTION }}</p>
                        </div>
                        <div @click="value.PERMISSIONS.READ = value.PERMISSIONS.READ ? 0 : 1; updateFeatureFlag(value); inheritParentUpdatesToChilds(value, `READ`)" v-if="value.PERMISSIONS.READ !== undefined" style="margin-left: auto; margin-right: 0;" class="spectrum-Switch spectrum-Switch--emphasized">
                          <input type="checkbox" class="spectrum-Switch-input" id="switch-onoff" :checked="value.PERMISSIONS.READ">
                          <span class="spectrum-Switch-switch"></span>
                          <label class="spectrum-Switch-label" for="switch-onoff-0">Read</label>
                        </div>
                        <div style="margin-right: 0;" @click="value.PERMISSIONS.WRITE = value.PERMISSIONS.WRITE ? 0 : 1; updateFeatureFlag(value); inheritParentUpdatesToChilds(value, `WRITE`)" v-if="value.PERMISSIONS.WRITE !== undefined" class="spectrum-Switch spectrum-Switch--emphasized">
                          <input type="checkbox" class="spectrum-Switch-input" id="switch-onoff" :checked="value.PERMISSIONS.WRITE">
                          <span class="spectrum-Switch-switch"></span>
                          <label class="spectrum-Switch-label" for="switch-onoff-0">Write</label>
                        </div>
                        <button :disabled="session.uac.featureFlags.revertedToDefaults.includes(value.ID)" title="Revert to default" @click="inheritParentReversionToChilds(value); session.uac.featureFlags.revertedToDefaults.push(value.ID);" class="spectrum-Button spectrum-Button--primary spectrum-Button--quiet spectrum-Button--sizeM">
                          <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                            <use xlink:href="#spectrum-icon-18-Revert" />
                          </svg>
                           <span class="spectrum-Button-label">Default</span>
                        </button>
                      </div>
                      <transition-group key="0" style="padding: 0; display: flex; box-sizing: border-box; margin-top: 8px; flex-direction: column;" class="spectrum-Body" tag="ul" name="fade-reverse">
                        <hr v-if="session.uac.featureFlags.childs[key]" style="margin: 8px 0 16px 0; width: 100%;"  role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeM">
                        <li :style="{'display': 'flex', 'flex-direction': 'column', 'margin': '8px 0', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px'}" :key="key2" class="readability" v-for="(value2, key2) in session.uac.featureFlags.childs[key]">
                          <div style="display: flex; margin: 0 24px;" class="spectrum-Body">
                            <h1 style="padding-left: 32px;" class="spectrum-Heading spectrum-Heading--sizeXS">{{ value2.NAME }}</h1>
                            <div @click="value2.PERMISSIONS.READ = value2.PERMISSIONS.READ ? 0 : 1; updateFeatureFlag(value2);" v-if="value2.PERMISSIONS.READ !== undefined" style="margin-left: auto; margin-right: 0;" class="spectrum-Switch spectrum-Switch--emphasized">
                              <input type="checkbox" class="spectrum-Switch-input" id="switch-onoff" :checked="value2.PERMISSIONS.READ">
                              <span class="spectrum-Switch-switch"></span>
                              <label class="spectrum-Switch-label" for="switch-onoff-0">Read</label>
                            </div>
                            <div style="margin-right: 0;" @click="value2.PERMISSIONS.WRITE = value2.PERMISSIONS.WRITE ? 0 : 1; updateFeatureFlag(value2);" v-if="value2.PERMISSIONS.WRITE !== undefined" class="spectrum-Switch spectrum-Switch--emphasized">
                              <input type="checkbox" class="spectrum-Switch-input" id="switch-onoff" :checked="value2.PERMISSIONS.WRITE">
                              <span class="spectrum-Switch-switch"></span>
                              <label class="spectrum-Switch-label" for="switch-onoff-0">Write</label>
                            </div>
                             <button :disabled="session.uac.featureFlags.revertedToDefaults.includes(value2.ID)" title="Revert to default" @click="revertToDefault(value2); session.uac.featureFlags.revertedToDefaults.push(value2.ID);" class="spectrum-Button spectrum-Button--primary spectrum-Button--quiet spectrum-Button--sizeM">
                              <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                                <use xlink:href="#spectrum-icon-18-Revert" />
                              </svg>
                              <span class="spectrum-Button-label">Default</span>
                            </button>
                          </div>
                        </li>
                      </transition-group>
                    </div>
                  </li>
                </transition-group>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </transition>
    <transition name="fade">
      <Footer />
    </transition>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Loading from '../components/Loading.vue'
import Popover from '../components/Popover.vue'
import ms from 'ms'
export default {
  name: 'ServerSelection',
  inject: [`mq`],
  data() {
    return {
      query: {
        uac: {
          pending: false,
          target: ``,
          similarMatchList: []
        }
      },
      popover: {
        uac: {
          userSelection: null,
          serverSelection: null,
          loading: false
        },
      },
      session: {
        uac: {
          activePermissions: {
            loading: true,
            guild: null,
            target: null
          },
          mostRecentsExpanded: false,
          mostRecents: [],
          mostRecentsGrouped: {},
          selectedTarget: {
            guild: null,
            user: null
          },
          featureFlags: {
            revertedToDefaults: [],
            parents: {},
            childs: {}
          }
        }
      },
      initLoading: true,
      cache: {
        guilds: {},
        users: {}
      },
      authorized: null
    }
  },
  components: {
    Loading,
    Footer,
    Popover
  },
  computed: {
    recentPermissionChangesHandler() {
      return this.session.uac.mostRecentsExpanded === true ? this.session.uac.mostRecents : this.session.uac.mostRecents.slice(0, 5)
    },
    isMobile() {
      return this.mq.current === `mobile`
    }
  },
  methods: {
    refreshCurrentlyActivePermissions() {
      this.session.uac.activePermissions.guild = null
      this.session.uac.activePermissions.target = null
      this.session.uac.activePermissions.loading = true
      this.fetchMostRecentFeatureFlags(null)
    },
    parseModifiedPermissions(src) {
      let copiedSrc = JSON.parse(JSON.stringify(src))
      let parents = copiedSrc.filter(x => x.SUB_ID === null)
      let subs = copiedSrc.filter(f => f.SUB_ID)
      let res = []
      for (let flag of parents) {
        if (flag.SUB_FLAGS !== undefined) {
          const subRefs = flag.SUB_FLAGS.map(sf => flag.ID + `_` + sf)
          const availableSubs = subs.filter(f => subRefs.includes(f.ID))
          let subFlags = []
          if (availableSubs.length > 0) {
            subFlags = availableSubs.map(s => {
                return {
                  ...s,
                  NAME: s.SUB_ID.split(`_`).map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(` `),
                  PARENT_AVAILABLE: true
              }
            })
            res = [...res, flag, ...subFlags]
          }
        } else {
          res = [...res, flag]
        }
      }
      const remainingWithoutParents = copiedSrc.filter(f => !res.map(r => r.ID).includes(f.ID))
      if (remainingWithoutParents.length > 0) {
        res = [...res, ...remainingWithoutParents]
      }
      return res
    },
    inheritParentReversionToChilds(parentMetadata) {
      const childs = this.session.uac.featureFlags.childs[parentMetadata.ID]
      let childSubKeys = [parentMetadata.ID]
      for (const child in childs) {
        this.session.uac.featureFlags.revertedToDefaults.push(childs[child].ID)
        childSubKeys.push(childs[child].ID)
      }
      this.$http
      .delete(`/api/featureFlags/${this.session.uac.selectedTarget.user.userId 
      ? this.session.uac.selectedTarget.user.userId 
      : this.session.uac.selectedTarget.user.id}/${this.session.uac.selectedTarget.guild.id}`, {
        data: {
          featureFlagIds: childSubKeys
        }
      })
      .then(() => {
        this.$store.commit(`sendToast`, {
          variant: `success`,
          text: `${parentMetadata.NAME} has been reverted to default and will put in effect in a few seconds`
        })
        this.refreshCurrentlyActivePermissions()
      })
    },
    revertToDefault(meta) {
      this.$http
      .delete(`/api/featureFlags/${this.session.uac.selectedTarget.user.userId 
      ? this.session.uac.selectedTarget.user.userId 
      : this.session.uac.selectedTarget.user.id}/${this.session.uac.selectedTarget.guild.id}?flagId=${meta.ID}`)
      .then(() => {
        this.$store.commit(`sendToast`, {
          variant: `success`,
          text: `${meta.NAME} has been reverted to default and will put in effect in a few seconds`
        })
        this.refreshCurrentlyActivePermissions()
      })
    },
    countStoredPermissions(mostRecentsGroupedMeta) {
      let count = 0
      for (let target in mostRecentsGroupedMeta) {
        count += mostRecentsGroupedMeta[target].length
      }
      return count
    },
    parseTimestamp(timestamp) {
      return ms(Date.now() - new Date(timestamp).getTime(), {long: true})
    },
    async inheritParentUpdatesToChilds(parentMetadata, permissionKey) {
      const sessTarget = this.session.uac.selectedTarget
      const childs = this.session.uac.featureFlags.childs[parentMetadata.ID]
      for (const child in childs) {
        childs[child].PERMISSIONS[permissionKey] = parentMetadata.PERMISSIONS[permissionKey]
      }
      if (Object.keys(childs).length <= 0) return
      this.$http
      .put(`/api/featureflags/${sessTarget.user.userId || sessTarget.user.id}/${sessTarget.guild.id}`, {
        featureFlags: Object.values(childs)
      })
    },
    updateFeatureFlag(metadata) {
      const sessTarget = this.session.uac.selectedTarget
      this.$http
      .put(`/api/featureflags/${sessTarget.user.userId || sessTarget.user.id}/${sessTarget.guild.id}`, {
        featureFlags: [metadata]
      })
      .then(() => {
        this.$store.commit(`sendToast`, {
          variant: `success`,
          text: `${metadata.NAME} will put in effect in a few seconds`
        })
        return this.refreshCurrentlyActivePermissions()
        /**
        //  Update most recent views
        this.session.uac.mostRecents.pop()
        this.$http
        .get(`/api/featureflags?limit=1`)
        .then(async res2 => {
          const d = res2.data[0]
          if (!this.cache.guilds[d.RAW.guild_id]) {
            const guild = await this.fetchGuild(d.RAW.guild_id)
            this.cache.guilds[d.RAW.guild_id] = guild
          }
          if (!this.cache.users[d.RAW.target_id]) {
            const user = await this.fetchUser(d.RAW.target_id)
            this.cache.users[d.RAW.target_id] = user
          }
          this.session.uac.mostRecents.unshift(d)
        })
        */
      })
      .catch(() => {
        return this.$store.commit(`sendToast`, {
          variant: `error`,
          text: `Failed to update ${metadata.NAME} flag`
        })
      })
    },
    avatar(data) {
      //  Direct guild's icon access
      if (data.iconURL) return data.iconURL
      //  Guild avatar. Color property refers to role's color
      if (data.color) {
        const guild = this.cache.guilds[data.guild]
        if (guild) {
          if (guild.iconURL) {
            let icon = guild.iconURL
            icon = icon.replace(`size=2048`, `size=256`)
            icon = icon.replace(`webp`, `png`)
            return icon
          }
        }
      }
      //  Fallback avatar
      if (!data.userId || !data.avatar) return `https://pbs.twimg.com/profile_images/1266101503862779905/_tlf7MWH_400x400.png`
      if (data.avatar) return `https://cdn.discordapp.com/avatars/${data.userId}/${data.avatar}.png`
      let avatar = data.displayAvatarURL
      if (!avatar) return `https://cdn.discordapp.com/embed/avatars/${data.discriminator % 5}.png`
      avatar = avatar.replace(`size=2048`, `size=256`)
      avatar = avatar.replace(`webp`, `png`)
      return avatar
    },
    fetchFeatureFlags(targetId, guildId) {
      const pool = this.session.uac.featureFlags
      this.$http
      .get(`/api/users/${targetId}/permission?v2=true&guild=${guildId}`)
      .then(async res => {
        res.data.permissions.filter(d => !d.SUB_ID).map(p => {
          pool.parents[p.ID] = p
          if (p.SUB_FLAGS !== undefined) {
            if (p.SUB_FLAGS.length > 0) {
              pool.childs[p.ID] = {}
              p.SUB_FLAGS.map(sf => {
                pool.childs[p.ID][sf] = res.data.permissions.find(d => d.ID === (p.ID+`_`+sf))
              })
            }
          }
        })
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          variant: `error`,
          text: `Failed to fetch target feature flags`
        })
      })
    },
    async groupingMostRecents() {
      let res = {}
      //  Group items in this.session.uac.mostRecents by RAW.guild_id
      await Promise.all(this.session.uac.mostRecents.map(d => {
        if (!res[d.RAW.guild_id]) res[d.RAW.guild_id] = {}
        if (!res[d.RAW.guild_id][d.RAW.target_id]) res[d.RAW.guild_id][d.RAW.target_id] = []
        res[d.RAW.guild_id][d.RAW.target_id].push(d)
      }))
      this.session.uac.mostRecentsGrouped = res
      this.session.uac.activePermissions.loading = false
    },
    fetchMostRecentFeatureFlags(limit=5) {
      this.session.uac.mostRecents = []
      this.$http
      .get(`/api/featureflags${limit ? `?limit=${limit}` : ``}`)
      .then(async res => {
        if (res.data.length <= 0) return
        for (let i = 0; i < res.data.length; i++) {
          const data = res.data[i]
          if (!this.cache.guilds[data.RAW.guild_id]) {
            const guild = await this.fetchGuild(data.RAW.guild_id)
            this.cache.guilds[data.RAW.guild_id] = guild
          }
          if (!this.cache.users[data.RAW.target_id]) {
            const user = await this.fetchUser(data.RAW.target_id)
            this.cache.users[data.RAW.target_id] = user
          }
          this.session.uac.mostRecents.push(data)
        }
        this.groupingMostRecents()
      })
    },
    async fetchUser(userId) {
      const res = await this.$http.get(`/api/search`, {
        params: {
          q: userId,
          limit: 1
        }
      })
      if (res.data.results.length <= 0) return null
      return res.data.results[0]
    },
    async getGuildName(guildId) {
      if (this.cache.guilds[guildId]) return this.cache.guilds[guildId].name
      const guild = await this.$http.get(`/api/guilds/${guildId}`)
      this.cache.guilds[guildId] = guild
      return guild.name
    },
    async fetchGuild(guildId) {
      const res = await this.$http.get(`/api/guilds/${guildId}`)
      return res.data.guild
    },
    fetchGuilds() {
      this.$http.get(`/api/guilds`, {
        params: {
          raw: true
        }
      })
      .then(res => {
        for (let i=0; i<res.data.guilds.length; i++) {
          this.cache.guilds[res.data.guilds[i].id] = res.data.guilds[i]
        }
      })
    },
    uacFetchTarget() {
      if (this.query.uac.pending) return
      if (this.query.uac.target === ``) return
      //  Reset saved sessions
      this.session.uac.selectedTarget.guild = null
      this.session.uac.selectedTarget.user = null
      this.query.uac.similarMatchList = []
      this.popover.uac.loading = true
      this.popover.uac.userSelection = true
      this.popover.uac.serverSelection = false
      this.query.uac.pending = true
      this.$http.get(`/api/search`, {
        params: {
          q: this.query.uac.target.toLowerCase(),
          limit: 5
        }
      })
      .then(async res => {
        this.popover.uac.loading = false
        for (let i = 0; i < res.data.results.length; i++) { 
          let d = res.data.results[i]
          await new Promise(resolve => setTimeout(resolve, 50))
          if (d.guild && !this.cache.guilds[d.guild]) this.cache.guilds[d.guild] =  await this.fetchGuild(d.guild)
          this.query.uac.similarMatchList.push(d)
        }
        this.query.uac.pending = false
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          variant: `error`,
          text: `Something went wrong while searching`
        })  
      })
    },
    selectTargetViaMostRecent(targetId, guildId) {
      this.$store.commit(`sendToast`, {
        variant: `info`,
        text: `Fetching target permissions...`
      })
      this.fetchUser(targetId)
      .then(res => {
        let base = JSON.parse(JSON.stringify(res))
        base.id = targetId
        base.guild = guildId
        this.selectUacTarget(base)
      })
    },
    selectUacTarget(target) {
      this.fetchGuilds()
      //  Reseting uac session
      this.session.uac.selectedTarget.user = null
      this.session.uac.selectedTarget.guild = null
      this.session.uac.featureFlags.parents = {}
      this.session.uac.featureFlags.childs = {}
      this.popover.uac.userSelection = false
      this.popover.uac.loading = false
      this.query.uac.pending = false
      //  Only open server selection interface if the target doesn't have guild property in it
      if (target.guild) {
        this.session.uac.selectedTarget.guild = this.cache.guilds[target.guild]
        this.fetchFeatureFlags(target.id, target.guild)
      }
      else {
        this.popover.uac.serverSelection = true
      }
      this.session.uac.selectedTarget.user = target
    }
  },
  created() {
    if (!this.$store.state.user) {
      this.$store.commit(`sendToast`, {
        variant: `info`,
        text: `Login required`
      })
      return this.$router.push(`/login`)
    }
    this.$http
    .get(`/api/users/${this.$store.state.user.id}/permission`)
    .then(res => {
      if (res.data.permissionLevel < 3) {
        this.$store.commit(`sendToast`, {
          variant: `error`,
          text: `Unauthorized access`
        })
        this.authorized = false
        return this.$router.push(`/`)
      }
      else {
        this.authorized = true
        this.configurations = null
        this.initLoading = false
        this.fetchMostRecentFeatureFlags(null)
      }
    })
  }
}
</script>

<style scoped>
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .container {
    background-color: var(--spectrum-global-color-gray-75);
  }
  .readability {
    opacity: 0.75;
  }
  .readability:hover {
    opacity: 1;
  }
  .spectrum-Switch-switch {
    transform: scale(1.1);
  }
  .fade-reverse-enter-active {
    transition: all 300ms ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 100ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>