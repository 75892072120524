<template>
  <transition style="overflow-y: scroll" name="slide-fade">
    <div style="display: flex; width: 100%%;" v-if="toast !== null" class="spectrum spectrum--medium spectrum--lightest">
      <div :class="'spectrum-Toast spectrum-Toast--'+toast.variant" :style="{position:'relative', width: isMobile ? '100%' : 'auto', 'margin-left': isMobile ? 0 : 'auto'}">
        <svg v-if="toast.variant === 'info'" class="spectrum-Icon spectrum-Icon--sizeM spectrum-Toast-typeIcon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-icon-18-Info" />
        </svg>
        <svg v-if="toast.variant === 'positive'" class="spectrum-Icon spectrum-Icon--sizeM spectrum-Toast-typeIcon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-icon-18-CheckmarkCircle" />
        </svg>
        <svg  v-if="toast.variant === 'negative'" class="spectrum-Icon spectrum-Icon--sizeM spectrum-Toast-typeIcon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-icon-18-Alert" />
        </svg>
        <div class="spectrum-Toast-body">
          <div class="spectrum-Toast-content">{{ toast.text }}</div>
        </div>
        <div @click="eject" class="spectrum-Toast-buttons">
          <button class="spectrum-ClearButton spectrum-ClearButton--medium spectrum-ClearButton--overBackground">
            <svg class="spectrum-Icon spectrum-UIIcon-Cross100" focusable="false" aria-hidden="true">
              <use xlink:href="#spectrum-css-icon-Cross100" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: `Toast`,
  inject: [`mq`],
  computed: {
    isMobile() {
      return this.mq.current === `mobile`
    },
    toast() {
      return this.$store.state.toast
    }
  },
  methods: {
    eject() {
      return this.$store.commit('ejectToast')
    }
  }
}
</script>
<style scoped>
  .spectrum {
    position: fixed;
    background-color: transparent;
    box-sizing: border-box;
    width: 100vw;
    bottom: 16px;
    padding: 0 16px;
    z-index: 3;
  }
/**  Animation */
  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }
  .slide-fade-leave-active {
    transition: all 100ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
</style>