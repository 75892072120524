<template>
  <div class="container">
    <transition name="fade">
      <Loading v-if="loading" :text="'Loading the configurations...'" />
    </transition>
    <transition name="fade">
      <div class="spectrum-Typography">
        <h1 class="spectrum-Heading spectrum-Heading--sizeXL">Server Configurations</h1>
        <p class="spectrum-Body spectrum-Body--sizeM">Bot settings that can be adjusted based on per server needs. Please follow the recommended format to avoid unintended behavior of the bot.</p>
      </div>
    </transition>
    <div class="spectrum-Body" style="flex-direction: left; display: flex; flex-direction: row;">
      <button @click="updateSortPopover(sortPopover ? false : true)" class="spectrum-Picker spectrum-Picker--sizeM spectrum-Picker--quiet is-open" aria-haspopup="listbox">
        <span class="spectrum-Picker-label">{{ sortMethod }}</span>
        <svg class="spectrum-Icon spectrum-UIIcon-ChevronDown100 spectrum-Picker-menuIcon" focusable="false" aria-hidden="true">
          <use xlink:href="#spectrum-css-icon-Chevron100" />
        </svg>
      </button>
      <form @submit.prevent="reloadGuildConfigs(false, selectedCategory)" class="spectrum-Search spectrum-Search--quiet" style="margin-left: 16px;">
        <div class="spectrum-Textfield spectrum-Textfield--quiet">
          <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-Textfield-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-icon-18-Magnify" />
          </svg>
          <input v-model="searchQuery" type="search" placeholder="Search for option" name="search" class="spectrum-Textfield-input spectrum-Search-input" autocomplete="off">
        </div>
        <button @click="searchQuery.length > 0 ? reloadGuildConfigs(true, selectedCategory) : null" type="reset" class="spectrum-ClearButton spectrum-Search-clearButton">
          <svg class="spectrum-Icon spectrum-UIIcon-Cross100" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-css-icon-Cross100" />
          </svg>
        </button>
      </form>
      <transition name="fade-reverse" mode="out-in">
        <div v-if="sortPopover" class="spectrum-Popover is-open" style="z-index: 2; margin-top: 36px;">
          <ul class="spectrum-Menu" role="listbox">
            <li @click="updateSortMethod('Importance')" class="spectrum-Menu-item" role="option" aria-selected="true" tabindex="0">
              <span class="spectrum-Menu-itemLabel">Importance</span>
            </li>
            <li @click="updateSortMethod('Name')" class="spectrum-Menu-item" role="option" tabindex="0">
              <span class="spectrum-Menu-itemLabel">Name</span>
            </li>
            <li @click="updateSortMethod('Recently updated')" class="spectrum-Menu-item" role="option" tabindex="0">
              <span class="spectrum-Menu-itemLabel">Recently updated</span>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <transition name="fade">
      <div v-if="notFound" style="display: flex; flex-direction: column; align-items: center; justify-content: center;" class="spectrum-Typography">
        <h1 class="spectrum-Heading spectrum-Heading--sizeL">No matched configurations</h1>
        <p class="spectrum-Body spectrum-Body--sizeM">Try search it again with different name</p>
      </div>
    </transition>

    <transition-group  :style="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isMobile ? '48px' : '0'}" class="config-list" tag="ul" name="fade">
      <li :style="{'flex-basis': isMobile ? '100%' : '33.3333%'}" v-for="conf in configs" :key="conf.ID">
        <ConfigurationListItem 
          :style="{'padding': isMobile ? '0' : '48px 48px 48px 0'}" 
          @reload="!isRemoteReload ? reloadGuildConfigs(false, selectedCategory) : null" 
          :permissions="$store.getters.getPermissions(`SERVER_CONFIGURATIONS_${conf.ID}`)"
          :parentPermissions="$store.getters.getPermissions(`SERVER_CONFIGURATIONS`)"
          :conf="conf"
        />
      </li>
    </transition-group>
    <div style="display: none;" v-if="isRemoteReload">{{ reloadGuildConfigs(false, selectedCategory) }}</div>
  </div>
</template>
<script>
import ms from 'ms'
import ConfigurationListItem from '../ConfigurationListItem.vue'
import Loading from '../Loading.vue'
export default {
  name: `Configurations`,
  components: {
    ConfigurationListItem,
    Loading
  },
  inject: [`mq`],
  data() {
    return {
      loading: false,
      notFound: false,
      sortMethod: `Importance`,
      searchQuery: ``,
      configs: [],
      flags: {},
      categories: {
        'CONFIGURATIONS_OVERALL': 1,
        'CONFIGURATIONS_LOGS': [`LOGGER_MODULE`, `LOGGER_CHANNEL`],
        'CONFIGURATIONS_MODERATIONS': [`COMMUNITY_ROLE`, `BLACKLIST_WORDS`, `BLACKLIST_WORDS_MODULE`, `INVITEURL_WHITELIST`, `INVITEURL_WHITELIST_MODULE`,
        `SPAM_PREVENTION`, `SPAM_INTERVAL`, `SPAM_SIMILARITY_LIMIT`, `SPAM_QUANTITY_LIMIT`, `MINI_WARNING_CAP`, `MINI_WARNING_DURATION`,
        `WARNING_ROLE`, `WARNING_PENALTY_LEVELS`, `WARNING_RESET_COUNTDOWN`, `MESSAGE_FLAGGING`, `FLAG_EMOJI`, `FLAG_VERIFICATION_CHANNEL`,
        `NO_NEW_ACCOUNT`],
        'CONFIGURATIONS_LEVELS': [`EXP_MODULE`, `EXP_AMOUNT`, `EXP_COOLDOWN`, `EXP_STREAK_BONUS`, `LEVEL_UP_MESSAGE`],
        'CONFIGURATIONS_TICKETS': [`TICKET_MODULE`, `TICKET_CATEGORY_CHANNEL`, `TICKET_BLACKLIST_USERS`, `TICKET_ROLE_MENTION`]
      }, 
    }
  },
  computed: {
    isRemoteReload() {
      return this.$store.state.sideNavigation.remoteReload
    },
    sortPopover() {
      return this.$store.state.sortPopover
    },
    isMobile() {
      return this.mq.current === `mobile`
    },
    selectedCategory() {
      return this.$store.state.sideNavigation.activeSubMenu
    }
  },
  methods: {
    updateSortMethod(name) {
      this.sortMethod = name
      this.updateSortPopover(false)
      this.reloadGuildConfigs(false, this.selectedCategory)
    },
    updateSortPopover(state) {
      this.$store.commit(`updateSortPopover`, state)
    },
    addFormPlaceholder(conf) {
      if (conf.TYPE === `array`) return conf.FORM_PLACEHOLDER = `separate by comma/whitespace`
      if (conf.TYPE === `string@role`) return conf.FORM_PLACEHOLDER = conf.PARSED_VALUE 
      ? `@${conf.PARSED_VALUE.name}`
      : `role name/id`
      if (conf.TYPE === `string@channel`) return conf.FORM_PLACEHOLDER = conf.PARSED_VALUE 
      ? `#${conf.PARSED_VALUE.name}`
      : `channel name/id`
      conf.FORM_PLACEHOLDER = conf.PARSED_VALUE
    },
    addWebDescription(conf) {
      switch (conf.ID) {
        case `PREFIX`:
          conf.VALUE_DESCRIPTION  = `"${conf.VALUE}" currently is the active command prefix in the server.`
          break

        case `COMMUNITY_ROLE`:
          conf.VALUE_DESCRIPTION  = conf.VALUE
          ? `@${conf.PARSED_VALUE.name} currently is set as the community role of this server.`
          : `There's no community role set currently.`
          break

        case `BLACKLIST_WORDS`:
          conf.VALUE_DESCRIPTION  = conf.PARSED_VALUE?.length > 0 
          ? `${conf.PARSED_VALUE.length} words currently are blacklisted in the server.`
          : `There are no blacklisted words at the moment.`
          break

        case `INVITEURL_WHITELIST`:
          conf.VALUE_DESCRIPTION  = conf.PARSED_VALUE?.length > 0
          ? `${conf.PARSED_VALUE.length} links currently whitelisted in the server. These links can be shared anywhere across channels.`
          : `There are no whitelisted links at the moment.`
          break

        case `SPAM_INTERVAL`:
          conf.VALUE_DESCRIPTION  = conf.VALUE > 0
          ? `Currently the spam interval is set to ${conf.PARSED_VALUE} per message.`
          : `There's no interval set for spam message yet.`
          break

        case `SPAM_SIMILARITY_LIMIT`:
          conf.VALUE_DESCRIPTION  = conf.PARSED_VALUE > 0
          ? `Currently the limit is set to maximum of ${conf.PARSED_VALUE} messages.`
          : `There's no limit set for spam similarity message yet.`
          break

        case `SPAM_QUANTITY_LIMIT`:
          conf.VALUE_DESCRIPTION  = conf.PARSED_VALUE > 0
          ? `Currently the limit is set to maximum of ${conf.PARSED_VALUE} messages in a row before considered as a spam.`
          : `There's no limit set for spam quantity yet.`
          break

        case `MINI_WARNING_CAP`:
          conf.VALUE_DESCRIPTION  = conf.PARSED_VALUE > 0
          ? `Currently the cap is set to ${conf.PARSED_VALUE} mini warnings in order to receive single regular warning. Updating the current cap will only be applied to the future mini warnings and won't affect the currently active mini warnings.`
          : `There's no cap set for mini warning yet.`
          break

        case `MINI_WARNING_DURATION`:
          conf.VALUE_DESCRIPTION  = conf.VALUE > 0
          ? `Currently the duration is set to ${conf.PARSED_VALUE} before gets expired.`
          : `There's no duration set for mini warning yet`
          break
        
        case `WARNING_ROLE`:
          conf.VALUE_DESCRIPTION  = conf.VALUE
          ? `@${conf.PARSED_VALUE.name} currently is set as the warning role of this server.`
          : `There's no warning role set currently.`
          break

        case `WARNING_RESET_COUNTDOWN`:
          conf.VALUE_DESCRIPTION  = conf.VALUE > 0
          ? `Currently the countdown is set to ${conf.PARSED_VALUE}.`
          : `There's no countdown set for warning reset yet.`
          break

        case `FLAG_EMOJI`:
          conf.VALUE_DESCRIPTION  = conf.VALUE
          ? `Currently the emoji is set to ${conf.PARSED_VALUE}.`
          : `There's no emoji set for flagged message yet.`
          break
        
        case `FLAG_VERIFICATION_CHANNEL`:
          conf.VALUE_DESCRIPTION  = conf.VALUE
          ? `#${conf.PARSED_VALUE.name} currently is set as the flag verification channel of this server.`
          : `There's no channel set at the moment`
          break
        
        case `LOGGER_MODULE`:
          conf.VALUE_DESCRIPTION  = Object.values(conf.PARSED_VALUE).includes(1)
          ? `Currently ${Object.values(conf.PARSED_VALUE).filter(v => v === 1).length} moderation events are logged in the server.`
          : `There are no logged moderation events at the moment.`
          break
        
        case `LOGGER_CHANNEL`:
          conf.VALUE_DESCRIPTION = Object.values(conf.VALUE).filter(v => v !== null).length > 0
          ? `${Object.values(conf.VALUE).filter(v => v !== null).length} channels currently are set as the moderation log channel of this server.`
          : `There are no channels set at the moment.`
          break

        case `NO_NEW_ACCOUNT`:
          conf.VALUE_DESCRIPTION  = conf.VALUE
          ? `The account-age protection is currently enabled`
          : `The account-age protection is currently disabled`
          break

        default:
          break
      }
    },
    parseDuration(val) {
      if (val === null) return val
      return ms(val, {long:true})
    },
    async parsedConfigValue(conf) {
      if (conf.TYPE === `string@role`) {
        const res = await this.$http.get(`/api/roles`, {
          params: {
            guild: this.$store.state.guild.id,
            role: conf.VALUE
          }
        })
        conf.PARSED_VALUE = res.data.role
        return 1
      }
      if (conf.TYPE === `string@channel`) {
        const res = await this.$http.get(`/api/channels`, {
          params: {
            guild: this.$store.state.guild.id,
            channel: conf.VALUE
          }
        })
        conf.PARSED_VALUE = res.data.channel
        return 1
      }
      if (conf.TYPE.startsWith(`object`)) {
        conf.PARSED_VALUE = {}
        if (conf.TYPE.endsWith(`channel`)) {
          try {
            const res = await this.$http.get(`/api/channels`, {
              params: { 
                guild: this.$store.state.guild.id,
                channel: Object.values(conf.VALUE).join(`,`)
              }
            })
            const keys = Object.keys(conf.VALUE)
            res.data.channels.forEach((c, i) => {
              conf.PARSED_VALUE[keys[i]] = c
            })
          }
          catch(e){null}
          return 1
        }
        for (const key in conf.VALUE) {
          if (conf.VALUE[key] === null) {
            conf.PARSED_VALUE[key] = conf.VALUE[key]
            continue
          }
          if (conf.TYPE.endsWith(`duration`)) {
            conf.PARSED_VALUE[key] = this.parseDuration(conf.VALUE[key])
            continue
          }
          if (conf.TYPE.endsWith(`channel`)) {
            try {
              const res = await this.$http.get(`/api/channels`, {
                params: {
                  guild: this.$store.state.guild.id,
                  channel: conf.VALUE[key]
                }
              })
              conf.PARSED_VALUE[key] = res.data.channel
              continue
            }
            catch(e){e}
          }
          conf.PARSED_VALUE[key] = conf.VALUE[key]
        }
        return 1
      }
      if (conf.TYPE === `number@duration`) return conf.PARSED_VALUE = this.parseDuration(conf.VALUE)
      return conf.PARSED_VALUE = conf.VALUE
    },
    async reloadGuildConfigs(resetSearchQuery=false, categorySort=null) {
      this.$store.commit(`lockSideNavigation`, true)
      this.notFound = false
      if (resetSearchQuery) this.searchQuery = null
      this.configs = []
      if (!this.$store.state.guild) return
      this.$http
      .get(`/api/configurations/local/${this.$store.state.guild.id}`)
      .then(async res => {
        let pool = res.data.configs
        //  Filter by selected sub navigation if available
        if ((categorySort !== undefined) || (categorySort !== null)) {
          const targetCategory = this.categories[categorySort]
          if ((targetCategory !== 1) && (targetCategory !== undefined)) pool = pool.filter(v => targetCategory.includes(v.ID))
        }
        //  Prevent duplicates
        pool = pool.filter((v,i,a)=> a.findIndex(t=> (t.ID === v.ID)) === i)
        //  Sorting by 'importance' won't actually sort the object.
        //  It is ordered by the originally written order in source file
        pool = this.sortMethod === `Name`
        ? pool.sort((a,b) => (a.NAME > b.NAME) ? 1 : ((b.NAME > a.NAME) ? -1 : 0))
        : this.sortMethod === `Recently updated`
        ? pool.sort((a,b) => (a.UPDATED_AT < b.UPDATED_AT) ? 1 : ((b.UPDATED_AT < a.UPDATED_AT) ? -1 : 0))
        : pool
        //  Start assign
        pool = this.searchQuery 
        ? pool.filter(c => c.NAME.toLowerCase().startsWith(this.searchQuery.toLowerCase()))
        : pool
        if (pool.length <= 0) return this.notFound = true
        for (let i=0; i<pool.length; i++) {
          const c = pool[i]
          if (i+1 === pool.length) {
            if (this.configs.length <= 0) {
              this.notFound = true
              break
            }
          }
          if (!this.$store.getters.getPermissions(`SERVER_CONFIGURATIONS_${c.ID}`).READ) continue
          await this.parsedConfigValue(c)
          this.addWebDescription(c)
          this.addFormPlaceholder(c)
          this.configs.push(c)
        }
        this.$store.commit(`lockSideNavigation`, false)
        this.$store.commit(`updateSideNavRemoteReload`, false)
      })
    }
  },
  created() {
    if (!this.isRemoteReload) this.reloadGuildConfigs()
  }
}
</script>
<style scoped>
  .spectrum-Divider {
    margin-top: 8px;
    margin-bottom: 14px;
  }
  .container .config-list {
    list-style-type: none;
    padding: 0;
  }
  .spectrum-Form {
    margin: 10px 0;
  }
  .spectrum-Textfield-input {
    padding: 0 10px;
  }
  .spectrum-Button {
    margin: 0 5px;
  }
  .spectrum-ButtonGroup {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .fade-reverse-enter-active {
    transition: all 0.2s ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }

  .fade-enter-active {
    transition: all 0.4s cubic-bezier(.45,.23,.04,.85);
  }
  .fade-leave-active {
    transition: all 0.4s cubic-bezier(.45,.23,.04,.85);
  }
  .fade-enter-from,
  .fade-leave-to {
    transform: translateY(40px);
    opacity: 0;
  }
</style>