<template>
  <button @click="$store.commit('updateGuild', null); $router.push('/server-selection');" class="spectrum-Button spectrum-Button--secondary spectrum-Button--quiet spectrum-Button--sizeS switch-btn">
    <svg class="spectrum-Icon spectrum-Icon--sizeS" focusable="false" aria-hidden="true" aria-label="Edit">
      <use xlink:href="#spectrum-icon-18-Apps" />
    </svg>
    <span class="spectrum-Button-label">Switch server</span>
  </button>
</template>
<script>
export default {
  name: `ServerSwitcher`
}
</script>
<style scoped>
  .switch-btn {
    max-width: 125px;
    margin-bottom: 16px;

    align-self: flex-start;
  }
</style>