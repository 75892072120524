<template>
  <div class="spectrum status">
    <div class="spectrum-Typography">
      <h1 class="spectrum-Heading spectrum-Heading--sizeXL">The state of service</h1>
      <p class="spectrum-Body spectrum-Body--sizeL">The displayed data is reflecting ot the current state of the bot across Adobe discord servers. It may be innacurate on short timeframe.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Status',
  data() {
    return {
      defaultPlaceholder: `Fetching...`,
      services: {
        bot: this.defaultPlaceholder,
        web: this.defaultPlaceholder,
        database: this.defaultPlaceholder
      }
    }
  },
  created() {
    this.getServiceAvailability(`bot`)
    this.getServiceAvailability(`database`)
  },
  methods: {
    /**
     * Retrieve the online presence of a service
     * @param {string} serviceId
     */
    getServiceAvailability(serviceId) {
      if (!serviceId) throw new TypeError(`param 'serviceId' must be a valid service type`)
      this.$http
        .get(`/api/status`, {
          params: {
            service: serviceId
          }
        })
        .then(res => (this.services[serviceId] = res.data.data ? `Operational` : `Unavailable`))
    }
  }
}
</script>

<style scoped>
  .status {
    display: flex;
    height: 100vh;
    flex-direction: column;
  }
</style>