<template>
  <div style="background-color: transparent;" class="spectrum reaction-roles">
    <div style="margin-bottom: 32px;" class="spectrum-Typography">
      <h1 style="margin-bottom: 0;" class="spectrum-Heading spectrum-Heading--sizeXXL">Tickets</h1>
      <transition name="fade-reverse">
        <div v-if="selectedMenu === 'TICKETS_OVERVIEW'" class="spectrum-Body">
          <p class="spectrum-Body spectrum-Body--sizeXL">The overview of currently active tickets or support DM in the server</p>
        </div>
      </transition>
      <transition name="fade-reverse">
        <div v-if="selectedMenu === 'TICKETS_ARCHIVE'" class="spectrum-Body">
          <p class="spectrum-Body spectrum-Body--sizeXL">List of read-only archived closed tickets in the server</p>
        </div>
      </transition>
    </div>
    <transition name="fade-reverse">
      <div v-if="selectedMenu === 'TICKETS_OVERVIEW'" class="spectrum-Body" style="width: 100%;">
        <div :style="{'display': 'flex', 'min-height': '120px', 'flex-direction': 'row', 'align-items': 'center', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '24px 40px'}" class="spectrum-Body">
          <div class="spectrum-Body">
            <h1 class="spectrum-Heading spectrum-Heading--sizeM" style="margin-bottom: 8px">{{ `Ticket Gateway` }}</h1>
            <h1 class="spectrum-Body spectrum-Body--sizeM" style="color: var(--spectrum-global-color-gray-700)">{{ `Enabling this module will allow member of the ${$store.state.guild.name} community to open a support ticket via bot DM. The option can be toggled per-server, instead of global configuration.` }}</h1>
          </div>
          <div @click="updateModuleStatus();" style="margin-left: 64px;" class="spectrum-Switch spectrum-Switch--emphasized">
            <input :disabled="!$store.getters.getPermissions(`SERVER_TICKETS_OVERVIEW`).WRITE" type="checkbox" class="spectrum-Switch-input" id="switch-onoff" :checked="moduleIsActive">
            <span class="spectrum-Switch-switch"></span>
          </div>
        </div>
        <div :style="{'display': 'flex', 'min-height': '120px', 'flex-direction': 'row', 'align-items': 'center', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '24px 40px'}" class="spectrum-Body">
          <div class="spectrum-Body">
            <h1 class="spectrum-Heading spectrum-Heading--sizeM" style="margin-bottom: 8px">{{ `${blacklists.length || ``} Blacklisted Users` }}</h1>
            <h1 class="spectrum-Body spectrum-Body--sizeM" style="color: var(--spectrum-global-color-gray-700)">{{ `Total of users that won't be able to open a support ticket in ${$store.state.guild.name} server. The list can be edited via configuration section of the web panel.` }}</h1>
          </div>
          <button :disabled="!$store.getters.getPermissions(`SERVER_TICKETS_OVERVIEW`).WRITE 
          || !$store.getters.getPermissions(`SERVER_CONFIGURATIONS`).WRITE
          || !$store.getters.getPermissions(`SERVER_CONFIGURATIONS_BLACKLIST_WORDS`).WRITE" type="checkbox" @click="$store.commit(`updateSideNavRemoteReload`, true); $store.commit(`updateSubSideNavigation`, `Tickets`); $store.commit(`updateSideNavigation`, `Configurations`)"  style="margin-left: 64px; padding: 0 18px;" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary">
            <svg class="spectrum-Icon spectrum-Icon--sizeL" focusable="false" aria-hidden="true" aria-label="Edit">
              <use xlink:href="#spectrum-icon-18-GearsAdd" />
            </svg>
            <p style="margin-left: 8px;">Edit</p>
          </button>
        </div>
        <transition name="fade-reverse">
          <Loading :style="{'z-index': '10', 'margin-left': '-1px', 'padding-right': '24px', 'position': 'absolute', 'height': '400px', 'width': '100%'}" :text="'Fetching tickets ...'" v-if="loading.open || loading.closed" />
        </transition>
        <div v-if="tickets.open === false" style="margin: 32px 0;" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
          <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
            <use xlink:href="#spectrum-icon-18-Info" />
          </svg>
          <div class="spectrum-InLineAlert-header">There are no opened tickets at the moment</div>
          <p class="spectrum-Body spectrum-Body--sizeXS">The list will updated once there's new opened ticket in the server</p>
        </div>
        <h1 v-if="tickets.open !== false" class="spectrum-Heading spectrum-Heading--sizeM" style="margin: 32px 8px 8px 8px;">{{ `Active tickets` }}</h1>
        <transition-group key="0" style="padding: 0;" class="spectrum-Body" tag="ul" name="fade-reverse">
          <li :style="{'display': 'flex', 'flex-direction': 'column', 'min-height': '64px', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '0 16px 0 32px'}" :key="key" class="card" v-for="(value, key) in tickets.open">
            <div style="display: flex; margin: 16px 0; width: 100%; align-items: center;">
              <img style="width: 32px; height: 32px;" class="spectrum-Avatar" :src="avatar(value.user_id)" alt="Avatar">
              <div style="padding-left: 24px;" class="spectrum-Body">
                <h1 class="spectrum-Heading spectrum-Heading--sizeS">{{ users[value.user_id] ? users[value.user_id].displayName : `User Unreachable` }}</h1>
                <p class="spectrum-Body spectrum-Body--sizeXS">{{ `Opened ${parseTimestamp(value.created_at)} ago` }}</p>
              </div>
              <p style="margin-left: 64px; font-style: italic;" class="spectrum-Body spectrum-Body--sizeXS">{{ `"${value.messages.length ? value.messages[0].content.slice(0, 24) + `...` : `No discussion yet`}"` }}</p>
              <button @click="redirectToChannel(value.channel_id)" style="margin-left: auto; padding: 0 18px;" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary spectrum-Button--quiet">
                <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Edit">
                  <use xlink:href="#spectrum-icon-18-LinkOut" />
                </svg>
                <p style="margin-left: 8px;">Visit Channel</p>
              </button>
              <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="background-color: var(--spectrum-global-color-gray-400); margin: 8px; height: auto; align-self: stretch;"></div>
              <button :disabled="!$store.getters.getPermissions(`SERVER_TICKETS_OVERVIEW`).WRITE" @click="deleteTicket(value.id);" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary spectrum-Button--quiet">
                <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Delete">
                  <use xlink:href="#spectrum-icon-18-Delete" />
                </svg>
              </button>
            </div>
          </li>
        </transition-group>
      </div>
    </transition>
    <transition name="fade-reverse">
      <div v-if="selectedMenu === 'TICKETS_ARCHIVE'" class="spectrum-Body" style="width: 100%;">
          <div v-if="tickets.closed === false" style="margin: 16px 0;" class="spectrum-InLineAlert spectrum-InLineAlert--negative">
            <svg class="spectrum-Icon spectrum-Icon--sizeM spectrum-InLineAlert-icon" focusable="false" aria-hidden="true">
              <use xlink:href="#spectrum-icon-18-Info" />
            </svg>
            <div class="spectrum-InLineAlert-header">There are no archived tickets at the moment</div>
            <p class="spectrum-Body spectrum-Body--sizeXS">The list will updated once there's new closed ticket in the server</p>
          </div>
        <transition-group key="0" style="padding: 0;" class="spectrum-Body" tag="ul" name="fade-reverse">
          <li :style="{'display': 'flex', 'flex-direction': 'column', 'min-height': '64px', 'margin': '2px', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '0 32px'}" :key="key" class="card" v-for="(value, key) in tickets.closed">
            <div style="display: flex; margin: 16px 0; width: 100%; align-items: center; flex-direction: column;">
              <div style="display: flex; width: 100%; align-items: center;" class="spectrum-Body">
                <img style="width: 32px; height: 32px;" class="spectrum-Avatar" :src="avatar(value.user_id)" alt="Avatar">
                <div style="padding-left: 24px;" class="spectrum-Body">
                  <h1 class="spectrum-Heading spectrum-Heading--sizeS">{{ users[value.user_id] ? users[value.user_id].displayName : `User Unreachable` }}</h1>
                  <p class="spectrum-Body spectrum-Body--sizeXS">{{ `Closed by ${users[value.closed_by_user_id] ? users[value.closed_by_user_id].displayName : `Unknown`}` }}</p>
                </div>
                <p style="margin-left: 64px; font-style: italic;" class="spectrum-Body spectrum-Body--sizeXS">{{ `"${value.messages.length ? value.messages[0].content.slice(0, 64) + `...` : `No discussion yet`}"` }}</p>
                <p style="margin-left: auto;" class="spectrum-Body spectrum-Body--sizeXS">{{ `${parseTimestamp(value.updated_at)} ago` }}</p>
                <button :disabled="value.messages.length <= 0" @click="downloadTicket(value.id);" style="margin-left: 16px;" class="spectrum-Button spectrum-Button--sizeXS spectrum-Button--primary spectrum-Button--quiet">
                  <svg class="spectrum-Icon spectrum-Icon--sizeM" focusable="false" aria-hidden="true" aria-label="Delete">
                    <use xlink:href="#spectrum-icon-18-Download" />
                  </svg>
                </button>
                <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="background-color: var(--spectrum-global-color-gray-400); margin: 12px; height: auto; align-self: stretch;"></div>
                <button @click="openedTab = openedTab === key ? null : key" style="padding: 0 18px;" class="spectrum-Button spectrum-Button--sizeS spectrum-Button--primary" :disabled="value.messages.length <= 0">
                  <p>{{ openedTab === key ? `Close` : `Details` }}</p>
                </button>
              </div>
              <div v-if="openedTab === key" :style="{'display': 'flex', 'flex-direction': 'column', 'padding': '0', 'width': '100%', 'margin': '16px 0'}" class="spectrum-Body">
                <hr style="margin: 12px 0 24px 0;" role="separator" aria-orientation="horizontal" class="spectrum-Divider spectrum-Divider--sizeS">
                <transition-group key="0" style="padding: 0;" class="spectrum-Body" tag="ul" name="fade-reverse">
                  <li :style="{'flex-wrap': 'wrap', 'display': 'flex', 'flex-direction': 'row', 'background-color': 'var(--spectrum-global-color-gray-200)', 'border-radius': '5px', 'padding': '4px 16px', 'justify-content': 'left', 'align-items': 'center'}" :key="key2" class="card" v-for="(value2, key2) in value.messages">      
                    <img style="width: 16px; height: 16px;" class="spectrum-Avatar" :src="avatar(value2.user_id)" alt="Avatar">
                    <p style="margin-left: 8px;" class="spectrum-Body spectrum-Body--sizeXS">{{ parseTimestamp(value2.created_at, false) }}</p>
                    <h1 class="spectrum-Body spectrum-Body--sizeXS" style="margin-left: 8px;">{{ (users[value2.user_id] ? users[value2.user_id].displayName : `User Unreachable`) + ` :` }}</h1>
                    <h1 style="margin-left: 12px;" class="spectrum-Body spectrum-Body--sizeXS">{{ value2.content }}</h1>
                    <div class="spectrum-Divider spectrum-Divider--sizeS spectrum-Divider--vertical" style="background-color: var(--spectrum-global-color-gray-100); margin: 12px; height: auto; align-self: stretch;"></div>
                  </li>
                </transition-group>
              </div>
            </div>
          </li>
        </transition-group>
      </div>
    </transition>
  </div>
</template>

<script>
import ms from 'ms'
import Loading from '../Loading'
export default {
  name: 'Tickets',
  inject: ['mq'],
    components: {
    Loading
  },
  data() {
    return {
      openedTab: null,
      moduleIsActive: null,
      users: {},
      blacklists: [],
      loading: {
        open: true,
        closed: true
      },
      tickets: {
        open: [],
        closed: []
      },
    }
  },
  computed: {
    selectedMenu() {
      return this.$store.state.sideNavigation.activeSubMenu
    },
  },
  methods: {
    redirectToChannel(channel) {
      window.open(`https://discord.com/channels/${this.$store.state.guild.id}/${channel}`, '_blank')
    },
    parseTimestamp(timestamp, relative=true) {
      if (relative) return ms(Date.now() - new Date(timestamp).getTime(), {long: true})
      //  Convert ISO string to HH:MM:SS AM/PM format
      return new Date(timestamp).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
    },
    avatar(userId) {
      if (this.users[userId]) return `https://cdn.discordapp.com/avatars/${userId}/${this.users[userId].avatar}.png`
      return `https://pbs.twimg.com/profile_images/1266101503862779905/_tlf7MWH_400x400.png`
    },
    async fetchUser(userId) {
      const res = await this.$http.get(`/api/users/${userId}`, {
        params: {
          guild: this.$store.state.guild.id
        }
      })
      if (!res.data.user) return
      this.users[userId] = res.data.user
    },
    async loadConfigs() {
      const res = await this.$http.get(`/api/configurations/local/${this.$store.state.guild.id}`)
      if (res.data.configs.length <= 0) return
      this.blacklists = res.data.configs.find(c => c.ID === 'TICKET_BLACKLIST_USERS').VALUE
      this.moduleIsActive = res.data.configs.find(c => c.ID === 'TICKET_MODULE').VALUE
    },
    async loadOpen() {
      const res = await this.$http.get(`/api/tickets/open/${this.$store.state.guild.id}`)
      if (res.data.tickets.length <= 0) {
        this.loading.open = false
        return this.tickets.open = false
      }
      for (let i=0; i<res.data.tickets.length; i++) {
        if (this.users[res.data.tickets[i].user_id] === undefined) await this.fetchUser(res.data.tickets[i].user_id)
        this.tickets.open.push(res.data.tickets[i])
        await new Promise(resolve => setTimeout(resolve, 50))
      }
      this.loading.open = false
    },
    async loadClosed() {
      const res = await this.$http.get(`/api/tickets/closed/${this.$store.state.guild.id}`)
      if (res.data.tickets.length <= 0) {
        this.loading.closed = false
        return this.tickets.closed = false
      }
      for (let i=0; i<res.data.tickets.length; i++) {
        if (this.users[res.data.tickets[i].user_id] === undefined) await this.fetchUser(res.data.tickets[i].user_id)
        if (this.users[res.data.tickets[i].closed_by_user_id] === undefined) await this.fetchUser(res.data.tickets[i].closed_by_user_id)
        this.tickets.closed.push(res.data.tickets[i])
        await new Promise(resolve => setTimeout(resolve, 50))
      }
      this.loading.closed = false
    },
    updateModuleStatus() {
      if (!this.$store.getters.getPermissions(`SERVER_TICKET_OVERVIEW`).WRITE) return
      this.moduleIsActive = this.moduleIsActive ? 0 : 1
      this.$http.put(`/api/configurations/local/${this.$store.state.guild.id}`, {
        configId: `TICKET_MODULE`,
        newValue: this.moduleIsActive
      })
      .then(() => {
        this.$store.commit(`sendToast`, {
          text: `Ticket gateway successfully ${this.moduleIsActive ? `enabled` : `disabled`}`,
          variant: `positive`
        })
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          text: `Failed to update ticket gateway`,
          variant: `negative`
        })
      })
    },
    deleteTicket(ticketId) {
      if (!this.$store.getters.getPermissions(`SERVER_TICKET_OVERVIEW`).WRITE) return
      this.$http.delete(`/api/tickets/${this.$store.state.guild.id}/${ticketId}`)
      .then(() => {
        this.tickets.open = this.tickets.open.filter(ticket => ticket.id !== ticketId)
        this.$store.commit(`sendToast`, {
          text: `Ticket ID ${ticketId} successfully closed`,
          variant: `positive`
        })
        if (this.tickets.open.length <= 0) return this.tickets.open = false
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          text: `Failed to close ticket ID ${ticketId}`,
          variant: `negative`
        })
      })
    },
    downloadTicket(ticketId) {
      const source = this.tickets.closed.find(ticket => ticket.id === ticketId)
      let content = `${new Date(source.created_at)}\n${this.$store.state.guild.name} Ticket ID ${ticketId}\n\n────────────────────\n`
      source.messages.map(t => {
        content += `${new Date(t.created_at).toLocaleString()} (ID ${t.user_id}) ${this.users[t.user_id] ? this.users[t.user_id].displayName : `Unknown user`}: ${t.content}\n`
      })
      content += `────────────────────\n`
      const blob = new Blob([content], {type: 'text/plain'})
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${this.$store.state.guild.name.toLowerCase()}-ticket-id${ticketId}.txt`
      link.click()
    }
  },
  created() {
    this.loadOpen()
    this.loadClosed()
    this.loadConfigs()
  }
}
</script>

<style scoped>
  .spectrum-Switch-switch {
    transform: scale(2);
  }
  .invis-button {
    opacity: 0;
  }
  .visible-button {
    opacity: 1;
  }
  .fade-reverse-enter-active {
    transition: all 300ms ease-out;
  }
  .fade-reverse-leave-active {
    transition: all 100ms cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-reverse-enter-from,
  .fade-reverse-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>