import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Status from '../components/dashboard/Status.vue'
import Dashboard from '../views/Dashboard.vue'
import ServerSelection from '../views/ServerSelection.vue'
import Auth from '../views/Auth.vue'
import Login from '../views/Login.vue'
import Developer from '../views/DevPortal.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/status',
    name: 'Status',
    component: Status
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/server-selection',
    name: 'Server Selection',
    component: ServerSelection
  },
  {
    path: '/developer',
    name: 'Developer Portal',
    component: Developer
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router