<template>
  <div class="spectrum container">
    <transition name="fade">
      <Loading v-if="loading" style="position: fixed; width: 100vw; height: 100vh;" :text="'Fetching...'" />
    </transition>
    <section :style="{'padding': isMobile ? '0 24px' : '0 80px'}" class="spectrum-Body sub-container">
      <div v-if="guildAvailable === false" style="height: calc(100vh - 64px);" class="spectrum-Typography unallowed">
        <h1 class="spectrum-Heading spectrum-Heading--sizeXXL">This is a webpanel for Adobe's discord servers</h1>
        <p class="spectrum-Body spectrum-Body--sizeXL">If you're a moderator and you see this message, contact administrator for support</p>
      </div>

      <div v-else>
        <div style="height: 40vh" class="spectrum-Typography">
          <h1 class="spectrum-Heading spectrum-Heading--sizeXXL">Which server you want to manage?</h1>
          <p class="spectrum-Body spectrum-Body--sizeXL header-caption">You have the privilege to edit the following servers</p>
        </div>
        <ul v-if="guildAvailable">
          <li v-for="guild in guilds" :key="guild.id">
            <div role="figure" tabindex="0" class="spectrum-Card">
              <div class="spectrum-Card-body">
                <p class="spectrum-Body spectrum-Body--sizeM">
                  <img style="width: 48px; margin-bottom: 10px;" :src="guild.image">
                </p>
                <div class="spectrum-Card-header spectrum-Heading spectrum-Heading--sizeXXS">
                  <div class="spectrum-Card-title">
                    {{ guild.name }}
                  </div>
                </div>
                <div class="spectrum-Card-content spectrum-Body spectrum-Body--sizeS css-jfisvt">
                  {{ guild.memberCount }} members
                </div>
              </div>
              <div class="spectrum-Card-footer">
                <a @click="proceedGuild(guild)" target="_blank" role="button" class="spectrum-Button spectrum-Button--sizeM spectrum-Button--primary">
                  Edit
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Loading from '../components/Loading.vue'
export default {
  name: 'ServerSelection',
  inject: [`mq`],
  components: {
    Loading,
    Footer
  },
  computed: {
    isMobile() {
      return this.mq.current === `mobile`
    }
  },
  data() {
    return {
      loading: true,
      guildAvailable: null,
      guilds: []
    }
  },
  methods: {
    fetchGuilds() {
      this.$http
      .get(`/api/guilds?raw=true`)
      .then(async res => {
        const guilds = res.data.guilds
        this.$http
        .get(`/api/users/${this.$store.state.user.id}/permission?v2=true`)
        .then(perms => {
          const permissions = perms.data.permissions
          this.$store.commit(`updateFeatureFlags`, permissions)
          for (let i=0; i<guilds.length; i++) {
            if (permissions[guilds[i].id].find(p => p.ID === `SERVER_WEB_PANEL`).PERMISSIONS.READ === 1) {
              this.guilds.push(guilds[i])
            }
          }
          if (this.guilds.length <= 0) {
            this.loading = false
            return this.guildAvailable = false
          }
          this.guildAvailable = true
          this.loading = false;
          })
        .catch(() => {
          this.$store.commit(`sendToast`, {
            variant: `info`,
            text: `Expired session`
          })
          return this.$router.push(`/login`)
        })
     })
      .catch(() => {
        this.loading = false
        return this.guildAvailable = false
      })
    },
    proceedGuild(guild) {
      if (!this.$store.getters.getPermissions(`SERVER_WEB_PANEL`, guild.id).READ) {
        return this.$store.commit(`sendToast`, {
          variant: `error`,
          text: `Your permission was updated. Please refresh the page`
        })
      }
      this.loading = true
      this.$store.commit(`updateGuild`, guild)
      this.$router.push(`/dashboard`)
    }
  },
  created() {
    this.fetchGuilds()
  }
}
</script>

<style scoped>
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--spectrum-global-color-gray-75);
  }
  .sub-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .spectrum-Typography {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header-caption {
    max-width: 600px;
  }
  .spectrum ul {
    display: flex;
    align-items: center;
    justify-content:center;
    list-style-type: none;
    flex-flow: row wrap;
    max-width: 1024px;
    padding: 0;
  }
  .spectrum-Card {
    margin: 20px;
    max-width: 50px;
    width: 50px;
  }
  .spectrum-Card-body {
    height: calc(var(--spectrum-global-dimension-size-2700) - var(--spectrum-global-dimension-size-1200))
  }
  .spectrum-Card-header {
    display: flex;
    flex-direction: column;
  }
  .spectrum-Card-content {
    height: auto;
    margin-top: 4px;
  }
  .spectrum-Card-footer {
    display: flex;
    justify-content: flex-end;
  }
  .spectrum-Button {
    margin-top: 18px;
  }
</style>