<template>
  <div class="spectrum auth">
  </div>
</template>
<script>
export default {
  name: 'Auth',
  mounted() {
    const fragment = new URLSearchParams(window.location.search)
    if (fragment.has(`error`)) {
      if (fragment.get(`error`) === `access_denied`) {
        return this.$store.commit(`sendToast`, {
          text: `The account link wasn't permitted`,
          variant: `negative`
        })
      }
    }
    if (this.$store.state.isLoggedIn && this.$store.state.user) {
      this.$store.commit(`sendToast`, {
        text: `Welcome back, ${this.$store.state.user.username}!`,
        variant: `positive`
      })
      return this.$router.push(`/server-selection`)
    }
      this.$store.commit(`sendToast`, {
        text: `Verifying your account`,
        variant: `info`
      })
    if (!fragment.has(`code`)) {
      this.$router.push(`/`)
      return this.$store.commit(`sendToast`, {
        text: `Something went wrong`,
        variant: `negative`
      })
    }
    if (localStorage.getItem(`state-key`) !== atob(decodeURIComponent(fragment.get(`state`)))) {
      this.$router.push(`/`)
      return this.$store.commit(`sendToast`, {
        text: `Your session has expired`,
        variant: `negative`
      })
    }
    return this.$http
      .get(`/api/auth/callback`, {
        params: {
          code: fragment.get(`code`)
        }
      })
      .then(res => {
        this.$store.commit(`login`, res.data.user)
        this.$store.commit(`sendToast`, {
          text: `Successfully logged in!`,
          variant: `positive`
        })
        return this.$router.push(`/server-selection`)
      })
      .catch(() => {
        this.$store.commit(`sendToast`, {
          text: `Failed to login. Please try again.`,
          variant: `negative`
        })
      })
  }
}
</script>

<style scoped>
  .auth {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center ;
  }
</style>